import React from "react";
import { connect } from "react-redux";
import { Label, Input, Row } from "reactstrap";
import { Button, DatePicker, Input as InputAntd } from "antd";
import { BsSearch } from "react-icons/bs";
import moment from "moment";
import { getTags } from "../../../store/actions/tagsActions";

export const FilterBox = ({
  filters,
  filterStatus,
  clearFilters,
  filterTypes,
  handleChange,
  handleSearch,
  handleDateChange,
  getTags,
  tags,
  t,
}) => {
  const [selectedFilters, setSelectedFilters] = React.useState({
    byText: false,
    byDate: false,
    byStartDate: false,
    byEndDate: false,
    byTags: false,
  });

  let {
    byText,
    byDate,
    byStartDate,
    byEndDate,
    byTags,
  } = selectedFilters;

  React.useEffect(() => {
    getTags({ limit: 30 }, 1);
  }, [byTags]);

  return (
    <div className="mx-5 task_form">
      <div className="row">
        {byText && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("event_name_search")}
            </Label>
            <InputAntd
              addonAfter={
                <BsSearch
                  onClick={() => handleSearch(filters.text)}
                  cursor="pointer"
                />
              }
              onChange={handleChange}
              name="text"
              value={filters.text}
              className="task-input"
              placeholder="Task Name"
            ></InputAntd>
          </div>
        )}
        {byStartDate && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("task_startDate")}
            </Label>
            <DatePicker
              allowClear={false}
              className="task-input col-md-12"
              name="startDate"
              onChange={(e) =>
                handleDateChange("startDate", e.locale("en-us").format("l"))
              }
              defaultValue={moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
            />
          </div>
        )}
        {byEndDate && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("task_endDate")}
            </Label>
            <DatePicker
              allowClear={false}
              className="task-input col-md-12"
              name="endDate"
              onChange={(e) =>
                handleDateChange("endDate", e.locale("en-us").format("l"))
              }
              defaultValue={moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
            />
          </div>
        )}
        {byTags && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("event_tags")}
            </Label>
            <Input
              className="task-input"
              onChange={handleChange}
              type="select"
              name="tagId"
              value={filters.status}
            >
              {tags &&
                tags.map((tag, i) => {
                  return (
                    <option key={i} value={tag.id}>
                      {tag.tag}
                    </option>
                  );
                })}
            </Input>
          </div>
        )}
        <div className="d-flex flex-wrap justify-content-center mt-2">
          <Button
            className={`main-button${+byText ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({ ...selectedFilters, byText: !byText })
            }
          >
            Filter by text
          </Button>
          <Button
            className={`main-button${+byStartDate ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({
                ...selectedFilters,
                byStartDate: !byStartDate,
              })
            }
          >
            Filter by start date
          </Button>
          <Button
            className={`main-button${+byEndDate ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({ ...selectedFilters, byEndDate: !byEndDate })
            }
          >
            Filter by end date
          </Button>
          <Button
            className={`main-button${+byTags ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({ ...selectedFilters, byTags: !byTags })
            }
          >
            Filter by tags
          </Button>
          <Button className="main-button-cancel" onClick={clearFilters}>
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state?.category?.categories,
  tags: state?.tags?.tags,
});

const mapDispatchToProps = { getTags };

export default connect(mapStateToProps, mapDispatchToProps)(FilterBox);
