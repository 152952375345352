import axios from "axios";

import { apis } from "../../constants/API";
import {
  CHAT_WORDS_ERROR,
  GET_CHAT_WORDS,
  GET_CHAT_WORDS_LOADING,
  UPDATE_CHAT_WORDS,
} from "./types";

import { tokenConfig } from "./authAcrions";

import { toastr } from "react-redux-toastr";

export const getChatWords = (query) => (dispatch, getState) => {
  dispatch({
    type: GET_CHAT_WORDS_LOADING,
  });
  axios
    .get(apis.chatWords, {
      ...tokenConfig(getState),
      params: {
        ...query,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: GET_CHAT_WORDS,
        payload: data,
      });
    })
    .catch((err) => console.log(err));
};

export const updateChatWords = (data) => (dispatch, getState) => {
  dispatch({
    type: GET_CHAT_WORDS_LOADING,
  });
  axios
    .put(apis.chatWords, data, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data, message },
      } = res;
      toastr.success(message);
      dispatch({
        type: UPDATE_CHAT_WORDS,
        payload: data,
      });
    })
    .catch((err) => {
      if (err.response) {
        let errRes = err.response.data.error;
        if (typeof errRes == "string") {
          toastr.error(errRes);
        }
      }
    });
};
