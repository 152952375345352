import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getTodos } from "../../../store/actions/todoActions";

export const TodoList = ({ getTodos, loading, todos }) => {
  let { t, i18n } = useTranslation(["tasks"]);

  React.useEffect(() => {
    getTodos({ limit: 5 }, 1);
  }, []);

  return (
    <div>
      <div>
        {loading && "Loading Todo List"}
        {todos &&
          todos.length !== 0 &&
          todos.map((todo, idx) => {
            const done = todo.done;
            return (
              <div key={idx} className="my-3" style={{ color: "var(--primaryColor" }}>
                {!done ? (
                  <span
                    style={{
                      wordBreak: "break-word",
                      color: "var(--primaryColor)",
                      fontWeight: "bold",
                    }}
                  >
                    {todo.title}
                  </span>
                ) : (
                  <span
                    style={{
                      wordBreak: "break-word",
                      color: "var(--darkGrey)",
                      fontWeight: "bold",
                      textDecoration: "line-through",
                    }}
                  >
                    {todo.title}
                  </span>
                )}

                <div>
                  <span
                    style={{
                      color: "var(--terColor)",
                      fontWeight: "normal",
                    }}
                  >
                    {!done ? (
                      <span>
                        {" "}
                        {moment(todo.endDate).locale(i18n.language).fromNow()}
                      </span>
                    ) : (
                      <span>Done</span>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.todo?.loading,
  todos: state?.todo?.todo,
});

const mapDispatchToProps = {
  getTodos,
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoList);
