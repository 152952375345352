import React from "react";
import { connect } from "react-redux";
import { Avatar } from "antd";
import moment from "moment";
import "moment/locale/ar";
import { useTranslation } from "react-i18next";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { IoMdNotifications } from "react-icons/io";
import { notificationRequestAction } from "../../../store/actions/notificationActions";
import { useHistory } from "react-router";

export const NotificationItem = ({
  notification,
  currentAction,
  notificationRequestAction,
}) => {
  let history = useHistory();

  const getNotificationImage = () => {
    return notification.image ? (
      notification.image
    ) : (
      <IoMdNotifications color="var(--primaryColor)" size="50" />
    );
  };

  const routeNotification = () => {
    switch(notification?.moduleId){
      case 1: history.push("/tasks/"+notification?.entityId);return
      case 3: history.push("/events/"+notification?.entityId);return
      default: return
    }
  };

  const { t, i18n } = useTranslation(["notifications"]);

  const handleNotifyRequest = (status) => {
    notificationRequestAction(status, notification);
  };

  const GetNotificationAction = () => {
    return (
      <>
        <span className="mx-3">
          <AiOutlineCheckCircle
            size="25"
            color="var(--acceptGreen)"
            style={{ cursor: "pointer" }}
            onClick={() => handleNotifyRequest("accept")}
          />
        </span>
        <span className="mx-3">
          <AiOutlineCloseCircle
            size="25"
            color="#E91E1F"
            style={{ cursor: "pointer" }}
            onClick={() => handleNotifyRequest("decline")}
          />
        </span>
      </>
    );
  };

  return (
    <div className="row notificationItem">
      <div className="col-3 text-center text-md-center align-self-center">
        <Avatar size={60} src={getNotificationImage()} />
      </div>
      <div className="col-9 align-self-center text-start">
        <p className="notification-text-actor ">{notification?.actorName}</p>
        <div>
          <p className="notification-text" onClick={() => routeNotification()}>
            {notification?.message?.replace(/'/g, "")}
          </p>
        </div>
        <div className="row">
          <div className="col-6">
            {currentAction === true && GetNotificationAction()}
          </div>
          <div className="col-6 text-end">
            <p style={{ color: "var(--primaryColor)" }}>
              {moment(notification?.createdAt || new Date())
                .locale(i18n?.language)
                .fromNow()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  notificationRequestAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem);
