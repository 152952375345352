import React from "react";
import { connect } from "react-redux";
import { Button, Progress, Dropdown, Tag } from "antd";
import { Row, Col } from "reactstrap";
import moment from "moment";
import "moment/locale/ar";
import { useTranslation } from "react-i18next";
import { Menu } from "antd";
import {
  AiOutlineMinusSquare,
  AiOutlinePlusSquare,
  AiFillTags,
} from "react-icons/ai";
import { MdTitle, MdDescription } from "react-icons/md";
import { BiTime, BiTimeFive, BiDotsVertical, BiHash } from "react-icons/bi";
import { GoFileMedia } from "react-icons/go";
import {
  GrStatusGoodSmall,
  GrStatusUnknownSmall,
  GrStatusCriticalSmall,
} from "react-icons/gr";
import {
  deleteTask,
  getSingleTask,
  updateTaskStatus,
  updateTaskPercent,
} from "../../../../store/actions/taskActions";
import { FileMedia } from "../../../../components/FileMedia/FileMedia";

export const Overview = ({
  task,
  user,
  users,
  updateTaskStatus,
  updateTaskPercent,
  loading,
  currVariant,
  setCurrVariant,
}) => {
  let { t, i18n } = useTranslation(["overview"]);
  let { t: t2 } = useTranslation(["tasks"]);

  const variants =
    task?.assignees &&
    task?.assignees.find((t) => t.userId === user.id) &&
    task?.assignees.find((t) => t.userId === user.id).taskStatus;

  let [taskStatus, setCurrentTaskStatus] = React.useState({});
  const [percent, setPercent] = React.useState();
  const [actionNeeded, setActionNeeded] = React.useState(false);
  const [status, setStatus] = React.useState();
  const [toggle, setToggle] = React.useState(false);

  const handleUpdateStatus = (statusId) => {
    updateTaskStatus(
      { taskStatusId: currVariant, statusId: statusId },
      toggler
    );
  };

  const toggler = () => setToggle(!toggle);

  React.useEffect(() => {
    let newTaskStatus = variants?.find((status) => status.id === currVariant);

    if (newTaskStatus) {
      newTaskStatus = {
        ...newTaskStatus,
        done: newTaskStatus?.statusId === 3,
        pending: newTaskStatus?.statusId === 1,
        accepted: newTaskStatus?.statusId === 2,
        rejected: newTaskStatus?.statusId === 4,
      };
      console.log("New Task Status", newTaskStatus);
    }
    setActionNeeded(newTaskStatus?.pending || newTaskStatus?.accepted);
    setCurrentTaskStatus(newTaskStatus);
    setStatus(newTaskStatus?.status.status);
    setPercent(newTaskStatus?.percentage);

    console.log("New Task Status ", newTaskStatus);
  }, [currVariant, task, toggle]);

  const handleAdd = () => {
    setPercent(percent + 10);
    updateTaskPercent(currVariant, percent + 10, toggler);
  };

  const handleSubtract = () => {
    percent > 0 &&
      setPercent(percent - 10) &&
      updateTaskPercent(currVariant, percent - 10, toggler);
  };

  return (
    <div className="m-2">
      <Row>
        <Col className="col-md-2" style={{ overflowY: "auto" }}>
          <Row>
            <h2
              className="text-center"
              style={{
                fontFamily: "cairo",
                color: "var(--primaryColor)",
                fontSize: "large",
              }}
            >
              {t("repetitions")}
            </h2>
          </Row>
          {variants &&
            variants.map((variant, idx) => {
              return (
                <Row className="">
                  <Col className="d-flex justify-content-center">
                    <Button
                      style={{
                        backgroundColor:
                          variant.id === currVariant
                            ? "var(--primaryColor)"
                            : "var(--secColor)",
                        color:
                          variant.id === currVariant
                            ? "var(--secColor)"
                            : "black",
                        borderRadius: 5,
                      }}
                      className="m-1"
                      key={idx}
                      onClick={() => setCurrVariant(variant.id)}
                    >
                      {t("end")}{" "}
                      {variants &&
                        moment(variants[idx]?.endDate)
                          .locale(i18n.language)
                          .format("DD/MM/YY")}
                    </Button>
                  </Col>
                </Row>
              );
            })}
        </Col>
        <Col md={10}>
          <Row>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-md-10">
                <h2
                  className="text-center justify-self-center"
                  style={{
                    fontFamily: "cairo",
                    color: "var(--primaryColor)",
                    fontWeight: "bolder",
                  }}
                >
                  {taskStatus?.endDate &&
                    moment(taskStatus?.endDate)
                      .locale(i18n.language)
                      .format("DD/MM/YY")}
                </h2>
              </div>
              <div>
              {actionNeeded &&   <Dropdown
                  overlay={
                    <Menu>
                      {actionNeeded && (
                        <Menu.ItemGroup title="Actions">
                          {!taskStatus?.accepted && !taskStatus?.done && (
                            <Menu.Item
                              key="1"
                              onClick={() => handleUpdateStatus(2)}
                            >
                              {t("actions.accept")}
                            </Menu.Item>
                          )}
                          {!taskStatus?.done && (
                            <Menu.Item
                              key="2"
                              onClick={() => handleUpdateStatus(4)}
                            >
                              {t("actions.reject")}
                            </Menu.Item>
                          )}
                          {!taskStatus?.done && (
                            <Menu.Item
                              key="3"
                              onClick={() => handleUpdateStatus(3)}
                            >
                              {t("actions.done")}
                            </Menu.Item>
                          )}
                        </Menu.ItemGroup>
                      )}
                    </Menu>
                  }
                >
                  <BiDotsVertical size="30" cursor="pointer" className="mx-3" />
                </Dropdown>}
              </div>
            </div>
          </Row>
          <Row>
            <Col className="col-md-2">
              <p className="overview-labels mx-2">
                <MdTitle /> {t("title")}:{" "}
              </p>
            </Col>
            <Col className="col-md-10">
              <p className="overview-variables">{task?.title}</p>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-2">
              <p className="overview-labels mx-2">
                <MdDescription /> {t("description")}:{" "}
              </p>
            </Col>
            <Col className="col-md-7">
              <p className="overview-variables">
                {task?.description ? task?.description : t("no_description")}
              </p>
            </Col>
            {(taskStatus?.accepted || taskStatus?.done) && (
              <Col className="col-md-3">
                <Row className="d-flex justify-content-center">
                  <Progress
                    className="d-flex justify-content-center"
                    type="circle"
                    percent={taskStatus?.done ? 100 : percent}
                  />
                </Row>
                {taskStatus?.accepted && (
                  <Row className="d-flex justify-content-center mt-1">
                    <Button
                      onClick={handleSubtract}
                      icon={<AiOutlineMinusSquare />}
                    />
                    <Button
                      onClick={handleAdd}
                      icon={<AiOutlinePlusSquare />}
                    />
                  </Row>
                )}
              </Col>
            )}
          </Row>
          <Row>
            <Col className="col-md-2">
              <p className="overview-labels mx-2">
                <BiTime /> {t("start")}:{" "}
              </p>
            </Col>
            <Col className="col-md-10">
              <p className="overview-variables">
                {moment(taskStatus?.startDate || task?.startDate)
                  .locale(i18n.language)
                  .format("lll")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-2">
              <p className="overview-labels mx-2">
                <BiTimeFive /> {t("end")}:{" "}
              </p>
            </Col>
            <Col className="col-md-10">
              <p className="overview-variables">
                {moment(taskStatus?.endDate || task?.endDate)
                  .locale(i18n.language)
                  .format("lll")}
              </p>
            </Col>
          </Row>
          {taskStatus?.status?.status && (
            <Row>
              <Col className="col-md-2">
                <p className="overview-labels mx-2">
                  {(taskStatus?.pending && (
                    <GrStatusUnknownSmall
                      style={{ color: "var(--primaryColor)" }}
                    />
                  )) ||
                    (taskStatus?.done && (
                      <GrStatusGoodSmall style={{ color: "green" }} />
                    )) ||
                    (taskStatus?.accepted && (
                      <GrStatusGoodSmall
                        style={{ color: "var(--primaryColor)" }}
                      />
                    )) ||
                    (taskStatus?.rejected && (
                      <GrStatusCriticalSmall style={{ color: "red" }} />
                    ))}{" "}
                  {t("status")}:
                </p>
              </Col>
              <Col className="col-md-10">
                <span className="overview-variables">
                  {t2(`filters.${taskStatus?.status?.status.toLowerCase()}`)}
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-md-2">
              <p className="overview-labels mx-2">
                <GoFileMedia /> {t("media")}:{" "}
              </p>
            </Col>
            <Col className="col-md-10">
              {task?.medias?.length === 0 && (
                <p className="overview-variables">{t("no_media")}</p>
              )}
              <div className="d-flex flex-wrap media-box">
                {task?.medias?.length !== 0 &&
                  task?.medias?.map((file, i) => (
                    <div className="m-1" key={i}>
                      <FileMedia file={file} />
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-md-2">
              <p className="overview-labels mx-2">
                <AiFillTags /> {t2("tags")}:{" "}
              </p>
            </Col>
            <Col className="col-md-10">
              {task?.tags &&
                task?.tags.length !== 0 &&
                task?.tags.map((tag, i) => {
                  return (
                    <Tag key={i} icon={<BiHash />} color="geekblue">
                      {tag.tag}
                    </Tag>
                  );
                })}
              {task?.tags && task?.tags.length === 0 && "No Tags"}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  task: state?.task?.singleTask?.task,
  user: state?.auth?.user,
  users: state?.task?.singleTask?.users,
  loading: state?.task?.loading,
});

const mapDispatchToProps = {
  deleteTask,
  updateTaskStatus,
  getSingleTask,
  updateTaskPercent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
