import React from "react";
import { connect } from "react-redux";
import { Modal, Form, Input, DatePicker, Button } from "antd";
import { Row, Col } from "reactstrap";
import { createNewTodo } from "../../../store/actions/todoActions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TagsInput from "react-tagsinput";

export const CreateTodo = ({ isModalVisible, handleCancel, createNewTodo }) => {
  let { t } = useTranslation(["todo"]);

  const [addDetails, setAddDetails] = React.useState({
    addMedia: false,
    addTags: false,
  });

  let { addMedia, addTags } = addDetails;

  const [todoData, setTodoData] = React.useState({
    title: "",
    startDate: "",
    endDate: "",
    description: "",
    tags: [],
    media: null,
  });

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const onChange = (e) => {
    let { name, value } = e.target;
    setTodoData({ ...todoData, [name]: value });
  };

  let { title, startDate, endDate, description, tags, media } = todoData;

  const createTodo = () => {
    let data = new FormData();

    title && data.append("title", title);
    startDate && data.append("startDate", startDate);
    endDate && data.append("endDate", endDate);
    description && data.append("description", description);
    tags && tags.map((tag) => data.append("tags[]", tag));
    if (media) {
      let i;
      for (i = 0; i < media.length; i++) {
        data.append("media", media[i]);
      }
    }

    createNewTodo(data);
    setTodoData({
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      tags: [],
      media: null,
    });
    handleCancel();
  };

  const onStartDateChange = (date) => {
    setTodoData({ ...todoData, startDate: date.locale("en-us") });
  };

  const onEndDateChange = (date) => {
    setTodoData({ ...todoData, endDate: date.locale("en-us") });
  };

  const handleChangeTags = (tags) => {
    setTodoData({ ...todoData, tags: tags });
  };

  function disabledDates(e) {
    return moment(e).dayOfYear() < moment(startDate).dayOfYear();
  }
  function disabledEndDate(e) {
    return {
      disabledHours: () =>
        range(0, moment(e) < moment(startDate) && moment(startDate).hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment(startDate) && moment(startDate).minute()),
    };
  }
  function disabledStartDate(e) {
    return {
      disabledHours: () => range(0, moment(e) < moment() && moment().hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment() && moment().minute()),
    };
  }
  return (
    <Modal
      closable={false}
      title={t("add")}
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={createTodo}
      okButtonProps={{
        className: "main-button",
        disabled: !title || !description || !startDate || !endDate,
      }}
      okText={t("add_todo")}
      cancelButtonProps={{ className: "main-button-cancel" }}
      cancelText={t("cancel")}
    >
      <Form>
        <Form.Item
          name="title"
          rules={[{ required: true, message: t("form.required.todo_title") }]}
        >
          <Input
            onChange={onChange}
            name="title"
            value={title}
            type="textarea"
            className="task-input"
            placeholder={t("form.todo_title")}
            label="Todo item title"
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            { required: true, message: t("form.required.todo_description") },
          ]}
        >
          <Input.TextArea
            onChange={onChange}
            name="description"
            value={description}
            className="task-input"
            placeholder={t("form.todo_description")}
          />
        </Form.Item>
        <Row>
          <Col className="col-md-4">{t("start")}</Col>
          <Col className="col-md-8">
            <Form.Item
              name="startDate"
              rules={[
                {
                  required: true,
                  message: t("form.required.startDate"),
                },
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onOk={onStartDateChange}
                defaultValue={startDate}
                name="startDate"
                className="task-input col-md-12"
                placeholder={t("form.startDate")}
                disabledDate={(e) => e < moment().startOf("day")}
                disabledTime={disabledStartDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-4">{t("end")}</Col>
          <Col className="col-md-8">
            <Form.Item
              name="endDate"
              rules={[
                {
                  required: true,
                  message: t("form.required.endDate"),
                },
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onOk={onEndDateChange}
                defaultValue={endDate}
                name="endDate"
                className="task-input col-md-12"
                placeholder={t("form.endDate")}
                disabledDate={disabledDates}
                disabledTime={disabledEndDate}
              />
            </Form.Item>
          </Col>
        </Row>
        {addTags && (
          <Row className="mb-2">
            <Col className="col-md-4">{t("form.tags")}</Col>
            <Col className="col-md-8">
              <TagsInput
                maxTags="15"
                className="task-input"
                onChange={handleChangeTags}
                name="tags"
                value={tags}
              />
            </Col>
          </Row>
        )}
        {addMedia && (<Row>
          <Col className="col-md-4">{t("media")}</Col>
          <Col className="col-md-8">
            <Form.Item>
              <Input
                onChange={(e) =>
                  setTodoData({ ...todoData, media: e.target.files })
                }
                className="p-2 task-input col-sm"
                type="file"
                multiple={true}
              />
            </Form.Item>
          </Col>
        </Row>)}
        <Row className="mb-2">
          <Col>
            <Button
              className={`main-button${addMedia ? "-selected" : ""}`}
              onClick={() =>
                setAddDetails({ ...addDetails, addMedia: !addMedia })
              }
            >
              Add Media
            </Button>
            <Button
              className={`main-button${addTags ? "-selected" : ""}`}
              onClick={() =>
                setAddDetails({ ...addDetails, addTags: !addTags })
              }
            >
              Add Tags
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { createNewTodo };

export default connect(mapStateToProps, mapDispatchToProps)(CreateTodo);
