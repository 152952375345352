import React from "react";
import { connect } from "react-redux";
import { AiFillDelete, AiFillStar, AiOutlineEdit } from "react-icons/ai";
import { BiHash, BiDotsVertical } from "react-icons/bi";
import { Modal, Menu, Tag, Dropdown } from "antd";
import {
  deleteFavourite,
  getFavourites,
} from "../../../store/actions/favouritesActions";
import { useTranslation } from "react-i18next";
import { FileMedia } from "../../../components/FileMedia/FileMedia";
import moment from "moment";
import EditFavourite from "../EditFavourite";

export const SingleFavourite = ({
  favourite,
  deleteFavourite,
  getFavourites,
  thisPage,
}) => {
  let { t: t2, i18n } = useTranslation(["delete"]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteFavourite(favourite?.id);
    getFavourites({ limit: 10 }, thisPage);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const handleEdit = () => {
    setIsEditVisible(true);
  };
  const handleEditCancel = () => {
    setIsEditVisible(false);
  };

  const MenuOverlay = () => {
    return (
      <Menu>
        <Menu.ItemGroup title="Actions">
          <Menu.Item
            onClick={showModal}
            key="1"
            icon={
              <AiFillDelete
                style={{
                  color: "red",
                }}
              />
            }
          >
            Delete
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={handleEdit}
            icon={
              <AiOutlineEdit
                style={{
                  color: "var(--primaryColor)",
                }}
              />
            }
          >
            Edit
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  };

  return (
    <div className="col-md-6 p-2">
      <div className="task-item m-0">
        <Modal
          title={t2("confirm")}
          visible={isModalVisible}
          closable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ className: "main-button" }}
          okText={t2("ok")}
          cancelText={t2("cancel")}
          cancelButtonProps={{ className: "main-button-cancel" }}
        >
          <p>
            {t2("sure")} "{favourite?.text}"
          </p>
        </Modal>
        <EditFavourite isModalVisible={isEditVisible} handleCancel={handleEditCancel} favourite={favourite}/>
        <div className="d-flex mt-4 justify-content-between">
          <div>
            <h2 className="template-head-text mx-auto">
              <AiFillStar color="gold" size="20" /> {favourite?.text}
            </h2>
          </div>
          <div>
            <Dropdown overlay={MenuOverlay}>
              <BiDotsVertical size="20" />
            </Dropdown>
          </div>
        </div>
        <div>
          <p>
            Added {moment(favourite?.createdAt).locale(i18n.language).fromNow()}
          </p>
        </div>
        {favourite.medias.length !== 0 && (
          <div className="d-flex flex-wrap justify-content-center template-desc-box">
            {favourite.medias.map((file, i) => (
              <div className="m-1" key={i}>
                <FileMedia file={file} />
              </div>
            ))}
          </div>
        )}
        {favourite?.tags && favourite?.tags.length !== 0 && (
          <div className="d-flex flex-wrap">
            {favourite.tags.map((tag, i) => (
              <Tag key={i} icon={<BiHash />} color="geekblue" className="my-1">
                {tag.tag}
              </Tag>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  deleteFavourite,
  getFavourites,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleFavourite);
