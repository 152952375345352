import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { FaUser, FaMobile, FaKey } from "react-icons/fa";
import { Link } from "react-router-dom";
import { register } from "../../store/actions/authAcrions";
import { useHistory } from "react-router-dom";
export const Register = ({
  loginErrors,
  register,
  loading,
  customError,
  successReg,
  isAuthenticated,
}) => {
  const history = useHistory();
  const [registerValues, setRegisterValues] = React.useState({
    username: "",
    mobile: "",
    password: "",
    password_confirm: "",
  });
  const { username, mobile, password, password_confirm } = registerValues;
  let { t } = useTranslation(["auth"]);

  const changeValues = (e) => {
    const { name, value } = e.target;
    setRegisterValues({ ...registerValues, [name]: value });
  };

  const onSubmit = (e) => {
    if (e.preventDefault) {
      e?.preventDefault();
    }
    if (loading) return;
    register({ username, mobile, password });
  };

  React.useEffect(() => {
    if (successReg || isAuthenticated) {
      history.push("/");
    }
  }, [successReg, isAuthenticated]);

  return (
    <div className="login-page">
      <Form className="login-form col-md-5 py-5 px-5" onFinish={onSubmit}>
        <h1 style={{ color: "var(--mainWhite)", fontFamily: "Cairo" }}>
          {t("join")}
        </h1>
        {customError && (
          <h6
            className="text-center my-4 mx-5"
            style={{
              background: "white",
              color: "red",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            {customError}
          </h6>
        )}
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              min: 6,
              message: "Username must be more than 6 chars",
            },
          ]}
        >
          <Input
            prefix={<FaUser />}
            className=" login-input"
            placeholder={t("username")}
            value={username}
            name="username"
            type="text"
            onChange={changeValues}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, min: 12, message: "Mobile is required" }]}
          name="mobile"
        >
          <Input
            prefix={<FaMobile />}
            className=" login-input"
            placeholder={t("mobile") + " (Ex: 201202021670)"}
            value={mobile}
            name="mobile"
            onChange={changeValues}
            rules={[
              {
                required: true,
                min: 12,
                message: "Mobile must be valid mobile Ex: 201202021670",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              min: 6,
              message: "Password must be more than 6 chars",
            },
          ]}
          name="password"
        >
          <Input.Password
            type="password"
            prefix={<FaKey />}
            className=" login-input"
            placeholder={t("password")}
            value={password}
            name="password"
            onChange={changeValues}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              min: 6,
              message: "Password must be more than 6 chars",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
          name="password_confirm"
          required
          dependencies={["password"]}
        >
          <Input.Password
            type="password"
            prefix={<FaKey />}
            className=" login-input"
            placeholder={t("password_confirm")}
            value={password_confirm}
            name="password_confirm"
            onChange={changeValues}
          />
        </Form.Item>
        <Form.Item className="text-center">
          <Button className="sec-button" htmlType="submit" disabled={loading}>
            {t("join")}
          </Button>
        </Form.Item>
        <Form.Item className="text-center">
          <Link to="/login">
            <Button className="sec-button">{t("login")}</Button>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.auth?.regLoading,
  customError: state?.auth?.customError,
  successReg: state?.auth?.successReg,
  isAuthenticated: state?.auth?.isAuthenticated,
});

const mapDispatchToProps = { register };

export default connect(mapStateToProps, mapDispatchToProps)(Register);
