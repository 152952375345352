import React from "react";
import { connect } from "react-redux";
import { HiClock } from "react-icons/hi";
import {
  AiFillDelete,
  AiFillFolderAdd,
  AiOutlineBell,
  AiOutlineEdit,
} from "react-icons/ai";
import { BiDotsVertical } from "react-icons/bi";
import { FaRegFolderOpen } from "react-icons/fa";
import moment from "moment";
import "moment/locale/ar";
import {
  deleteTask,
  updateTaskStatus,
  getTasks,
  editTask,
} from "../../../store/actions/taskActions";
import { Modal, Menu, Dropdown, Button, Input, Checkbox, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import CategorizeForm from "../CategorizeForm";
import EditTask from "../EditTask";

export const TaskItem = ({
  task,
  deleteTask,
  user,
  updateTaskStatus,
  type: filterType,
  loading,
  page,
  getTasks,
  filters,
  history,
  editTask,
  onSelect,
}) => {
  let { t, i18n } = useTranslation(["tasks"]);
  let { t: t2 } = useTranslation(["delete"]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [inlineDesc, setInlineDesc] = React.useState(task?.description);
  const [edit, setEdit] = React.useState(false);

  const showEdit = () => {
    setEdit(true);
  };

  const onChange = (e) => {
    setInlineDesc(e.target.value);
  };

  const editTaskDesc = () => {
    let formData = new FormData();
    formData.append("taskId", task?.id);
    inlineDesc && formData.append("description", inlineDesc);
    editTask(formData);
  };

  const MenuOverlay = () => {
    return (
      <Menu>
        {task.creatorId === user.id && (
          <Menu.ItemGroup title="Creator Actions">
            <Menu.Item
              onClick={showModal}
              key="1"
              icon={
                <AiFillDelete
                  style={{
                    cursor: "pointer",
                    color: "red",
                  }}
                />
              }
            >
              Delete
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={handleOpenEdit}
              icon={
                <AiOutlineEdit
                  style={{
                    cursor: "pointer",
                    color: "var(--primaryColor)",
                  }}
                />
              }
            >
              Edit
            </Menu.Item>
          </Menu.ItemGroup>
        )}
        <Menu.ItemGroup title="Actions">
          <Menu.Item
            key="3"
            onClick={showCategorize}
            icon={
              <AiFillFolderAdd
                style={{
                  cursor: "pointer",
                  color: "var(--primaryColor)",
                }}
              />
            }
          >
            Categorize
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  };

  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const handleOpenEdit = () => {
    setIsEditVisible(true);
  };
  const handleCloseEdit = () => {
    setIsEditVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteTask(task.id);
    getTasks({ limit: 5, ...filters }, page);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isCategorizeVisible, setIsCategorizeVisible] = React.useState(false);
  const showCategorize = () => {
    setIsCategorizeVisible(true);
  };

  const handleCategorizeCancel = () => {
    setIsCategorizeVisible(false);
  };

  const handleUpdateStatus = (statusId) => {
    updateTaskStatus(taskStatusId, statusId);
    getTasks({ limit: 5, ...filters }, page);
  };
  let taskStatusId =
    task?.assignees && task?.assignees[0]
      ? task?.assignees[0]?.taskStatus?.id
      : task?.id;
  let taskStartDate =
    task?.assignees && task?.assignees[0]
      ? task?.assignees[0]?.taskStatus?.startDate
      : task?.startDate;
  let taskEndDate =
    task?.assignees && task?.assignees[0]
      ? task?.assignees[0]?.taskStatus?.endDate
      : task?.endDate;
  return (
    <div className="col-sm-3 task-item py-4 m-4 mx-4 position-relative">
      <Modal
        title={t2("confirm")}
        visible={isModalVisible}
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ className: "main-button" }}
        okText={t2("ok")}
        cancelText={t2("cancel")}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <p>
          {t2("sure")} "{task.title}"
        </p>
      </Modal>
      <CategorizeForm
        visible={isCategorizeVisible}
        handleCancel={handleCategorizeCancel}
        taskId={task?.id}
      />
      <EditTask
        isModalVisible={isEditVisible}
        handleCancel={handleCloseEdit}
        task={task}
      />
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-wrap">
          {task?.creatorId === user?.id && (
            <Checkbox className="mx-1" onClick={() => onSelect(task.id)} />
          )}
          <h2
            style={{
              color: "var(--primaryColor)",
              fontWeight: "bold",
              fontSize: "20px",
              wordBreak: "break-word",
              cursor: "pointer",
            }}
            onClick={() => history.push("/tasks/" + task.id)}
          >
            {task.title}
          </h2>
        </div>
        <div>
          <Dropdown overlay={MenuOverlay}>
            <BiDotsVertical cursor="pointer" />
          </Dropdown>
        </div>
      </div>
      <div className="container mb-4 p-0">
        <div>
          {filterType === "assigned" && (
            <span
              style={{
                color: "var(--darkGrey)",
              }}
            >
              {task?.createdAt &&
                moment(task?.createdAt).locale(i18n.language).fromNow()}
            </span>
          )}
        </div>
        <Row>
          <Col>
            <span
              style={{
                fontWeight: "bold",
                color: "var(--primaryColor)",
              }}
            >
              {moment(taskStartDate).locale(i18n.language).format("ll")}
            </span>
            <span>
              <HiClock
                className="mx-2"
                style={{
                  color: "var(--primaryColor)",
                }}
              />
            </span>
          </Col>
          <Col>
            <span
              style={{
                color: "green",
                fontWeight: "bold",
              }}
            >
              {moment(taskEndDate).locale(i18n.language).format("ll")}
            </span>
            <span>
              <AiOutlineBell
                className="mx-2"
                style={{
                  color: "green",
                }}
              />
            </span>
          </Col>
        </Row>
      </div>
      {task?.creatorId === user?.id && (
        <div>
          {!edit && (
            <div className="mb-1 task-desc-box" onDoubleClick={showEdit}>
              {inlineDesc}
            </div>
          )}
          {edit && (
            <>
              {" "}
              <Input.TextArea
                className="mb-1 task-desc-box"
                value={inlineDesc}
                onChange={onChange}
                onDoubleClick={showEdit}
              ></Input.TextArea>
              <Button className="main-button" onClick={editTaskDesc}>
                Edit
              </Button>{" "}
            </>
          )}
        </div>
      )}
      <div className="mt-2">
        {task?.category &&
          task?.category.length !== 0 &&
          task?.category.map((category) => {
            return (
              <Tag icon={<FaRegFolderOpen />} color="#FFCA28">
                {" "}
                {category?.title}
              </Tag>
            );
          })}
      </div>

      {/* {filterType === "assigned" && !loading && (
        <div
          className="row py-2 justify-content-center text-center position-absolute bottom-0 start-50 translate-middle-x"
          style={{
            borderRadius: "10px",
            height: "40px",
          }}
        >
          <div className="col">
            {task?.assignees &&
              task?.assignees.find((t) => t.userId == user.id) &&
              task?.assignees.find((t) => t.userId == user.id).taskStatus
                ?.statusId == 1 && (
                <AiFillCheckCircle
                  onClick={() => {
                    handleUpdateStatus(2);
                  }}
                  style={{ cursor: "pointer" }}
                  size="25"
                  color="var(--acceptGreen)"
                />
              )}
          </div>
          {task?.assignees &&
            task?.assignees.find((t) => t.userId == user.id) &&
            task?.assignees.find((t) => t.userId == user.id).taskStatus
              ?.statusId != 4 && (
              <div className="col">
                <TiDeleteOutline
                  onClick={() => {
                    handleUpdateStatus(4);
                  }}
                  style={{ cursor: "pointer" }}
                  size="25"
                  color="red"
                />
              </div>
            )}
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.task?.loading,
});

const mapDispatchToProps = { deleteTask, updateTaskStatus, getTasks, editTask };

export default connect(mapStateToProps, mapDispatchToProps)(TaskItem);
