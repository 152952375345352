import React from 'react'
import { connect } from 'react-redux'
import { GoVerified } from 'react-icons/go'
import { Button } from 'reactstrap'
import { useParams } from 'react-router'
import { logout, verifyEmail } from '../../store/actions/authAcrions'

export const EmailVerification = ({ verifyEmail, isAuthenticated,history, logout }) => {
    let { token } = useParams();

    const onclick = () => {
        console.log(token)
        let data = new FormData();
        token && data.append('token', token)
        verifyEmail(data, logout)
        if(!isAuthenticated){

        }
    }

    return (
        <div className="login-page">
            <div className="login-form col-md-6">
                <div className="my-4">
                    <h1 style={{ color: "var(--mainWhite)" }}>
                        <GoVerified /> Email Confirmation 
                    </h1>
                </div>
                <div className="row">
                    <div className="col justify-content-center">
                        {
                            !isAuthenticated && <Button className="sec-button" onClick={onclick}>Verify Email and log-in</Button>
                        }
                        {
                            isAuthenticated && <Button className="sec-button" onClick={onclick}>Verify Email</Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state?.auth?.isAuthenticated,
})

const mapDispatchToProps = { verifyEmail, logout }

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification)
