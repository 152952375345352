import React from "react";
import { connect } from "react-redux";
import { Modal, Input, Button } from "antd";
import { Row, Col } from "reactstrap";
import { createNewTask } from "../../../store/actions/taskActions";
import AsyncSelect from "react-select/async";
import { getContactList } from "../../../store/actions/contactActions";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import moment from "moment";
import TagsInput from "react-tagsinput";

export const NewTaskBar = ({
  createNewTask,
  getContactList,
  isModalVisible,
  handleCancel,
  populatedTitle,
  firebaseMessage,
  firebaseChat,
  firebaseChatMembers,
  user,
}) => {
  let { t } = useTranslation(["tasks"]);

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const [taskData, setTaskData] = React.useState({
    title: populatedTitle ? populatedTitle : "",
    startTime: moment().locale("en-us"),
    endTime: "",
    description: "",
    selfAssigned: false,
    assignees: [],
    tags: [],
    media: null,
    assigneesByUserId: [],
  });

  const [clicked, setClicked] = React.useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setTaskData({ ...taskData, [name]: value });
  };

  const onStartDateChange = (date) => {
    setTaskData({ ...taskData, startTime: date.locale("en-us") });
  };

  const onEndDateChange = (date) => {
    setTaskData({ ...taskData, endTime: date.locale("en-us") });
  };

  function disabledDates(e) {
    return moment(e).dayOfYear() < moment(startTime).dayOfYear();
  }
  function disabledEndDateTime(e) {
    return {
      disabledHours: () =>
        range(0, moment(e) < moment(startTime) && moment(startTime).hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment(startTime) && moment(startTime).minute()),
    };
  }
  function disabledStartDateTime(e) {
    return {
      disabledHours: () => range(0, moment(e) < moment() && moment().hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment() && moment().minute()),
    };
  }

  const [defaultContacts, setDefaultContacts] = React.useState([]);
  const [addMedia, setAddMedia] = React.useState(false);
  const [addDescription, setAddDescription] = React.useState(false);
  const [addTags, setAddTags] = React.useState(false);
  const {
    title,
    startTime,
    endTime,
    description,
    selfAssigned,
    assignees,
    media,
    tags,
    assigneesByUserId,
  } = taskData;

  const publishTask = () => {
    let data = new FormData();

    title && data.append("title", title);
    startTime && data.append("startTime", startTime);
    endTime && data.append("endTime", endTime);
    description && data.append("description", description);
    selfAssigned && data.append("selfAssigned", selfAssigned);
    assignees.map((assignee) => assignee.value).length !== 0 &&
      assignees.map((assignee) => data.append("assignees[]", assignee.value));

    assigneesByUserId.map((assignee) => assignee.value).length !== 0 &&
      assigneesByUserId.map((assignee) =>
        data.append("assigneesByUserId[]", assignee.value)
      );

    if (media) {
      console.log(media);
      var i;
      for (i = 0; i < media.length; i++) data.append("media", media[i]);
    }
    tags && tags.map((tag) => data.append("tags", tag));
    createNewTask(data, handleCancel, firebaseMessage, firebaseChat);
  };

  const filterContacts = async (search) => {
    let newCats = await getContactList({ search });

    return newCats.map((contact) => {
      return { label: contact.name, value: contact.id };
    });
  };

  const filterChatMembers = async (search) => {
    console.log(firebaseChatMembers);
    if (firebaseChatMembers.otherUser) {
      return [
        {
          label: firebaseChatMembers?.otherUser?.name,
          value: firebaseChatMembers.otherUser?.id,
        },
      ];
    }

    let newCats = await firebaseChatMembers?.members?.filter((c) =>
      c.name?.includes(search)
    );

    return newCats.map((contact) => {
      return { label: contact.name, value: contact.id };
    });
  };

  const handleChangeTags = (tags) => {
    setTaskData({ ...taskData, tags: tags });
  };

  const getDeafultContacts = async () => {
    setDefaultContacts(await filterContacts());
  };

  React.useEffect(() => {
    if (firebaseChatMembers) {
      if (firebaseChatMembers.otherUser) {
        return setTaskData({
          ...taskData,
          assigneesByUserId: [
            {
              label: firebaseChatMembers.otherUser.name,
              value: firebaseChatMembers.otherUser.id,
            },
          ],
        });
      } else if (firebaseChatMembers.members) {
        return setTaskData({
          ...taskData,
          assigneesByUserId: firebaseChatMembers?.members
            ?.filter((member) => member.id !== user.id)
            .map((c) => ({
              label: c.name,
              value: c.id,
            })),
        });
      }
    }
  }, [firebaseChatMembers]);

  React.useEffect(() => {
    if (isModalVisible) {
      getDeafultContacts();
    }
  }, [isModalVisible]);
  return (
    <Modal
      closable={false}
      title={t("add_task")}
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={publishTask}
      okButtonProps={{
        className: "main-button",
        disabled: !title || !startTime || !endTime,
      }}
      okText={t("ok")}
      cancelButtonProps={{ className: "main-button-cancel" }}
      cancelText={t("cancel")}
    >
      <Row className="mb-2">
        <Col md={4}>
          <span>{t("form.task_title")}</span>
        </Col>
        <Col md={8}>
          <Input
            onChange={onChange}
            name="title"
            value={title}
            onClick={() => setClicked(true)}
            className="task-input"
            placeholder={t("form.to_remember")}
            required={true}
          />
        </Col>
      </Row>
      {addDescription && (
        <Row className="mb-2">
          <Col md={4}>
            <span>{t("form.task_description")}</span>
          </Col>
          <Col md={8}>
            <Input
              onChange={onChange}
              name="description"
              value={description}
              type="textarea"
              className="task-input"
              placeholder={t("form.task_description")}
              required={true}
            />
          </Col>
        </Row>
      )}
      <Row className="mb-2">
        <Col md={4}>
          <span>{t("form.start")}</span>
        </Col>
        <Col md={8}>
          <DatePicker
            showTime
            showSecond={false}
            onOk={onStartDateChange}
            defaultValue={startTime}
            name="startTime"
            className="task-input col-md-12"
            placeholder="Start Time"
            disabledDate={(e) => e < moment().startOf("day")}
            disabledTime={disabledStartDateTime}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <span>{t("form.end")}</span>
        </Col>
        <Col md={8}>
          <DatePicker
            showTime
            showSecond={false}
            onOk={onEndDateChange}
            defaultValue={endTime}
            name="endTime"
            className="task-input col-md-12"
            placeholder="End Time"
            disabledDate={disabledDates}
            disabledTime={disabledEndDateTime}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <span>{t("form.assignees")}</span>
        </Col>

        <Col md={8}>
          <AsyncSelect
            cacheOptions
            isMulti
            defaultOptions={defaultContacts}
            loadOptions={filterContacts}
            className="task-input"
            onChange={(values) => {
              setTaskData({ ...taskData, assignees: values });
            }}
          />
        </Col>
      </Row>
      {firebaseChat && (
        <Row className="mb-2">
          <Col md={4}>
            <span>{t("form.assigneesFromChat")}</span>
          </Col>

          <Col md={8}>
            <AsyncSelect
              cacheOptions
              isMulti
              defaultOptions
              value={assigneesByUserId}
              loadOptions={filterChatMembers}
              className="task-input"
              onChange={(values) => {
                setTaskData({ ...taskData, assigneesByUserId: values });
              }}
            />
          </Col>
        </Row>
      )}
      {addTags && (
        <Row className="mb-2">
          <Col md={4}>
            <span>{t("form.tags")}</span>
          </Col>
          <Col md={8}>
            <TagsInput
              maxTags="15"
              className="task-input"
              onChange={handleChangeTags}
              name="tags"
              value={tags}
            />
          </Col>
        </Row>
      )}
      {addMedia && (
        <Row className="mb-2">
          <Col md={4}>
            <span>{t("form.media")}</span>
          </Col>
          <Col md={8}>
            <Input
              onChange={(e) =>
                setTaskData({ ...taskData, media: e.target.files })
              }
              className="p-2 task-input col-sm"
              type="file"
              multiple={true}
            />
          </Col>
        </Row>
      )}
      <Row className="mb-2">
        <Col>
          <Button
            className={`main-button${addMedia ? "-selected" : ""}`}
            onClick={() => setAddMedia(!addMedia)}
          >
            Add Media
          </Button>
          <Button
            className={`main-button${addDescription ? "-selected" : ""}`}
            onClick={() => setAddDescription(!addDescription)}
          >
            Add Description
          </Button>
          <Button
            className={`main-button${addTags ? "-selected" : ""}`}
            onClick={() => setAddTags(!addTags)}
          >
            Add Tags
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  contacts: state.contacts.contacts,
  user: state.auth?.user,
});

const mapDispatchToProps = {
  createNewTask,
  getContactList,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTaskBar);
