import React from "react";
import { connect } from "react-redux";

import { Input, Form, Button } from "antd";
import { login as loginAction } from "../../store/actions/authAcrions";
import { getToken as getFireBaseToken } from "../../firebase/config";
import { BiLogIn } from "react-icons/bi";
import { FaMobile } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { RiLockPasswordFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
export const Login = ({
  loginAction: login,
  isAuthenticated,
  needsVerification,
  verifyError,
  customError,
  successReg,
}) => {
  let history = useHistory();
  let { t } = useTranslation(["auth"]);

  const [loginData, setLoginData] = React.useState({
    mobile: "",
    password: "",
  });
  const [firebaseToken, setFirebaseToken] = React.useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const onSubmit = (e) => {
    login({ ...loginData, deviceToken: firebaseToken });
  };

  React.useEffect(() => {
    getFireBaseToken(setFirebaseToken);
    if (isAuthenticated) history.push("/");
  }, []);

  React.useEffect(() => {
    if (needsVerification) {
      history.push("/verify-phone");
    }
  }, [needsVerification]);

  const { mobile, password } = loginData;
  return (
    <div className="login-page">
      <Form className="login-form col-md-5 py-5 px-5" onFinish={onSubmit}>
        <h1 style={{ color: "var(--mainWhite)" }}>
          <BiLogIn /> {t("login")}
        </h1>
        {verifyError && (
          <h6
            className="text-center my-4 mx-5"
            style={{
              background: "white",
              color: "red",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            {JSON.stringify(verifyError)}
          </h6>
        )}
        {customError && (
          <h6
            className="text-center my-4 mx-5"
            style={{
              background: "white",
              color: "red",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            {customError}
          </h6>
        )}
        <Form.Item
          rules={[{ required: true, min: 12, message: "Mobile is required" }]}
          name="mobile"
        >
          <Input
            prefix={<FaMobile />}
            className="my-2 login-input"
            name="mobile"
            placeholder={t("mobile")}
            value={mobile}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              min: 6,
              message: "Password must be more than 6 chars",
            },
          ]}
          name="password"
        >
          <Input.Password
            prefix={<RiLockPasswordFill />}
            className="my-2 login-input"
            name="password"
            placeholder={t("password")}
            value={password}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item>
          <div className="d-flex justify-content-between">
            <Button className="sec-button" htmlType="submit">
              {t("login")}
            </Button>
            <div>
              <div>
                <a className="link m-2" href="/forgotpassword">
                  {t("forgotPassword")}
                </a>
              </div>
              <div>
                <a className="link m-2" href="/register">
                  {t("newToUs")}
                </a>
              </div>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  needsVerification: state.auth?.needsVerification,
  verifyError: state.auth?.verifyError,
  customError: state?.auth?.customError,
  successReg: state?.auth?.successReg,
});

const mapDispatchToProps = {
  loginAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
