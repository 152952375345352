import React from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Button } from "reactstrap";
import { verifyPhoneNumber as verifyPhoneNumberAction } from "../../store/actions/authAcrions";
import { useHistory } from "react-router-dom";
import InputVerificationCode from "../../components/VerificationInput";
export const VerifyPhoneNumber = ({
  user,
  verifyPhoneNumber,
  needsVerification,
  isAuthenticated,
  verifyError,
  loading,
}) => {
  const [verificationData, setVerificationData] = React.useState({
    code: "",
    mobile: "",
  });
  const history = useHistory();
  const onSubmit = (e, vCode) => {
    /*     if (e?.preventDefault()) {
      e?.preventDefault();
    } */

    if (vCode) {
      return verifyPhoneNumber({
        code: vCode,
        mobile: verificationData.mobile,
      });
    }
    verifyPhoneNumber(verificationData);
  };
  const handleChange = (e) => {
    setVerificationData({ ...verificationData, code: e.target.value });
  };

  React.useEffect(() => {
    setVerificationData({ ...verificationData, mobile: user?.mobile });
  }, []);

  React.useEffect(() => {
    if (!needsVerification && !isAuthenticated) history.push("/");
    if (!needsVerification && isAuthenticated) history.push("/chat");
  }, [needsVerification]);

  return (
    <div className="login-page text-center">
      <Form className="login-form col-md-5" onSubmit={onSubmit}>
        {verifyError && verifyError.length !== 0 && (
          <h6 className="error-message"> {JSON.stringify(verifyError)} </h6>
        )}

        <h1 style={{ color: "var(--mainWhite)" }}>Verify Phone Number</h1>
        <h5 style={{ color: "var(--mainWhite)" }}>
          +{verificationData.mobile}
        </h5>
        <h6 style={{ color: "var(--mainWhite)" }}>
          Please insert the code you have recieved
        </h6>

        <InputVerificationCode
          length={6}
          loading={loading}
          onComplete={(vCode) => {
            // setVerificationData({ ...verificationData, code: vCode });
            onSubmit({ vCode }, vCode);
          }}
        />
        {/*  <FormGroup>
          <Input.Password
            className="my-2 login-input"
            name="code"
            placeholder={"Code"}
            value={verificationData.code}
            onChange={handleChange}
          />
        </FormGroup> */}
        {/*         <FormGroup>
          <div className="d-flex justify-content-between">
            <Button className="sec-button" type="submit">
              Confirm
            </Button>
          </div>
        </FormGroup> */}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  needsVerification: state.auth.needsVerification,
  verifyError: state.auth?.verifyError,
});

const mapDispatchToProps = { verifyPhoneNumber: verifyPhoneNumberAction };

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhoneNumber);
