import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Modal, Form, Input, DatePicker } from "antd";
import { Row, Col } from "reactstrap";
import { editTodo } from "../../../store/actions/todoActions";

export const EditTodo = ({ isModalVisible, handleCancel, editTodo, todo }) => {
  let { t } = useTranslation(["todo"]);
  const [todoData, setTodoData] = React.useState({
    title: "",
    startDate: "",
    endDate: "",
    description: "",
  });

  React.useEffect(() => {
    setTodoData({
      title: todo?.title,
      startDate: moment(todo?.startDate),
      endDate: moment(todo?.endDate),
      description: todo?.description,
    });
  }, [todo]);

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const onChange = (e) => {
    let { name, value } = e.target;
    setTodoData({ ...todoData, [name]: value });
  };

  let { title, startDate, endDate, description } = todoData;

  const onStartDateChange = (date) => {
    setTodoData({ ...todoData, startDate: date.locale("en-us") });
  };

  const onEndDateChange = (date) => {
    setTodoData({ ...todoData, endDate: date.locale("en-us") });
  };

  function disabledDates(e) {
    return moment(e).dayOfYear() < moment(startDate).dayOfYear();
  }
  function disabledEndDateTime(e) {
    return {
      disabledHours: () =>
        range(0, moment(e) < moment(startDate) && moment(startDate).hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment(startDate) && moment(startDate).minute()),
    };
  }
  function disabledStartDateTime(e) {
    return {
      disabledHours: () => range(0, moment(e) < moment() && moment().hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment() && moment().minute()),
    };
  }

  const handleEdit = () => {
    editTodo(todo?.id, todoData);
    handleCancel();
  };

  return (
    <Modal
      closable={false}
      title={t("edit")}
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={handleEdit}
      okButtonProps={{
        className: "main-button",
        disabled: !title && !description && !startDate && !endDate,
      }}
      okText={t("ok_edit")}
      cancelButtonProps={{ className: "main-button-cancel" }}
      cancelText={t("cancel")}
    >
      <Form>
        <Form.Item
          name="title"
          rules={[{ required: true, message: t("form.required.event_title") }]}
        >
          <Input
            defaultValue={title}
            onChange={onChange}
            name="title"
            value={title}
            type="textarea"
            className="task-input"
            placeholder={t("form.event_title")}
            label="Event Title"
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            { required: true, message: t("form.required.event_description") },
          ]}
        >
          <Input.TextArea
            defaultValue={description}
            onChange={onChange}
            name="description"
            value={description}
            className="task-input"
            placeholder={t("form.event_description")}
          />
        </Form.Item>
        <Row>
          <Col className="col-md-4">{t("start")}</Col>
          <Col className="col-md-8">
            <Form.Item
              name="startDate"
              rules={[
                {
                  required: true,
                  message: t("form.required.startDate"),
                },
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onOk={onStartDateChange}
                defaultValue={startDate}
                name="startDate"
                className="task-input col-md-12"
                placeholder={t("form.startDate")}
                disabledDate={(e) => e < moment().startOf("day")}
                disabledTime={disabledStartDateTime}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-4">{t("end")}</Col>
          <Col className="col-md-8">
            <Form.Item
              name="endDate"
              rules={[
                {
                  required: true,
                  message: t("form.required.endDate"),
                },
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onOk={onEndDateChange}
                defaultValue={endDate}
                name="endDate"
                className="task-input col-md-12"
                placeholder={t("form.endDate")}
                disabledDate={disabledDates}
                disabledTime={disabledEndDateTime}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {editTodo};

export default connect(mapStateToProps, mapDispatchToProps)(EditTodo);
