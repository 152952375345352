import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getFreeAppointments } from "../../../../store/actions/appointmentsActions";
import { Button, Tooltip } from "antd";
import { RiHealthBookLine } from "react-icons/ri";
import RequestAppointment from "./RequestAppointment";
import PaginateSlots from "../../../Appointments/PaginateSlots"
export const UserAppointments = ({
  user,
  appointments,
  getFreeAppointments,
}) => {
  let { t } = useTranslation(["appointments"]);
  const [createOpen, setcreateOpen] = React.useState(false);

  React.useEffect(() => {
      getFreeAppointments({limit:10, userId: user?.id}, 1)
  },[])

  return (
    <div>
      <RequestAppointment
        userId={user?.id}
        visible={createOpen}
        handleCancel={() => setcreateOpen(false)}
      />
      <div className="d-flex my-4 justify-content-between">
        <div className="px-3">
          <h2 className="head-text">{t("free_slots")}</h2>
        </div>
        <div>
          <Tooltip title={t("request")}>
            <Button
              className="text-center main-button mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => setcreateOpen(!createOpen)}
              id="close"
            >
              <RiHealthBookLine size="25" color="white" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div>
          <PaginateSlots userId={user?.id} book/>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appointments: state?.appointments?.appointments,
  user: state?.users?.singleUser
});

const mapDispatchToProps = { getFreeAppointments };

export default connect(mapStateToProps, mapDispatchToProps)(UserAppointments);
