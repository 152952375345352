import React from "react";
import { connect } from "react-redux";
import { Modal, Radio } from "antd";
import { categorize } from "../../../store/actions/taskActions";

export const CategorizeForm = ({
  visible,
  categories,
  taskId,
  handleCancel,
  categorize,
}) => {
  const [catId, setCatId] = React.useState();
  const onChange = (e) => {
    setCatId(e.target.value);
  };

  const handleOk = () => {
    categorize(taskId, catId);
    handleCancel();
  };

  return (
    <Modal
      title="Categorize Task"
      visible={visible}
      okButtonProps={{ className: "main-button" }}
      cancelButtonProps={{ className: "main-button-cancel" }}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Radio.Group onChange={onChange}>
        {categories &&
          categories.map((category, idx) => {
            return (
              <Radio.Button key={idx} value={category.id} className="m-1">
                {category.title}
              </Radio.Button>
            );
          })}
      </Radio.Group>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  categories: state?.category?.categories,
});

const mapDispatchToProps = {
  categorize,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorizeForm);
