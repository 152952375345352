import React from "react";
import { connect } from "react-redux";
import { Badge, Calendar } from "antd";
import { getCalendar } from "../../store/actions/calendarActions";
import moment from "moment";
import "moment/locale/ar";
import { BsCalendarFill } from "react-icons/bs";
import EventsModal from "./EventsModal";
import { useTranslation } from "react-i18next";

export const CalendarPage = ({ thisMonth, getCalendar, loading }) => {
  let { t } = useTranslation(["calendar"]);

  const [modalData, setModalData] = React.useState({
    events: null,
    date: null,
    isVisible: false,
  });

  const { events, date, isVisible } = modalData;

  const showModal = (events, date) => {
    setModalData({
      events: events,
      date: date,
      isVisible: true,
    });
  };

  const handleOk = () => {
    setModalData({
      events: null,
      date: "",
      isVisible: false,
    });
  };

  React.useEffect(() => {
    getCalendar({
      startDate: moment().startOf("month").locale("en-us").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").locale("en-us").format("YYYY-MM-DD"),
    });
  }, []);

  function dateRender(value) {
    const today = value.locale("en-us").format("YYYY-MM-DD");
    const eventsToday = thisMonth[today] || [];
    if (eventsToday)
      return (
        <div>
          <ul className="events">
            {!loading &&
              eventsToday.map((event, idx) => (
                <li key={idx}>
                  <Badge color={event?.color} text={event?.title} />
                </li>
              ))}
            {loading && "Loading"}
          </ul>
        </div>
      );
  }

  function openModal(value) {
    const today = value.locale("en-us").format("YYYY-MM-DD");
    const eventsToday = thisMonth[today] || [];
    console.log(value)
    eventsToday.length !== 0 && showModal(eventsToday, today);
  }

  const handlePanelChange = (date, mode) => {
    getCalendar({
      startDate: moment(date)
        .startOf("month")
        .locale("en-us")
        .format("YYYY-MM-DD"),
      endDate: moment(date).endOf("month").locale("en-us").format("YYYY-MM-DD"),
    });
  };

  return (
    <div className="container mb-5 main-page">
      <EventsModal data={modalData} handleOk={handleOk} />
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            <BsCalendarFill /> {t("calendar")}
          </h2>
        </div>
        <div></div>
      </div>
      <div>
        <Calendar
          dateCellRender={dateRender}
          onPanelChange={handlePanelChange}
          onSelect={openModal}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  thisMonth: state?.calendar?.thisMonth,
  loading: state?.calendar?.loading,
});

const mapDispatchToProps = {
  getCalendar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
