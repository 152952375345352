import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Tag, Button, Tooltip } from 'antd';
import { MdTitle } from 'react-icons/md';
import { TiTag } from 'react-icons/ti';
import { GrAddCircle } from 'react-icons/gr';
import { createTemplate } from '../../../store/actions/templateActions';

export const CreateTemplate = ({ createTemplate }) => {
    const [currTag, setCurrTag] = React.useState("");
    const [vars, setVars] = React.useState([]);
    const [data, setData] = React.useState({
        title: "",
        description: "",
        media: null,
    })



    const { title, description, media } = data;

    const addVar = () => {
        vars.push(currTag);
        document.getElementById('variable-in').value = "";
        setCurrTag("");
    }

    function insertAtCursor(e) {
        const field = document.querySelector("#description-field")
        const pos = field.selectionStart
        field.value = field.value.slice(0, pos) + e.target.id + field.value.slice(pos, field.value.length)
    }

    const onSubmit = () => {
        let formData = new FormData();
        title && formData.append('title', title);
        description && formData.append('description', description);
        if(media) {
            var i;
            for(i=0;i<media.length;i++)
                formData.append('media',media[i])
        }
        createTemplate(formData)
    }

    const handleClose = () => {
        document.querySelector("#close").click()
    }

    return (
        <div className="row">
            <div className="col task_form py-3 mt-4 my-5">
                <div className="row px-3">
                    <Form>
                        <Form.Item name="title" rules={[{ required: true, message: "Title is required" }]}>
                            <Input prefix={<MdTitle />}
                                value={title}
                                onChange={({ target }) => setData({ ...data, title: target.value })}
                                className="mt-3 task-input"
                                placeholder="Template Title">
                            </Input>
                        </Form.Item>
                        <Form.Item help="Add variables to use in your template">
                            <div className="d-flex">
                                <div>
                                    <Tooltip title="Add variable">
                                        <GrAddCircle onClick={addVar} style={{ cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                                <Input
                                    prefix={<TiTag />}
                                    value={currTag}
                                    onChange={({ target }) => setCurrTag(target.value)}
                                    className="mt-3 task-input"
                                    id="variable-in"
                                    placeholder="Template Variables">
                                </Input>
                            </div>
                        </Form.Item>
                        <Form.Item name="description" rules={[
                            { required: true, message: "Format is required" },
                            { min: 5, message: "Format is too short" },
                            { max: 10000, message: "Format is too long" }
                        ]}>
                            <Input.TextArea
                                value={description}
                                onChange={({ target }) => setData({ ...data, description: target.value })}
                                className="mt-3 task-input"
                                id="description-field"
                                placeholder="Format the template"
                                aria-rowcount={10}
                            >
                            </Input.TextArea>
                        </Form.Item>
                        <div className="container-fluid col-md-12">
                            <div className="row-sm">
                                {
                                    vars && vars.map((variable, idx) => {
                                        return <Tag key={idx} className="tag m-2 p-1" onClick={insertAtCursor} id={"%" + variable + "%"} closable> {variable} </Tag>;
                                    })}
                            </div>
                        </div>
                        <Form.Item name="media">
                            <Input
                                value={media}
                                onChange={({target}) => {setData({...data, media: target.files})}}
                                multiple='true'
                                className="mt-3 task-input"
                                type="file"
                            >
                            </Input>
                        </Form.Item>
                        <div className="col justify-content-center p-1">
                        <Button className="main-button-cancel m-1" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button className="main-button m-1" onClick={onSubmit} disabled={!description || !title}>
                                Submit
                            </Button>
                        </div>
                    </Form>

                </div>
            </div>

        </div >
    );
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = { createTemplate }

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate)
