import React from "react";
import { connect } from "react-redux";
import { getNotificationConfig } from "../../../../store/actions/notificationActions";
import Loader from "../../../../components/Loader";
import { CustomInput } from "reactstrap";
import { Button } from "reactstrap";
import { FaSave } from "react-icons/fa";
export const NotificationConfig = ({
  getNotificationConfig,
  config,
  loading,
}) => {
  //State
  const [configs, setConfigs] = React.useState({});

  const getStatus = (key) => {
    let currentConfigs = { ...configs };
    return currentConfigs[key] ? currentConfigs[key] : false;
  };

  const updateValue = (valueName, value) => {
    let oldConfigs = { ...configs };
    oldConfigs[valueName] = value;
    setConfigs({ ...oldConfigs });
  };

  React.useEffect(() => {
    getNotificationConfig();
  }, []);

  React.useEffect(() => {
    if (config) {
      setConfigs(config);
    }
  }, [config]);

  if (loading) return <Loader />;

  return (
    <div>
      <div className="container">
        {configs &&
          Object.keys(configs)
            .filter(
              (i) =>
                i !== "id" &&
                i !== "updatedAt" &&
                i !== "createdAt" &&
                i !== "userId"
            )
            .map((moduleKey, i) => {
              return (
                <div className="row my-3" key={i}>
                  <div className="col">
                    <span className="text text-capitalize main-text-light">{moduleKey}</span>
                  </div>
                  <div className="col">
                    <CustomInput
                      type="switch"
                      name={moduleKey}
                      onChange={(e) =>
                        updateValue(e.target.name, e.target.checked)
                      }
                      checked={getStatus(moduleKey)}
                    />
                  </div>
                </div>
              );
            })}
      </div>
      <Button className="main-button my-1 mx-2">
      <FaSave/> Save Notification Settings
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  config: state.notifications?.notificationConfig,
  loading: state.notifications?.loading,
});

const mapDispatchToProps = {
  getNotificationConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationConfig);
