import {
  GET_CONTACT_LIST,
  GET_CONTACT_LIST_ERROR,
  GET_CONTACT_LIST_LOADING,
  CREATE_CONTACT_LIST,
} from "../actions/types";

const initialState = {
  contacts: [],
  loading: false,
  error: false,
};

export default function contactReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CONTACT_LIST:
      return {
        ...state,
        contacts: payload,
        loading: false,
        error: false,
      };
    case GET_CONTACT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_CONTACT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
