import {
    LOADING_FAVOURITES,
    LOAD_FAVOURITES,
    ADDED_FAVOURITE,
    FAVOURITE_ERROR,
} from "../actions/types";

const initialState = {
    favourites: [],
    error: false,
    loading: false,
    thisPage: 1,
    allPages: 1,
    totalCount: 1,
};

export default function favouritesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOAD_FAVOURITES:
            return {
                ...state,
                favourites: payload.result,
                thisPage: payload.thisPage,
                allPages: payload.allPages,
                error: false,
                loading: false,
            };
        case LOADING_FAVOURITES:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case ADDED_FAVOURITE:
            return {
                ...state,
                favourites: payload.result,
                error: false,
                loading: false,
            };
        case FAVOURITE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return {
                ...state
            }

    }
}
