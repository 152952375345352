import React from "react";
import Slider from "infinite-react-carousel";
import { connect } from "react-redux";
import { getEventInvitees } from "../../../store/actions/eventActions";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { Card, Avatar } from "antd";
import { CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const InviteesSlider = ({
  getEventInvitees,
  eventId,
  users,
  thisPage,
  allPages,
}) => {
  let { t, i18n } = useTranslation(["events"]);
  let history = useHistory();

  React.useEffect(() => {
    getEventInvitees(eventId, 3, 1);
  }, []);

  console.log(users);

  const settings = {
    nextArrow: (
      <AiOutlineArrowRight
        size="30"
        onClick={() => (thisPage !== allPages && getEventInvitees(eventId, 3, thisPage + 1))}
      />
    ),
    prevArrow: <AiOutlineArrowLeft size="30" />,
    slidesPerRow: 3,
  };

  return (
    <div className="position-relative">
      <AiOutlineArrowLeft
        size="30"
        cursor="pointer"
        onClick={() => (thisPage !== 1 && getEventInvitees(eventId, 3, thisPage - 1))}
        className="position-absolute top-50 start-0 translate-middle-y"
      />
      <div className="d-flex flex-wrap mx-5">
      {users &&
        users.map((user, idx) => {
          return (
            <Card className="m-1" key={idx}>
              <Card.Meta
                avatar={
                  <Avatar
                    src={user?.image}
                    onClick={() => history.push("/users/" + user.id)}
                    style={{ cursor: "pointer" }}
                  />
                }
                title={user?.name}
                description={
                  <span>
                    {user?.status}{" "}
                    {user?.status === "accepted" &&
                      moment(user?.updatedAt).locale(i18n.language).fromNow()}
                  </span>
                }
              />
              <CardBody></CardBody>
            </Card>
          );
        })}
        </div>
      <AiOutlineArrowRight
        size="30"
        cursor="pointer"
        onClick={() => getEventInvitees(eventId, 3, thisPage + 1)}
        className="position-absolute top-50 end-0 translate-middle-y"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state?.events?.singleEvent?.users?.result,
  thisPage: state?.events?.singleEvent?.users?.thisPage,
  allPages: state?.events?.singleEvent?.users?.allPages,
});

const mapDispatchToProps = { getEventInvitees };

export default connect(mapStateToProps, mapDispatchToProps)(InviteesSlider);
