import React from "react";
import { connect } from "react-redux";
import { Modal, Input, Button } from "antd";
import { Row, Col } from "reactstrap";
import { editTask } from "../../../store/actions/taskActions";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import moment from "moment";

export const EditTask = ({
  editTask,
  isModalVisible,
  handleCancel,
  task,
}) => {
  let { t } = useTranslation(["tasks"]);

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const [taskData, setTaskData] = React.useState({
    title: "",
    startTime: "",
    endTime: "",
    description: "",
  });

  React.useEffect(() => {
    setTaskData({
      title: task?.title,
      startTime: moment(task?.startDate).locale('en-us'),
      endTime: moment(task?.endDate).locale('en-us'),
      description: task?.description,
    });
  }, [task]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setTaskData({ ...taskData, [name]: value });
  };

  const onStartDateChange = (date) => {
    setTaskData({ ...taskData, startTime: date.locale("en-us") });
  };

  const onEndDateChange = (date) => {
    setTaskData({ ...taskData, endTime: date.locale("en-us") });
  };

  function disabledDates(e) {
    return moment(e).dayOfYear() < moment(startTime).dayOfYear();
  }
  function disabledEndDateTime(e) {
    return {
      disabledHours: () =>
        range(0, moment(e) < moment(startTime) && moment(startTime).hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment(startTime) && moment(startTime).minute()),
    };
  }
  function disabledStartDateTime(e) {
    return {
      disabledHours: () => range(0, moment(e) < moment() && moment().hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment() && moment().minute()),
    };
  }

  const [addDescription, setAddDescription] = React.useState(false);
  const {
    title,
    startTime,
    endTime,
    description,
  } = taskData;

  const publishTask = () => {
    let data = new FormData();
    data.append("taskId", task?.id)
    title && data.append("title", title);
    startTime && data.append("startTime", startTime);
    endTime && data.append("endTime", endTime);
    description && data.append("description", description);
    editTask(data, handleCancel);
  };

  return (
    <Modal
      closable={false}
      title={t("edit_task")}
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={publishTask}
      okButtonProps={{
        className: "main-button",
        disabled: !title || !startTime || !endTime,
      }}
      okText={t("edit")}
      cancelButtonProps={{ className: "main-button-cancel" }}
      cancelText={t("cancel")}
    >
      <Row className="mb-2">
        <Col md={4}>
          <span>{t("form.task_title")}</span>
        </Col>
        <Col md={8}>
          <Input
            onChange={onChange}
            name="title"
            value={title}
            className="task-input"
            placeholder={t("form.to_remember")}
            required={true}
          />
        </Col>
      </Row>
      {addDescription && (
        <Row className="mb-2">
          <Col md={4}>
            <span>{t("form.task_description")}</span>
          </Col>
          <Col md={8}>
            <Input
              onChange={onChange}
              name="description"
              value={description}
              type="textarea"
              className="task-input"
              placeholder={t("form.task_description")}
              required={true}
            />
          </Col>
        </Row>
      )}
      <Row className="mb-2">
        <Col md={4}>
          <span>{t("form.start")}</span>
        </Col>
        <Col md={8}>
          <DatePicker
            showTime
            showSecond={false}
            onOk={onStartDateChange}
            defaultValue={startTime}
            name="startTime"
            className="task-input col-md-12"
            placeholder="Start Time"
            disabledDate={(e) => e < moment().startOf("day")}
            disabledTime={disabledStartDateTime}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <span>{t("form.end")}</span>
        </Col>
        <Col md={8}>
          <DatePicker
            showTime
            showSecond={false}
            onOk={onEndDateChange}
            defaultValue={endTime}
            name="endTime"
            className="task-input col-md-12"
            placeholder="End Time"
            disabledDate={disabledDates}
            disabledTime={disabledEndDateTime}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Button
            className={`main-button${addDescription ? "-selected" : ""}`}
            onClick={() => setAddDescription(!addDescription)}
          >
            Edit Description
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  contacts: state.contacts.contacts,
});

const mapDispatchToProps = {
  editTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTask);
