import { Input, Form, Modal, Row, Col, Select, DatePicker, Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getContactList } from "../../../store/actions/contactActions";
import AsyncSelect from "react-select/async";
import { createNewEvent } from "../../../store/actions/eventActions";
import moment from "moment";
import TagsInput from "react-tagsinput";
import GoogleMaps from "../../../components/GoogleMap/GoogleMaps";

export const CreateEvent = ({
  isModalVisible,
  handleCancel,
  getContactList,
  createNewEvent,
  populatedTitle,
  firebaseMessage,
  firebaseChat,
  user,
  firebaseChatMembers,
}) => {
  let { t } = useTranslation(["events"]);
  const [eventData, setEventData] = React.useState({
    title: populatedTitle,
    typeId: 3,
    startTime: "",
    endTime: "",
    description: "",
    users: [],
    tags: [],
    media: null,
    usersByIds: [],
  });
  const [defaultContacts, setDefaultContacts] = React.useState([]);
  React.useEffect(() => {
    if (populatedTitle) setEventData({ ...eventData, title: populatedTitle });
  }, [populatedTitle]);

  const getDeafultContacts = async () => {
    setDefaultContacts(await filterContacts());
  };

  React.useEffect(() => {
    if (isModalVisible) {
      getDeafultContacts();
    }
  }, [isModalVisible]);

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const onChange = (e) => {
    let { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
  };

  let {
    title,
    typeId,
    startTime,
    endTime,
    description,
    users,
    tags,
    media,
    usersByIds,
  } = eventData;

  const filterContacts = async (search) => {
    let newCats = await getContactList({ search });

    return newCats.map((contact) => {
      return { label: contact.name, value: contact.id };
    });
  };

  const filterChatMembers = async (search) => {
    console.log(firebaseChatMembers);
    if (firebaseChatMembers.otherUser) {
      return [
        {
          label: firebaseChatMembers?.otherUser?.name,
          value: firebaseChatMembers.otherUser?.id,
        },
      ];
    }

    let newCats = await firebaseChatMembers?.members?.filter((c) =>
      c.name?.includes(search)
    );

    return newCats.map((contact) => {
      return { label: contact.name, value: contact.id };
    });
  };

  const publishEvent = () => {
    let data = new FormData();
    usersByIds.map((assignee) => assignee.value).length !== 0 &&
      usersByIds.map((assignee) => data.append("usersByIds[]", assignee.value));
    title && data.append("title", title);
    typeId && data.append("typeId", typeId);
    startTime && data.append("startDate", startTime);
    endTime && data.append("endDate", endTime);
    description && data.append("description", description);
    users.map((user) => user.value).length !== 0 &&
      users.map((user) => data.append("users[]", user.value));
    tags && tags.map((tag) => data.append("tags[]", tag));
    currPosition.lat !== 0 && data.append("latitude", currPosition.lat);
    currPosition.lng !== 0 && data.append("longitude", currPosition.lng);

    firebaseMessage && data.append("firebaseMessageId", firebaseMessage);
    firebaseChat && data.append("chatId", firebaseChat);
    if (media) {
      let i;
      for (i = 0; i < media.length; i++) {
        data.append("media", media[i]);
      }
    }

    createNewEvent(data, firebaseMessage, firebaseChat);
    setEventData({
      title: "",
      typeId: "3",
      startTime: "",
      endTime: "",
      description: "",
      users: [],
      tags: [],
      media: null,
    });
    handleCancel();
  };

  const onStartDateChange = (date) => {
    setEventData({ ...eventData, startTime: date.locale("en-us") });
  };

  const onEndDateChange = (date) => {
    setEventData({ ...eventData, endTime: date.locale("en-us") });
  };

  function disabledDates(e) {
    return moment(e).dayOfYear() < moment(startTime).dayOfYear();
  }
  function disabledEndDateTime(e) {
    return {
      disabledHours: () =>
        range(0, moment(e) < moment(startTime) && moment(startTime).hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment(startTime) && moment(startTime).minute()),
    };
  }
  function disabledStartDateTime(e) {
    return {
      disabledHours: () => range(0, moment(e) < moment() && moment().hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment() && moment().minute()),
    };
  }

  const [addTags, setAddTags] = React.useState(false);
  const [addMedia, setAddMedia] = React.useState(false);
  const [addLocation, setAddLocation] = React.useState(false);
  const [currPosition, setCurrPosition] = React.useState({ lat: 0, lng: 0 });

  const onAddLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCurrPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
    setAddLocation(!addLocation);
  };

  const onMapClick = (e) => {
    setCurrPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    console.log(e.latLng);
  };

  const handleChangeTags = (tags) => {
    setEventData({ ...eventData, tags: tags });
  };
  React.useEffect(() => {
    if (firebaseChatMembers) {
      if (firebaseChatMembers.otherUser) {
        return setEventData({
          ...eventData,
          usersByIds: [
            {
              label: firebaseChatMembers.otherUser.name,
              value: firebaseChatMembers.otherUser.id,
            },
          ],
        });
      } else if (firebaseChatMembers.members) {
        return setEventData({
          ...eventData,
          usersByIds: firebaseChatMembers?.members
            ?.filter((member) => member.id !== user.id)
            .map((c) => ({
              label: c.name,
              value: c.id,
            })),
        });
      }
    }
  }, [firebaseChatMembers]);

  return (
    <Modal
      closable={false}
      title={t("create")}
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={publishEvent}
      okButtonProps={{
        className: "main-button",
        disabled: !title || !description || !startTime || !endTime,
      }}
      okText={t("ok")}
      cancelButtonProps={{ className: "main-button-cancel" }}
      cancelText={t("cancel")}
    >
      <Form>
        <Form.Item
          name="title"
          rules={[{ required: true, message: t("form.required.event_title") }]}
        >
          <Input
            onChange={onChange}
            name="title"
            value={title}
            defaultValue={populatedTitle ?? ""}
            type="textarea"
            className="task-input"
            placeholder={t("form.event_title")}
            label="Event Title"
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            { required: true, message: t("form.required.event_description") },
          ]}
        >
          <Input.TextArea
            onChange={onChange}
            name="description"
            value={description}
            className="task-input"
            placeholder={t("form.event_description")}
          />
        </Form.Item>
        <Row>
          <Col className="col-md-4">{t("start")}</Col>
          <Col className="col-md-8">
            <Form.Item
              name="startTime"
              rules={[
                {
                  required: true,
                  message: t("form.required.startTime"),
                },
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onOk={onStartDateChange}
                defaultValue={startTime}
                name="startTime"
                className="task-input col-md-12"
                placeholder={t("form.startTime")}
                disabledDate={(e) => e < moment().startOf("day")}
                disabledTime={disabledStartDateTime}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-4">{t("end")}</Col>
          <Col className="col-md-8">
            <Form.Item
              name="endTime"
              rules={[
                {
                  required: true,
                  message: t("form.required.endTime"),
                },
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onOk={onEndDateChange}
                defaultValue={endTime}
                name="endTime"
                className="task-input col-md-12"
                placeholder={t("form.endTime")}
                disabledDate={disabledDates}
                disabledTime={disabledEndDateTime}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-4">{t("form.type")}</Col>
          <Col className="col-md-8">
            <Form.Item>
              <Select
                className="task-input"
                defaultValue={"3"}
                onChange={(e) => setEventData({ ...eventData, typeId: e })}
                help="Event type"
              >
                <Select.Option value={"1"}>{t("form.date")}</Select.Option>
                <Select.Option value={"2"}>
                  {t("form.invitation")}
                </Select.Option>
                <Select.Option value={"3"}>{t("form.meeting")}</Select.Option>
                <Select.Option value={"4"}>{t("form.party")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {firebaseChat && (
          <Row className="mb-2">
            <Col className="col-md-4">
              <span>{t("form.assigneesFromChat")}</span>
            </Col>

            <Col className="col-md-8">
              <AsyncSelect
                cacheOptions
                isMulti
                defaultOptions
                value={eventData.usersByIds}
                loadOptions={filterChatMembers}
                className="task-input"
                onChange={(values) => {
                  setEventData({ ...eventData, usersByIds: values });
                }}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="col-md-4">{t("invitees")}</Col>
          <Col className="col-md-8">
            <Form.Item>
              <AsyncSelect
                cacheOptions
                isMulti
                defaultOptions={defaultContacts}
                className="task-input"
                loadOptions={filterContacts}
                onChange={(values) => {
                  setEventData({ ...eventData, users: values });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {addMedia && (
          <Row>
            <Col className="col-md-4">{t("media")}</Col>
            <Col className="col-md-8">
              <Form.Item>
                <Input
                  onChange={(e) =>
                    setEventData({ ...eventData, media: e.target.files })
                  }
                  className="p-2 task-input col-sm"
                  type="file"
                  multiple={true}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {addTags && (
          <Row className="mb-2">
            <Col className="col-md-4">{t("tags")}</Col>
            <Col className="col-md-8">
              <TagsInput
                maxTags="15"
                className="task-input"
                onChange={handleChangeTags}
                name="tags"
                value={tags}
              />
            </Col>
          </Row>
        )}
        {addLocation && (
          <Row className="mb-2">
            <Col className="col-md-4">{t("location")}</Col>
            <Col className="col-md-8">
              <GoogleMaps
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDGEsJYp-Oc9e03h_pWHoTwITKP9VVNqTo&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                zoom={15}
                lat={currPosition.lat}
                lng={currPosition.lng}
                onClick={onMapClick}
              />
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <Col>
            <Button
              className={`main-button${addMedia ? "-selected" : ""}`}
              onClick={() => setAddMedia(!addMedia)}
            >
              Add Media
            </Button>
            <Button
              className={`main-button${addTags ? "-selected" : ""}`}
              onClick={() => setAddTags(!addTags)}
            >
              Add Tags
            </Button>
            <Button
              className={`main-button${addLocation ? "-selected" : ""}`}
              onClick={onAddLocation}
            >
              Add Location
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({ user: state.auth?.user });

const mapDispatchToProps = {
  getContactList,
  createNewEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
