import React from "react";
import { connect } from "react-redux";
import { deleteTask, getTasks } from "../../../store/actions/taskActions";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import TaskItem from "../TaskItem/TaskItem";
import TaskListItem from "../TaskListItem";
import { useTranslation } from "react-i18next";
import moment from "moment";
import FilterBox from "../FilterBox";
import { Button, Switch, Modal } from "antd";
import { FiFilter } from "react-icons/fi";
import { VscLoading } from "react-icons/vsc";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import { GrTable } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import emptyicon from "../../../Assets/Icons/empty-white-box.png";

export const PaginateTasks = ({
  tasks,
  thisPage,
  allPages,
  getTasks,
  history,
  tab,
  loading,
  deleteTask,
}) => {
  const onClick = (event) => {
    switch (event.target.textContent) {
      case "»":
      case "»Last":
      case "Last":
        getTasks({ limit: 9, ...filters }, allPages);
        break;
      case "«":
      case "«First":
      case "First":
        getTasks({ limit: 9, ...filters }, 1);
        break;
      case "›":
      case "›Next":
      case "Next":
        getTasks({ limit: 9, ...filters }, thisPage + 1);
        break;
      case "‹":
      case "‹Previous":
      case "Previous":
        getTasks({ limit: 9, ...filters }, thisPage - 1);
        break;
      default:
        getTasks({ limit: 9, ...filters }, Number(event.target.textContent));
        break;
    }
  };

  let { t } = useTranslation(["tasks"]);
  let { t: t2 } = useTranslation(["delete"]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteTask(selectedTasks);
    setDeleting(!deleting);
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const filterTypes = [
    { name: t("filters.created"), value: "creator" },
    {
      name: t("filters.assigned"),
      value: "assigned",
    },
  ];

  const filterStatus = [
    {
      id: 1,
      status: t("filters.pending"),
      value: "pending",
    },
    {
      id: 3,
      status: t("filters.done"),
      value: "done",
    },

    {
      id: 2,
      status: t("filters.accepted"),
      value: "accepted",
    },
    {
      id: 4,
      status: t("filters.rejected"),
      value: "rejected",
    },
  ];

  const [filters, setFilters] = React.useState({
    type: filterTypes[tab - 1].value,
    status: undefined,
    date: undefined,
    text: undefined,
    startDate: undefined,
    endDate: undefined,
    categoryId: undefined,
  });

  const clearFilters = () => {
    setFilters({
      type: filterTypes[tab - 1].value,
      status: undefined,
      date: undefined,
      text: undefined,
      startDate: undefined,
      endDate: undefined,
      categoryId: undefined,
    });

    return getTasks({ ...filters, limit: 9 }, 1);
  };

  const handleChange = (e) => {
    let { name, value } = e.target || e;
    setFilters({ ...filters, [name]: value === "" ? undefined : value });
    console.log(filters);
    if (name !== "text") {
      return getTasks(
        { ...filters, [name]: value === "" ? undefined : value, limit: 9 },
        thisPage
      );
    }
  };

  const handleSearch = (text) => {
    return getTasks(
      { ...filters, text: text === "" ? undefined : text, limit: 9 },
      thisPage
    );
  };

  const handleDateChange = (target, date) => {
    setFilters({ ...filters, [target]: date });
    console.log(date);
    return getTasks({ ...filters, [target]: date, limit: 5 }, thisPage);
  };

  React.useEffect(() => {
    getTasks({ ...filters, limit: 9 }, 1);
  }, [tab]);

  const [switchVal, setSwitchVal] = React.useState(true);
  const [selectedTasks, setSelectedTasks] = React.useState([]);
  const onSelect = (id) => {
    let found = selectedTasks.find((taskId) => taskId === id);
    if (found) {
      setSelectedTasks(selectedTasks.filter((taskId) => taskId !== id));
    } else {
      setSelectedTasks([...selectedTasks, id]);
    }
  };

  React.useEffect(() => {
    setSelectedTasks([]);
  }, [tab, switchVal, deleting]);

  return (
    <div className="col p-2 ">
      <Modal
        title={t2("confirm")}
        visible={isModalVisible}
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ className: "main-button" }}
        okText={t2("ok")}
        cancelText={t2("cancel")}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <p>
          {t2("sure")} {selectedTasks.length} tasks
        </p>
      </Modal>
      <div className="d-flex m-2 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            {tab === 1 ? "Tasks created by me" : "Tasks assigned to me"}
          </h2>
        </div>
        <div>
          <Switch
            unCheckedChildren={<GrTable />}
            checkedChildren={<BsFillGrid3X2GapFill />}
            defaultChecked
            onChange={setSwitchVal}
            style={{ size: 30 }}
          />
          <Button
            className="text-center main-button mx-1"
            style={{ cursor: "pointer" }}
            onClick={() => setFilterOpen(!filterOpen)}
            id="close"
          >
            <FiFilter size="20" color="white" />
          </Button>
        </div>
      </div>
      <div>
        {filterOpen && (
          <FilterBox
            filterTypes={filterTypes}
            setFilters={setFilters}
            clearFilters={clearFilters}
            filters={filters}
            handleChange={handleChange}
            filterStatus={filterStatus}
            handleDateChange={handleDateChange}
            handleSearch={handleSearch}
            t={t}
          />
        )}
      </div>
      {loading && (
        <div
          className="col text-center rotator"
          style={{
            fontSize: "40px",
            color: "var(--primaryColor)",
          }}
        >
          <VscLoading />
        </div>
      )}
      {!loading && tasks.length === 0 && (
        <div>
          <div className="col text-center">
            <img
              src={emptyicon}
              alt="Empty"
              style={{ width: 100, height: 100 }}
            />
          </div>
          <div
            className="col text-center"
            style={{
              fontSize: "40px",
              color: "var(--primaryColor)",
            }}
          >
            {t("no_tasks")}
          </div>
        </div>
      )}
      <div className="d-flex flex-wrap">
        {!loading &&
          tasks &&
          tasks.map((task, i) => {
            if (switchVal) {
              return (
                <TaskItem
                  history={history}
                  task={task}
                  key={i}
                  page={thisPage}
                  filters={filters}
                  type={filters.type}
                  onSelect={onSelect}
                />
              );
            } else {
              return (
                <TaskListItem
                  history={history}
                  task={task}
                  key={i}
                  page={thisPage}
                  filters={filters}
                  type={filters.type}
                  onSelect={onSelect}
                />
              );
            }
          })}
      </div>
      <div>
        <Button
          className="main-button-cancel"
          onClick={showModal}
          disabled={selectedTasks.length === 0}
        >
          <MdDelete /> Delete all selected ({selectedTasks.length})
        </Button>
      </div>
      {allPages > 1 && (
        <div className="col d-flex justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className="pag-link"
                >
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index}
                      className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tasks: state?.task?.tasks,
  loading: state?.task?.loading,
  thisPage: state?.task?.thisPage,
  allPages: state?.task?.allPages,
});

const mapDispatchToProps = { getTasks, deleteTask };

export default connect(mapStateToProps, mapDispatchToProps)(PaginateTasks);
