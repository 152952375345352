import React from "react";
import { connect } from "react-redux";
import { Modal, Input, Form } from "antd";
import { createNewContact } from "../../../store/actions/contactActions";
import { FaUserAlt, FaMobile } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import validator from "validator";
export const QuickContactForm = ({
  isModalVisible,
  createNewContact,
  handleCancel,
}) => {
  let { t } = useTranslation(["contacts"]);
  const [contactData, setContactData] = React.useState({
    name: "",
    mobile: "",
  });
  let { name, mobile } = contactData;

  const [image, setImage] = React.useState(null);

  const onImageChange = (e) => {
    setImage(e.file.originFileObj);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
  };

  const createContact = () => {
    let formData = new FormData();
    name && formData.append("name", name);
    mobile && formData.append("mobile", mobile);
    image && formData.append("image", image);
    createNewContact(formData);
    handleCancel();
  };
  const validatePhoneNumber = (number) => {
    if (number) {
      const isValidPhoneNumber = validator.isMobilePhone(number, [
        "ar-EG",
        "ar-SA",
      ]);

      if (String(number).length !== 12) {
        return false;
      }

      if (!String(number).startsWith("2") || !String(number).startsWith("9")) {
        return false;
      }

      if (!isValidPhoneNumber) {
        return false;
      }

      return isValidPhoneNumber;
    } else return false;
  };
  const [form] = Form.useForm();
  return (
    <Modal
      title={t("form.create")}
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={createContact}
      okText={t("form.add")}
      cancelText={t("form.cancel")}
      okButtonProps={{
        disabled: !name || !mobile || mobile.length !== 12,
        className: "main-button",
      }}
      cancelButtonProps={{ className: "main-button-cancel" }}
    >
      <div className="col-md-12 justify-content-center">
        <div className="col">
          <Form form={form}>
            <ImgCrop rotate>
              <Upload
                listType="picture-card"
                onChange={onImageChange}
                multiple={false}
                className="d-flex justify-content-center"
              >
                {(!image || image.status === "removed") && "Upload"}
              </Upload>
            </ImgCrop>

            <Form.Item
              name="name"
              rules={[{ required: true, message: t("form.required.name") }]}
            >
              <Input
                prefix={<FaUserAlt />}
                type="text"
                className="task-input"
                onChange={onChange}
                value={name}
                name="name"
                placeholder={` ${t("form.name")}`}
              />
            </Form.Item>

            <Form.Item
              name="mobile"
              validateStatus={
                validatePhoneNumber(form.getFieldValue("mobile"))
                  ? "success"
                  : "error"
              }
              help={
                !validatePhoneNumber(form.getFieldValue("mobile")) &&
                "Phone must be valid phone number starts with country code"
              }
              rules={[
                {
                  required: true,
                  len: 12,
                  message: t("form.required.mobile"),
                },
                {
                  pattern: "^(2)[0-9]{11}$",
                  message: "Phone number must start with country code",
                },
              ]}
            >
              <Input
                type="number"
                prefix={<FaMobile />}
                className="task-input"
                type="number"
                onChange={onChange}
                name="mobile"
                value={mobile}
                placeholder={` ${t("form.mobile")}`}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  createNewContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickContactForm);
