import React from "react";
import { connect } from "react-redux";
import { Modal, DatePicker } from "antd";
import { Row, Col } from "reactstrap";
import { createNewAppointment } from "../../../store/actions/appointmentsActions";
import moment from "moment";

export const CreateAppointment = ({
  createNewAppointment,
  isModalVisible,
  handleCancel,
}) => {
  const [slotData, setSlotData] = React.useState({
    startDate: moment().locale("en-us"),
    endDate: "",
  });

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  let { startDate, endDate } = slotData;

  const onStartDateChange = (date) => {
    setSlotData({ ...slotData, startDate: date.locale("en-us") });
  };

  const onEndDateChange = (date) => {
    setSlotData({ ...slotData, endDate: date.locale("en-us") });
  };

  function disabledDates(e) {
    return moment(e).dayOfYear() !== moment(startDate).dayOfYear();
  }
  function disabledEndDateTime(e) {
    return {
      disabledHours: () => [
        ...range(0, moment(startDate).hour()),
        ...range(
          moment(startDate).hour() + 6,
          24
        ),
      ],
      disabledMinutes: () =>
        range(0, moment(e) < moment(startDate) && moment(startDate).minute()),
    };
  }
  function disabledStartDateTime(e) {
    return {
      disabledHours: () => range(0, moment(e) < moment() && moment().hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment() && moment().minute()),
    };
  }

  const handleOk = () => {
      let data = new FormData();
      data.append("startDate",startDate)
      data.append("endDate",endDate)
      createNewAppointment(data, handleCancel)
      setSlotData({
        startDate: "",
        endDate: "",
      })
  };

  return (
    <Modal
      okButtonProps={{
        className: "main-button",
        disabled: !startDate || !endDate,
      }}
      cancelButtonProps={{ className: "main-button-cancel" }}
      title="Add Free Slot"
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Row className="mb-2">
        <Col md={4}>
          <span>Start Time</span>
        </Col>
        <Col md={8}>
          <DatePicker
            showTime
            showSecond={false}
            onOk={onStartDateChange}
            defaultValue={startDate}
            name="startDate"
            className="task-input col-md-12"
            placeholder="Start Time"
            disabledDate={(e) => e < moment().startOf("day")}
            disabledTime={disabledStartDateTime}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <span>End Time</span>
        </Col>
        <Col md={8}>
          <DatePicker
            showTime
            showSecond={false}
            onOk={onEndDateChange}
            defaultValue={endDate}
            name="endDate"
            className="task-input col-md-12"
            placeholder="End Time"
            disabledDate={disabledDates}
            disabledTime={disabledEndDateTime}
          />
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  createNewAppointment,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppointment);
