import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import erroricon from "../../Assets/Icons/warning.png";
import { connect } from 'react-redux'

export const NotAllowed = ({path}) => {
  let history = useHistory()

  return (
    <div className="container main-page my-5">
      <div className="align-middle">
        <div className="text-center">
          <img
            style={{ width: 200, height: 200 }}
            src={erroricon}
            alt="Error 404"
          />
        </div>
        <h1 className="main-text text-center" style={{fontSize: 30}}>
          You don't have access to this page!
        </h1>
        <div className="text-center">
          <Button onClick={() => history.push(path)} className="main-button">Go back</Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(NotAllowed)
