import React from "react";
import { connect } from "react-redux";
import { TabContent, NavItem, NavLink, Nav, TabPane, Row } from "reactstrap";
import classnames from "classnames";
import UserAppointments from "./UserAppointments";
import UserTasks from "./UserTasks";
import { useTranslation } from "react-i18next";

export const Tabs = (props) => {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let { t } = useTranslation(["userProfile"]);

  return (
    <div className="my-3">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("1");
            }}
          >
            {t("appointments")}
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("2");
            }}
          >
            {t("tasks")}
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <UserAppointments/>
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="2">
          <Row>
            <UserTasks />
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = (state) => ({
  task: state?.task?.singleTask?.task,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
