import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Navbar from "./components/Navbar";
import { connect } from "react-redux";
import ProtectedRoute from "./Routes/ProtectedRoute";
import SystemOwnerRoute from "./Routes/SystemOwnerRoute";
import { useLocation } from "react-router-dom";

import store from "./store/store";
import { getCategories } from "./store/actions/categoryActions";

import {
  Categories,
  UsersPage,
  Tasks,
  Login,
  Home,
  Chatbot,
  ChatBotManagement,
  ContactList,
  Profile,
  Notifications,
  ForgotPassword,
  EmailVerification,
  ResetPassword,
  Templates,
  Favourites,
  CalendarPage,
  Task,
  Events,
  EventPage,
  TodoList,
  UserProfile,
  Appointments,
  VerifyPhoneNumber,
  NotificationPage,
  Register,
  ResetPasswordPhone,
} from "./Pages/index";
import TopNavBar from "./components/TopBar";
import Loader from "./components/Loader";

export const Main = ({ token, isLoading }) => {
  const [navbarIsOpen, setnavbarIsOpen] = React.useState(false);
  React.useEffect(() => {
    if (token) {
      store.dispatch(getCategories());
    }
  }, [token]);

  if (isLoading) return <Loader />;

  return (
    <div>
      <BrowserRouter>
        <div
          style={{
            maxHeight: "100vh",
            overflowY: "hidden",
          }}
        >
          {token && window.location.pathname !== "/chat" && <TopNavBar />}

          <div className={`mx-0 ${navbarIsOpen ? "row" : ""}`}>
            {token && (
              <>
                <Navbar isOpen={navbarIsOpen} setIsOpen={setnavbarIsOpen} />
              </>
            )}
            <div className="col">
              <Switch>
                <ProtectedRoute exact path={"/"} component={Home} />

                <ProtectedRoute
                  exact
                  path={"/appointments"}
                  component={Appointments}
                />

                <ProtectedRoute exact path={"/profile"} component={Profile} />

                <ProtectedRoute
                  exact
                  path={"/users/:userId"}
                  component={UserProfile}
                />

                <ProtectedRoute exact path={"/tasks"} component={Tasks} />

                <ProtectedRoute
                  exact
                  path={"/templates"}
                  component={Templates}
                />

                <ProtectedRoute
                  exact
                  path={"/favourites"}
                  component={Favourites}
                />

                <ProtectedRoute
                  exact
                  path={"/calendar"}
                  component={CalendarPage}
                />

                <ProtectedRoute
                  exact
                  path={"/tasks/:taskId"}
                  component={Task}
                />

                <ProtectedRoute exact path={"/events"} component={Events} />

                <ProtectedRoute exact path={"/todo"} component={TodoList} />

                <ProtectedRoute
                  exact
                  path={"/events/:eventId"}
                  component={EventPage}
                />

                <Route exact path="/login" component={Login} />

                <Route
                  exact
                  path="//confirm/:token"
                  component={EmailVerification}
                />

                <Route
                  exact
                  path="//reset-password/:token"
                  component={ResetPassword}
                />

                <Route
                  exact
                  path="/forgotpassword"
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path="/phone-reset"
                  component={ResetPasswordPhone}
                />
                <Route
                  exact
                  path="/verify-phone"
                  component={VerifyPhoneNumber}
                />

                <Route exact path="/register" component={Register} />

                <ProtectedRoute
                  exact
                  path="/categories"
                  component={Categories}
                />

                <SystemOwnerRoute exact path="/users" component={UsersPage} />

                <SystemOwnerRoute
                  exact
                  path="/chatbot/manage"
                  component={ChatBotManagement}
                />

                <ProtectedRoute exact path="/chatbot" component={Chatbot} />

                <ProtectedRoute
                  exact
                  path="/contacts"
                  component={ContactList}
                />

                <ProtectedRoute
                  exact
                  path="/notifications"
                  component={NotificationPage}
                />
              </Switch>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state?.auth?.isAuthenticated,
  isLoading: state.auth?.isLoading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
