import React from "react";
import { connect } from "react-redux";
import { Badge, Menu, Dropdown, Avatar } from "antd";
import { useTranslation } from "react-i18next";
import { IoMdNotifications } from "react-icons/io";
import {
  getTopNotificationAction,
  changeNotificationSeenCount,
  notificationRequestAction,
} from "../../store/actions/notificationActions";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import moment from "moment";
import "moment/locale/ar";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
export const NotificationDropdown = ({
  notificationUnSeenCount,
  getTopNotificationAction,
  topNotifications,
  topLoading,
  changeNotificationSeenCount,
  notificationRequestAction,
}) => {
  const history = useHistory();
  let { t, i18n } = useTranslation(["sidebar"]);

  const GetNotificationAction = ({ notification }) => {
    const handleNotifyRequest = (status) => {
      notificationRequestAction(String(status), notification);
    };

    return (
      <>
        <span className="mx-3">
          <AiOutlineCheckCircle
            size="25"
            color="var(--acceptGreen)"
            style={{ cursor: "pointer" }}
            onClick={() => handleNotifyRequest("accept")}
          />
        </span>
        <span className="mx-3">
          <AiOutlineCloseCircle
            size="25"
            color="#E91E1F"
            style={{ cursor: "pointer" }}
            onClick={() => handleNotifyRequest("decline")}
          />
        </span>
      </>
    );
  };

  React.useEffect(() => {
    changeNotificationSeenCount("clear");
  }, []);

  const menu = (
    <Menu>
      {topNotifications?.length !== 0 ? (
        <>
          {topNotifications?.map((notification, key) => {
            return (
              <Menu.Item key={key}>
                <div className="row justify-content-start align-content-center">
                  <div className="col  text-start">
                    <Avatar
                      src={
                        notification?.image || (
                          <IoMdNotifications color="var(--primaryColor)" />
                        )
                      }
                    />
                  </div>
                  <div className="col text-start">
                    <div
                      style={{
                        width: "60ch",
                        wordBreak: "break-word",
                        wordWrap: "break-word",
                      }}
                    >
                      <span
                        style={{
                          color: "var(--primaryColor)",
                          fontWeight: "bold",
                          fontFamily: "Cairo",
                          wordBreak: "break-word",
                          wordWrap: "break-word",
                        }}
                      >
                        {notification?.message?.length > 60
                          ? notification?.message?.substring(0, 60) + "..."
                          : notification?.message}
                      </span>
                    </div>
                    <div className="my-2">
                      <span
                        style={{
                          color: "var(--darkGrey)",
                          fontWeight: "normal",
                          fontFamily: "Cairo",
                          fontSize: "12px",
                        }}
                      >
                        {moment(notification?.createdAt || new Date())
                          .locale(i18n?.language)
                          .fromNow()}
                      </span>
                    </div>
                    {notification.action === true && (
                      <div className="row">
                        <div className="col-6">
                          {
                            <GetNotificationAction
                              notification={notification}
                            />
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Menu.Item>
            );
          })}
          <Menu.Divider />
          <Menu.Item>
            <div
              className="text-center"
              onClick={() => {
                history.push("/notifications");
              }}
            >
              View All
            </div>
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item className="px-5 py-3">
            <h5 className="main-text">Empty Notification list.</h5>
          </Menu.Item>
          <Menu.Item>
            <div
              className="text-center"
              onClick={() => {
                history.push("/notifications");
              }}
            >
              View Page
            </div>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
  );

  React.useEffect(() => {
    setInterval(() => {
      getTopNotificationAction();
    }, 100000);
  }, []);
  return (
    <div>
      {topLoading ? (
        <Spin indicator={antIcon} />
      ) : (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="bottomRight"
          arrow
        >
          <Badge count={notificationUnSeenCount}>
            <IoMdNotifications size="25" style={{ color: "white" }} />
          </Badge>
        </Dropdown>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  notificationUnSeenCount: state?.notifications?.unSeenCount,
  topNotifications: state?.notifications?.topNotifications,
  topLoading: state?.notifications?.topLoading,
});

const mapDispatchToProps = {
  getTopNotificationAction,
  changeNotificationSeenCount,
  notificationRequestAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDropdown);
