import {
  AUTH_ERROR,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_LOADED,
  USER_LOADING,
  LOGOUT_SUCCESS,
  NEEDS_VERFICATION,
  NEEDS_VERFICATION_ERROR,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  fbToken: localStorage.getItem("fbToken"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  error: false,
  needsVerification: false,
  verifyError: null,
  customError: "",
  regLoading: false,
  successReg: false,
  successReg: false,
};

export default function store(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case NEEDS_VERFICATION_ERROR:
      return {
        ...state,
        loading: false,
        verifyError: payload,
        regLoading: false,
        successReg: false,
      };
    case NEEDS_VERFICATION:
      return {
        isAuthenticated: false,
        isLoading: false,
        user: payload.user,
        token: payload.token,
        fbToken: payload.fbToken,
        needsVerification: true,
        verifyError: null,
        regLoading: false,
        successReg: false,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: payload.user,
        token: payload.token,
        fbToken: payload.fbToken,
        verifyError: null,
        regLoading: false,
        successReg: false,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
        regLoading: false,
        successReg: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("fbToken", action.payload.fbToken);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload.user,
        token: action.payload.token,
        fbToken: action.payload.fbToken,
        needsVerification: false,
        verifyError: null,
        regLoading: false,
        successReg: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        regLoading: false,
        successReg: true,
      };
    case LOGIN_FAIL:
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("fbToken");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        needsVerification: false,
        isLoading: false,
        customError: payload?.customError || "",
        regLoading: false,
        successReg: false,
      };
    case "REGISTER_LODING":
      return {
        ...state,
        regLoading: true,
        successReg: false,
      };
    default:
      return state;
  }
}
