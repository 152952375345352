import {
    LOADING_TAGS,
    LOAD_TAGS,
} from "../actions/types";

const initialState = {
    tags: [],
    error: false,
    loading: false,
    thisPage: 1,
    allPages: 1,
    count: 0,
};

export default function favouritesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOAD_TAGS:
            return {
                ...state,
                tags: payload.result,
                thisPage: payload.thisPage,
                allPages: payload.allPages,
                count: payload.count,
                error: false,
                loading: false,
            };
        case LOADING_TAGS:
            return {
                ...state,
                error: false,
                loading: true,
            };
        default:
            return {
                ...state
            }

    }
}
