import {
  USERS_FAIL,
  USERS_LOADING,
  LOAD_USERS,
  LOAD_SINGLE_USER,
} from "./types";

import axios from "axios";

import { apis } from "../../constants/API";

import { tokenConfig } from "./authAcrions";
import { toastr } from "react-redux-toastr";

export const createNewUser = (data) => (dispatch, getState) => {
  axios
    .post(apis.user.manage, data, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data, message },
      } = res;
      const oldUsers = getState().users?.users;
      let newUsers = [data, ...oldUsers];
      toastr.success("Good Job!", message);
      return dispatch({
        type: LOAD_USERS,
        payload: { result: newUsers },
      });
    })
    .catch((error) => {
      console.log(error);
      if (error.response?.data?.error?.message) {
        return toastr.error("Error", "Category Name is required");
      }
      if (
        error.response?.data &&
        typeof error.response?.data.error == "string"
      ) {
        toastr.error("Login Error", error.response.data.error);
      }
    });
};

export const getUsers = (query, page) => (dispatch, getState) => {
  dispatch({
    type: USERS_LOADING,
  });
  axios
    .get(apis.user.manage, {
      ...tokenConfig(getState),
      params: {
        ...query,
        page,
        limit: query?.limit ? query?.limit : 49,
        id: query?.userId ? query?.userId : null,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_USERS,
        payload: {
          result: data.result,
          allPages: data.allPages,
          thisPage: data.thisPage,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USERS_FAIL });
    });
};

export const getUserProfile = (userId) => (dispatch, getState) => {
  dispatch({
    type: USERS_LOADING,
  });
  axios
    .get(apis.user.userProfile, {
      ...tokenConfig(getState),
      params: { userId },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_SINGLE_USER,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USERS_FAIL });
    });
};
