import React from "react";
import { connect } from "react-redux";
import { getCategories } from "../../store/actions/categoryActions";
import CreateNewCategory from "./CreateNewCategory";
import { deleteCategory } from "../../store/actions/categoryActions";
import { BsFolderFill } from "react-icons/bs";
import { Button, Switch } from "antd";
import { GrTable } from "react-icons/gr";
import { AiFillFolderAdd } from "react-icons/ai";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import TableView from "./Views/TableView";
import CardsView from "./Views/CardsView";

export const Categories = ({ categories, getCategories, deleteCategory }) => {
  const [addOpen, setAddOpen] = React.useState(false);
  const [switchVal, setSwitchVal] = React.useState(true);

  return (
    <div className="container mb-5 main-page">
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            <BsFolderFill /> Manage Task Categories
          </h2>
        </div>
        <div>
          <Button
            className="text-center main-button mx-2"
            style={{ cursor: "pointer" }}
            onClick={() => setAddOpen(!addOpen)}
            id="close"
          >
            <AiFillFolderAdd size="30" color="white" />
          </Button>
        </div>
      </div>
      <div className="mt-5">{addOpen && <CreateNewCategory />}</div>
      <div className="row-md-12 p-2">
        <div className="position-relative col mb-3">
          <Switch
            checkedChildren={<GrTable />}
            unCheckedChildren={<BsFillGrid3X2GapFill />}
            defaultChecked
            onChange={setSwitchVal}
            style={{ size: 30 }}
            className="position-absolute top-50 end-0 translate-middle "
          />
        </div>
        {switchVal ? <TableView /> : <CardsView />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state?.category?.categories,
});

const mapDispatchToProps = { getCategories, deleteCategory };

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
