import React from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row } from 'reactstrap'
import NotificationConfig from './NotificationConfig';
import classnames from 'classnames';
import ChangePassword from './ChangePassword';

export const Tabs = (props) => {
    const [activeTab, setActiveTab] = React.useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="my-3">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        style={{color: "var(--primaryColor)", backgroundColor: "var(--mainWhite)"}}
                        onClick={() => { toggle('1'); }}
                    >
                        Notification Settings
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        style={{color: "var(--primaryColor)", backgroundColor: "var(--mainWhite)"}}
                        onClick={() => { toggle('2'); }}
                    >
                        Change Password
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} >
                <TabPane tabId="1">
                    <Row>
                        <NotificationConfig/>
                    </Row>
                </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab} >
                <TabPane tabId="2">
                    <Row>
                        <ChangePassword/>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    );
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Tabs)
