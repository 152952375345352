import axios from "axios";

import { apis } from "../../constants/API";
import {
    LOADING_TAGS,
    LOAD_TAGS,
} from "./types";

import { tokenConfig } from "./authAcrions";
import { toastr } from "react-redux-toastr";

export const getTags = (query, page) => (dispatch, getState) => {
    dispatch({
        type: LOADING_TAGS,
    });
    return axios
        .get(apis.getTags, {
            ...tokenConfig(getState),
            params: {
                ...query,
                limit: query.limit ? query.limit : 50,
                page,
            },
        })
        .then((res) => {
            const {
                data: { data },
            } = res;
            let currTags = getState().tags?.tags
            currTags.push(...data.result)
            console.log(currTags)
            dispatch({
                type: LOAD_TAGS,
                payload: { result: currTags, thisPage: data.thisPage, allPages: data.allPages, count: data.count },

            });
        })
        .catch((err) => {
            console.log(err)
        });
};