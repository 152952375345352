import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getEvents } from "../../store/actions/eventActions";
import PaginateEvents from "./PaginateEvents";
import CreateEvent from "./CreateEvent";
import { Button } from "antd";
import { FaRegCalendarPlus } from "react-icons/fa";
import { AiOutlineCalendar } from "react-icons/ai";
import { TabContent, NavItem, NavLink, Nav, TabPane, Row } from "reactstrap";
import classnames from "classnames";

export const Events = ({ getEvents, history, events, loading, thisPage }) => {
  let { t } = useTranslation(["events"]);

  const [createOpen, setcreateOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const filterTypes = [
    {
      name: t("filter.created"),
      value: "creator",
    },
    {
      name: t("filter.invited"),
      value: "invited",
    },
  ];

  const filterStatus = [
    {
      id: 1,
      status: t("filter.pending"),
      value: "pending",
    },
    {
      id: 2,
      status: t("filter.accepted"),
      value: "accepted",
    },
  ];

  const [filters, setFilters] = React.useState({
    type: filterTypes[1].value,
    status: filterStatus[0].value,
  });


  React.useEffect(() => {
    getEvents({ limit: 10, type: activeTab === "1" ? "creator":"invited", status: activeTab === "1" ? undefined : activeTab === "2" ? "pending":"accepted"}, 1);
  }, [activeTab]);

  const handleCancel = () => {
    setcreateOpen(false);
  };

  return (
    <div className=" mb-5 main-page"  style={{ minHeight: "100vh" }}>
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            <AiOutlineCalendar /> {t("events")}
          </h2>
        </div>
        <div>
          <Button
            className="text-center main-button"
            style={{ cursor: "pointer" }}
            onClick={() => setcreateOpen(!createOpen)}
            id="close"
          >
            <FaRegCalendarPlus size="25" color="white" />
          </Button>
        </div>
      </div>
      <div>
        <CreateEvent isModalVisible={createOpen} handleCancel={handleCancel} />
      </div>
      <Nav tabs>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("1");
            }}
          >
            Created Events
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("2");
            }}
          >
            Pending Events
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("3");
            }}
          >
            Accepted Events
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <PaginateEvents tab={1} history={history} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="2">
          <Row>
            <PaginateEvents tab={2} history={history} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="3">
          <div className="container p-2">
            <PaginateEvents tab={3} history={history}/>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = (state) => ({
  events: state?.events?.events,
  loading: state?.events?.loading,
  thisPage: state?.events?.thisPage,
});

const mapDispatchToProps = {
  getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
