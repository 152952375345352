import axios from "axios";

import { apis } from "../../constants/API";
import {
  CATS_FAIL,
  CATS_LOADING,
  LOAD_CATS,
  LOAD_SINGLE_CAT,
  TASK_CATS_LOADING,
  LOAD_TASK_CATS
} from "./types";

import { tokenConfig } from "./authAcrions";
import { toastr } from "react-redux-toastr";

export const getCategories = (query, page) => (dispatch, getState) => {
  dispatch({
    type: CATS_LOADING,
  });
  axios
    .get(apis.viewMyCategories, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: query?.limit ? query?.limit : 30,
        page,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_CATS,
        payload: {
          result: data.result,
          thisPage: data.thisPage,
          allPages: data.allPages,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const getTaskCategories = (query, page) => (dispatch, getState) => {
  dispatch({
    type: TASK_CATS_LOADING,
  });
  axios
    .get(apis.viewMyTaskCategories, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: query?.limit ? query?.limit : 30,
        page,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_TASK_CATS,
        payload: {
          result: data.result,
          thisPage: data.thisPage,
          allPages: data.allPages,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const addNewCategory = (data) => (dispatch, getState) => {
  axios
    .post(apis.viewMyCategories, data, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data, message },
      } = res;

      const oldCats = getState().category.categories;
      let newCats = [data, ...oldCats];
      toastr.success("Good Job!", message);
      return dispatch({
        type: LOAD_CATS,
        payload: { result: newCats },
      });
    })
    .catch((error) => {
      console.log(error);
      if (error.response?.data?.error?.message) {
        return toastr.error("Error", "Category Name is required");
      }
      if (
        error.response?.data &&
        typeof error.response?.data.error == "string"
      ) {
        toastr.error("Login Error", error.response.data.error);
      }
    });
};

export const deleteCategory = (categoryId) => (dispatch, getState) => {
  axios
    .delete(apis.viewMyCategories, {
      ...tokenConfig(getState),
      params: {
        categoryId,
      },
    })
    .then((res) => {
      const { message } = res.data;
      const oldCats = getState().category?.categories;
      let newCats = oldCats.filter((t) => t.id != categoryId);
      toastr.success("Ok", message);
      return dispatch({
        type: LOAD_CATS,
        payload: { result: newCats },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
