import React from "react";
import { connect } from "react-redux";
import NewTaskBar from "./NewTaskBar";
import { getTasks } from "../../store/actions/taskActions";
import { FaTasks } from "react-icons/fa";
import PaginateTasks from "./PaginateTasks";
import { useTranslation } from "react-i18next";
import { MdPlaylistAdd } from "react-icons/md";

import classnames from "classnames";
import { Button, Tooltip } from "antd";
import { TabContent, NavItem, NavLink, Nav, TabPane, Row } from "reactstrap";


import Todo from "../Todo";
import { getTodos } from "../../store/actions/todoActions";
export const Tasks = ({ tasks, getTasks, loading, history, getTodos }) => {
  let { t } = useTranslation(["tasks"]);
  let { t: t2 } = useTranslation(["todo"]);

  const [activeTab, setActiveTab] = React.useState("3");
  const [createOpen, setcreateOpen] = React.useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  React.useEffect(() => {
    getTasks({ type: activeTab === "1" ? "creator" : "assigned", limit: 9 }, 1);
  }, [activeTab]);

  React.useEffect(() => {
    getTodos({limit: 9, done: false},1)
  },[])

  return (
    <div className="container mb-5 main-page"  style={{ minHeight: "100vh" }}>
      <NewTaskBar
        isModalVisible={createOpen}
        handleCancel={() => {
          setcreateOpen(false);
        }}
      />
      <div className="d-flex my-4 justify-content-between container">
        <div>
          <h2 className="head-text mx-auto">
            <FaTasks /> {t("tasks")}
          </h2>
        </div>
        <div>
          <Tooltip title="Create task">
            <Button
              className="text-center main-button"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setcreateOpen(!createOpen);
                console.log(createOpen);
              }}
              id="close"
            >
              <MdPlaylistAdd size="30" color="white" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <Nav tabs>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("3");
            }}
          >
            {t2("todo")}
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("1");
            }}
          >
            {t("filters.created")}
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("2");
            }}
          >
            {t("filters.assigned")}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="container">
            <PaginateTasks tab={1} history={history} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="2">
          <Row className="container">
            <PaginateTasks tab={2} history={history} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="3">
          <Row className="container">
            <Todo />
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tasks: state?.task?.tasks,
  loading: state?.task?.loading,
});

const mapDispatchToProps = { getTasks, getTodos };

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
