import React from "react";
import { connect } from "react-redux";
import { getChatWords, updateChatWords } from "../../store/actions/chatwords";
import TagsInput from "react-tagsinput";
import { Input, Button } from "reactstrap";

export const Chatbot = ({ getChatWords, chatWords, updateChatWords }) => {
  const [words, setWords] = React.useState({
    sign: "#",
    listTasks: [],
    createTask: [],
    listFavs: [],
    addToFav: [],
    hourDate: [],
    tomorrowDate: [],
    weekDate: [],
    monthDate: [],
  });

  React.useEffect(() => {
    getChatWords();
  }, []);

  const handleChangeListTasks = (tags) => {
    setWords({ ...words, listTasks: tags });
  };

  const handleChangeCreateTasks = (tags) => {
    setWords({ ...words, createTask: tags });
  };

  const handleChangeListFavs = (tags) => {
    setWords({ ...words, listFavs: tags });
  };

  const handleChangeAddToFav = (tags) => {
    setWords({ ...words, addToFav: tags });
  };

  const handleChangeHourDate = (tags) => {
    setWords({ ...words, hourDate: tags });
  };

  const handleChangeTomorrowDate = (tags) => {
    setWords({ ...words, tomorrowDate: tags });
  };

  const handleChangeWeekDate = (tags) => {
    setWords({ ...words, weekDate: tags });
  };

  const saveChatWords = () => {
    const {
      hourDate,
      tomorrowDate,
      weekDate,
      listFavs,
      listTasks,
      createTask,
      addToFav,
      sign,
    } = words;
    updateChatWords({
      sign,
      listFavs,
      listTasks,
      createTask,
      addToFav,
      hourDate,
      tomorrowDate,
      weekDate,
    });
  };

  React.useEffect(() => {
    if (chatWords) {
      let listTasks = chatWords?.listTasks?.split(",");
      let createTask = chatWords?.createTask?.split(",");
      let listFavs = chatWords?.listFavs?.split(",");
      let addToFav = chatWords?.addToFav?.split(",");
      let hourDate = chatWords?.hourDate?.split(",");
      let tomorrowDate = chatWords?.tomorrowDate?.split(",");
      let weekDate = chatWords?.weekDate?.split(",");
      let sign = chatWords?.sign;
      if (listTasks) {
        setWords({
          ...words,
          listTasks: listTasks,
          createTask,
          listFavs,
          addToFav,
          sign,
          hourDate,
          tomorrowDate,
          weekDate,
        });
      }
    }
  }, [chatWords]);

  return (
    <div className="container mb-5 main-page">
      {chatWords && (
        <>
          <hr />
          <h4 className="text text-capitalize">Commands</h4>
          <div className="my-4 mx-5">
            <h6>Command Sign</h6>
            <Input
              value={words.sign}
              onChange={(e) => setWords({ ...words, sign: e.target.value })}
            />
          </div>
          <div className="my-4 mx-5">
            <h6>List Tasks</h6>
            <TagsInput
              value={words.listTasks}
              onChange={handleChangeListTasks}
            />
          </div>
          <div className="my-4 mx-5">
            <h6>Create Tasks</h6>
            <TagsInput
              value={words.createTask}
              onChange={handleChangeCreateTasks}
            />{" "}
          </div>

          <div className="my-4 mx-5">
            <h6>List Favourites</h6>
            <TagsInput value={words.listFavs} onChange={handleChangeListFavs} />
          </div>
          <div className="my-4 mx-5">
            <h6>Add to favourites</h6>
            <TagsInput value={words.addToFav} onChange={handleChangeAddToFav} />
          </div>

          <hr />
          <h4 className="text text-capitalize">Task Dates</h4>
          <div className="my-4 mx-5">
            <h6>Next Hour</h6>
            <TagsInput value={words.hourDate} onChange={handleChangeHourDate} />
          </div>
          <div className="my-4 mx-5">
            <h6>Next Day</h6>
            <TagsInput
              value={words.tomorrowDate}
              onChange={handleChangeTomorrowDate}
            />
          </div>
          <div className="my-4 mx-5">
            <h6>Next Week</h6>
            <TagsInput value={words.weekDate} onChange={handleChangeWeekDate} />
          </div>
          <hr />
        </>
      )}
      <div>
        <div className="row">
          <Button className="col-4 mx-5  main-button" onClick={saveChatWords}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  chatWords: state.chatwords.chatwords,
});

const mapDispatchToProps = { getChatWords, updateChatWords };

export default connect(mapStateToProps, mapDispatchToProps)(Chatbot);
