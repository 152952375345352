import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Dropdown, Menu } from "antd";
import { BiDotsVertical } from "react-icons/bi";
import { GrSend } from "react-icons/gr";
import BookAppointment from "../../UserProfile/Tabs/UserAppointments/BookAppointment";
export const SingleSlot = ({ appointment, book, user }) => {
  let { t, i18n } = useTranslation(["appointments"]);
  const [bookModalVisible, setBookModalVisible] = React.useState(false);

  const handleRequest = () => {
    setBookModalVisible(true);
  };

  const MenuOverlay = () => {
    return (
      <Menu>
        <Menu.ItemGroup title="Actions">
          <Menu.Item
            key="3"
            onClick={handleRequest}
            icon={
              <GrSend
                style={{
                  cursor: "pointer",
                  color: "var(--primaryColor)",
                }}
              />
            }
          >
            Request
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  };

  console.log(book);

  return (
    <div className="p-1 col-md-6">
      <BookAppointment
        appointment={appointment}
        userId={user.id}
        visible={bookModalVisible}
        handleCancel={() => setBookModalVisible(false)}
      />
      <div className="task-item">
        <div className="d-flex justify-content-between">
          <div>
            <h2
              style={{
                color: "var(--primaryColor)",
                fontWeight: "bold",
                fontSize: "20px",
                wordBreak: "break-word",
              }}
            >
              {t("free_slot")}
            </h2>
          </div>
          {book && (
            <div>
              <Dropdown overlay={MenuOverlay}>
                <BiDotsVertical cursor="pointer" />
              </Dropdown>
            </div>
          )}
        </div>
        <Row>
          <Col md={6}>
            From:{" "}
            {moment(appointment?.startDate).locale(i18n.language).format("lll")}
          </Col>
          <Col md={6}>
            To:{" "}
            {moment(appointment?.endDate).locale(i18n.language).format("lll")}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.users?.singleUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SingleSlot);
