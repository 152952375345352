import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { Label, Input, Row } from "reactstrap";
import { Button, DatePicker, Input as InputAntd } from "antd";
import { BsSearch } from "react-icons/bs";
import moment from "moment";
import { getCategories } from "../../../store/actions/categoryActions";
import { getTags } from "../../../store/actions/tagsActions";

export const FilterBox = ({
  filters,
  filterStatus,
  clearFilters,
  filterTypes,
  handleChange,
  handleSearch,
  handleDateChange,
  getCategories,
  categories,
  getTags,
  tags,
  t,
}) => {
  const [selectedFilters, setSelectedFilters] = React.useState({
    byStatus: false,
    byText: false,
    byDate: false,
    byStartDate: false,
    byEndDate: false,
    byTags: false,
    byCategories: false,
  });

  let {
    byStatus,
    byText,
    byDate,
    byStartDate,
    byEndDate,
    byTags,
    byCategories,
  } = selectedFilters;

  React.useEffect(() => {
    getCategories({ limit: 30 }, 1);
    getTags({ limit: 30 }, 1);
  }, [byTags, byCategories]);

  return (
    <div className="mx-5 task_form">
      <div className="row">
        {filters.type === "assigned" && byStatus && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("task_status")}
            </Label>
            <Input
              className="task-input"
              onChange={handleChange}
              type="select"
              name="status"
              value={filters.status}
            >
              {filterStatus &&
                filterStatus.map((type, i) => {
                  return (
                    <option key={i} value={type.value.toLowerCase()}>
                      {type.status}
                    </option>
                  );
                })}
            </Input>
          </div>
        )}
        {byText && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("task_name_search")}
            </Label>
            <InputAntd
              addonAfter={
                <BsSearch
                  onClick={() => handleSearch(filters.text)}
                  cursor="pointer"
                />
              }
              onChange={handleChange}
              name="text"
              value={filters.text}
              className="task-input"
              placeholder="Task Name"
            ></InputAntd>
          </div>
        )}
        {byDate && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("task_date")}
            </Label>
            <DatePicker
              allowClear={false}
              className="task-input col-md-12"
              name="date"
              onChange={(e) =>
                handleDateChange("date", e.locale("en-us").format("l"))
              }
              defaultValue={moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
            />
          </div>
        )}
        {byStartDate && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("task_startDate")}
            </Label>
            <DatePicker
              allowClear={false}
              className="task-input col-md-12"
              name="startDate"
              onChange={(e) =>
                handleDateChange("startDate", e.locale("en-us").format("l"))
              }
              defaultValue={moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
            />
          </div>
        )}
        {byEndDate && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("task_endDate")}
            </Label>
            <DatePicker
              allowClear={false}
              className="task-input col-md-12"
              name="endDate"
              onChange={(e) =>
                handleDateChange("endDate", e.locale("en-us").format("l"))
              }
              defaultValue={moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")}
            />
          </div>
        )}
        {byCategories && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("task_category")}
            </Label>
            <Input
              className="task-input"
              onChange={handleChange}
              type="select"
              name="categoryId"
              value={filters.status}
            >
              {categories &&
                categories.map((category, i) => {
                  return (
                    <option key={i} value={category.id}>
                      {category.title}
                    </option>
                  );
                })}
            </Input>
          </div>
        )}
        {byTags && (
          <div className="col">
            <Label className="my-2" style={{ color: "var(--primaryColor)" }}>
              {t("task_tags")}
            </Label>
            <Input
              className="task-input"
              onChange={handleChange}
              type="select"
              name="tagId"
              value={filters.status}
            >
              {tags &&
                tags.map((tag, i) => {
                  return (
                    <option key={i} value={tag.id}>
                      {tag.tag}
                    </option>
                  );
                })}
            </Input>
          </div>
        )}
        <div className="d-flex flex-wrap justify-content-center mt-2">
          {filters.type === "assigned" && (
            <Button
              className={`main-button${+byStatus ? "-selected" : ""}`}
              onClick={() =>
                setSelectedFilters({ ...selectedFilters, byStatus: !byStatus })
              }
            >
              Filter by status
            </Button>
          )}
          <Button
            className={`main-button${+byText ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({ ...selectedFilters, byText: !byText })
            }
          >
            Filter by text
          </Button>
          <Button
            className={`main-button${+byDate ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({ ...selectedFilters, byDate: !byDate })
            }
          >
            Filter by date
          </Button>
          <Button
            className={`main-button${+byStartDate ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({
                ...selectedFilters,
                byStartDate: !byStartDate,
              })
            }
          >
            Filter by start date
          </Button>
          <Button
            className={`main-button${+byEndDate ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({ ...selectedFilters, byEndDate: !byEndDate })
            }
          >
            Filter by end date
          </Button>
          <Button
            className={`main-button${+byTags ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({ ...selectedFilters, byTags: !byTags })
            }
          >
            Filter by tags
          </Button>
          <Button
            className={`main-button${+byCategories ? "-selected" : ""}`}
            onClick={() =>
              setSelectedFilters({
                ...selectedFilters,
                byCategories: !byCategories,
              })
            }
          >
            Filter by categories
          </Button>
          <Button className="main-button-cancel" onClick={clearFilters}>
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state?.category?.categories,
  tags: state?.tags?.tags,
});

const mapDispatchToProps = { getCategories, getTags };

export default connect(mapStateToProps, mapDispatchToProps)(FilterBox);
