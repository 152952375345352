import React from "react";
import { connect } from "react-redux";
import { RiLockPasswordFill } from "react-icons/ri";
import { Form, FormGroup, Button } from "reactstrap";
import { Input } from "antd";
import { changePassword } from "../../../../store/actions/authAcrions";

export const ChangePassword = ({ changePassword, isAuthenticated }) => {
  const [passData, setPassData] = React.useState({
    oldPassword: "",
    newPassword: "",
    newPassword1: "",
  });
  const { oldPassword, newPassword, newPassword1 } = passData;
  const onSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    console.log(oldPassword);
    console.log(passData);
    oldPassword && formData.append("oldPassword", oldPassword);
    newPassword && formData.append("newPassword", newPassword);

    changePassword(formData);
  };

  return (
    <div>
      <div className="row justify-content-center">
        <Form className="col-md-6 p-3" onSubmit={onSubmit}>
          <h1
            style={{
              color: "var(--primaryColor)",
              fontFamily: "cairo",
              padding: 6,
            }}
          >
            Change Password <RiLockPasswordFill />
          </h1>
          <FormGroup>
            <Input
              className="mt-2 task-input"
              placeholder="Old Password"
              value={passData.oldPassword}
              onChange={({ target }) => {
                setPassData({ ...passData, oldPassword: target.value });
              }}
              required={true}
            />
          </FormGroup>
          <FormGroup>
            <Input.Password
              className="mt-2 task-input"
              placeholder="New Password"
              invalid={newPassword && newPassword.length < 6}
              value={newPassword}
              onChange={({ target }) => {
                setPassData({ ...passData, newPassword: target.value });
              }}
              required={true}
            />
            {newPassword && newPassword.length < 6 && (
              <p className="invalid-entry-message">
                Length must be atleast 6 characters
              </p>
            )}
          </FormGroup>
          <FormGroup>
            <Input.Password
              className="mt-2 task-input"
              placeholder="Confirm New Password"
              invalid={
                newPassword1 &&
                (newPassword1.length < 6 || newPassword1 !== newPassword)
              }
              value={newPassword1}
              onChange={({ target }) => {
                setPassData({ ...passData, newPassword1: target.value });
              }}
              required={true}
            />
            {newPassword1 && newPassword1.length < 6 && (
              <p className="invalid-entry-message">
                Length must be atleast 6 characters
              </p>
            )}
            {newPassword1 && newPassword1 !== newPassword && (
              <p className="invalid-entry-message">Passwords do not match</p>
            )}
          </FormGroup>
          <FormGroup>
            <div className="row">
              <Button
                className="main-button mx-5 my-5 col "
                type="submit"
                disabled={
                  newPassword1 !== newPassword ||
                  newPassword1.length < 6 ||
                  newPassword.length < 6
                }
              >
                Confirm Change
              </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.token,
});

const mapDispatchToProps = { changePassword };

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
