import React from "react";
import { connect } from "react-redux";
import { Modal, Menu, Dropdown, Checkbox } from "antd";
import { Col, Row } from "reactstrap";
import {
  FaCalendarCheck,
  FaCalendarTimes,
  GiPartyPopper,
  BsHeartFill,
  BiDotsVertical,
  GiRoundTable,
} from "react-icons/all";
import {
  AiOutlineExclamationCircle,
  AiOutlineCheckCircle,
  AiFillMail,
  AiFillDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/ar";
import { FileMedia } from "../../../components/FileMedia/FileMedia";
import eventicon from "../../../Assets/Images/calendar.png";
import {
  deleteEvent,
  getEvents,
  updateEventStatus,
} from "../../../store/actions/eventActions";
import EditEvent from "../../EventPage/EditEvent";
export const SingleEvent = ({
  event,
  updateEventStatus,
  getEvents,
  deleteEvent,
  filters,
  thisPage,
  history,
  user,
  onSelect,
  onActSelect,
  selectedEvents,
  selectedActEvents,
}) => {
  let { t, i18n } = useTranslation(["events"]);
  let { t: t2 } = useTranslation(["delete"]);

  const media = event.medias && event.medias.length !== 0 && event.medias[0];
  const [status, setStatus] = React.useState(
    event.invited && event.invited.status
  );
  const handleResponse = (responseId) => {
    updateEventStatus(event.id, responseId);
    getEvents({ ...filters, limit: 6 }, thisPage);
  };

  const eventIcon = () => {
    switch (event?.typeId) {
      case 1:
        return <BsHeartFill />;
      case 2:
        return <AiFillMail />;
      case 3:
        return <GiRoundTable />;
      case 4:
        return <GiPartyPopper />;
      default:
        return <></>;
    }
  };

  const getEventType = () => {
    switch (event?.typeId) {
      case 1:
        return "date";
      case 2:
        return "invitation";
      case 3:
        return "meeting";
      case 4:
        return "party";
      default:
        return;
    }
  };

  const statusIcon = () => {
    switch (status) {
      case "pending":
        return <AiOutlineExclamationCircle color="orange" />;
      case "accepted":
        return <AiOutlineCheckCircle color="green" />;
      default:
        return <></>;
    }
  };

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    deleteEvent([event?.id]);
    handleCancel();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const handleOpenEdit = () => {
    setIsEditVisible(true);
  };
  const handleCloseEdit = () => {
    setIsEditVisible(false);
  };

  const MenuOverlay = () => {
    return (
      <Menu>
        {/* <Menu.ItemGroup title="Details">
          <Menu.Item icon={statusIcon()}>{t("filter." + status)}</Menu.Item>
          <Menu.Item icon={eventIcon()}>
            {t("form." + getEventType())}
          </Menu.Item>
        </Menu.ItemGroup> */}
        {event?.userId === user?.id && (
          <Menu.ItemGroup title="Creator Actions">
            <Menu.Item
              onClick={showModal}
              key="1"
              icon={
                <AiFillDelete
                  style={{
                    color: "red",
                  }}
                />
              }
            >
              Delete
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={handleOpenEdit}
              icon={
                <AiOutlineEdit
                  style={{
                    color: "var(--primaryColor)",
                  }}
                />
              }
            >
              Edit
            </Menu.Item>
          </Menu.ItemGroup>
        )}
        {status === "pending" && (
          <Menu.ItemGroup title={t("respond")}>
            <Menu.Item
              key="3"
              onClick={() => handleResponse("1")}
              icon={<FaCalendarCheck color="green" />}
            >
              Accept
            </Menu.Item>
            <Menu.Item
              key="4"
              onClick={() => handleResponse("2")}
              icon={<FaCalendarTimes color="red" />}
            >
              Reject
            </Menu.Item>
          </Menu.ItemGroup>
        )}
      </Menu>
    );
  };

  const value = () => {
    console.log(
      selectedEvents.includes(event?.id) ||
        selectedActEvents.includes(event?.id)
    );

    return (
      selectedEvents.includes(event?.id) ||
      selectedActEvents.includes(event?.id)
    );
  };

  return (
    <div className="col-md-6 p-2">
      <Modal
        title={t2("confirm")}
        visible={isModalVisible}
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ className: "main-button" }}
        okText={t2("ok")}
        cancelText={t2("cancel")}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <p>
          {t2("sure")} "{event?.title}"
        </p>
      </Modal>
      <EditEvent
        isModalVisible={isEditVisible}
        handleCancel={handleCloseEdit}
        event={event}
      />
      {/* <Col className="task-item m-2" style={{ overflowX: "hidden" }}>
        <Row>
          <Col className="p-1 d-flex flex-wrap justify-content-center" md={3}>
            <Row>
              <div>
                <FileMedia
                  style={{ borderRadius: 15 }}
                  file={media || eventicon}
                />
              </div>
            </Row>
            <Row>
              <p className="m-1 p-1 text-center">
                {statusIcon()} {t("filter." + status)}
              </p>
              <p className="m-1 p-1 text-center">
                {eventIcon()} {t("form." + getEventType())}
              </p>
            </Row>
          </Col>
          <Col className="m-2" md={8}>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap">
                {event?.userId === user?.id && (
                  <Checkbox
                    checked={value()}
                    className="mx-1 my-auto"
                    onClick={() => {
                      onSelect(event.id);
                      status === "pending" && onActSelect(event.id);
                    }}
                  />
                )}
                {event?.userId !== user?.id && status === "pending" && (
                  <Checkbox
                    checked={value()}
                    className="mx-1 my-auto"
                    onClick={() => onActSelect(event.id)}
                  />
                )}
                <h2
                  style={{ cursor: "pointer", margin: 0 }}
                  onClick={() => history.push("/events/" + event.id)}
                >
                  {event.title}
                </h2>
              </div>
              <div>
                <Dropdown overlay={MenuOverlay}>
                  <BiDotsVertical cursor="pointer" size="20" />
                </Dropdown>
              </div>
            </div>
            {event.description && (
              <>
                <Row>
                  <p style={{ color: "gray", margin: 0 }}>
                    {t("description")}:{" "}
                  </p>
                </Row>
                <Row>
                  <p>{event.description}</p>
                </Row>
              </>
            )}
            {event.startDate && (
              <Row>
                <Col className="col-3">
                  <p>{t("start")}: </p>
                </Col>
                <Col className="col">
                  <p>
                    {moment(event.startDate)
                      .locale(i18n.language)
                      .format("lll")}
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="col-3">
                <p>{t("end")}: </p>
              </Col>
              <Col className="col">
                <p>
                  {moment(event.endDate).locale(i18n.language).format("lll")}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col> */}
      <Col className="task-item">
        <Row>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-wrap">
              {event?.userId === user?.id && (
                <Checkbox
                  checked={value()}
                  className="mx-1 my-auto"
                  onClick={() => {
                    onSelect(event.id);
                    status === "pending" && onActSelect(event.id);
                  }}
                />
              )}
              {event?.userId !== user?.id && status === "pending" && (
                <Checkbox
                  checked={value()}
                  className="mx-1 my-auto"
                  onClick={() => onActSelect(event.id)}
                />
              )}
              <h2
                style={{ cursor: "pointer", margin: 0 }}
                onClick={() => history.push("/events/" + event.id)}
              >
                {event.title}
              </h2>
            </div>
            <div>
              <Dropdown overlay={MenuOverlay}>
                <BiDotsVertical cursor="pointer" size="20" />
              </Dropdown>
            </div>
          </div>
        </Row>
        <Row md={12} className="position-relative">
          <Col className="m-2" md={8}>
            {event.description && (
              <>
                <Row>
                  <p style={{ color: "gray", margin: 0 }}>
                    {t("description")}:{" "}
                  </p>
                </Row>
                <Row>
                  <p>{event.description}</p>
                </Row>
              </>
            )}
            {event.startDate && (
              <Row>
                <Col className="col-3">
                  <p>{t("start")}: </p>
                </Col>
                <Col className="col">
                  <p>
                    {moment(event.startDate)
                      .locale(i18n.language)
                      .format("lll")}
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="col-3">
                <p>{t("end")}: </p>
              </Col>
              <Col className="col">
                <p>
                  {moment(event.endDate).locale(i18n.language).format("lll")}
                </p>
              </Col>
            </Row>
          </Col>
          <div className="position-absolute top-50 end-0 translate-middle-y text-end">
            <FileMedia style={{ borderRadius: 15 }} file={media || eventicon} />
          </div>
        </Row>
        <Row>
          <Col>
            <p className="m-1 p-1 text-center">
              {statusIcon()} {t("filter." + status)}
            </p>
          </Col>
          <Col>
            <p className="m-1 p-1 text-center">
              {eventIcon()} {t("form." + getEventType())}
            </p>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

const mapStateToProps = (state) => ({
  thisPage: state?.events?.thisPage,
  user: state?.auth?.user,
});

const mapDispatchToProps = { updateEventStatus, getEvents, deleteEvent };

export default connect(mapStateToProps, mapDispatchToProps)(SingleEvent);
