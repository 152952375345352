import {
  GET_NOTIFICATION_CONFIG,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_LOADING,
  GET_TOP_NOTIFICATIONS,
  GET_TOP_NOTIFICATIONS_LOADING,
  NOTIFICATION_ACT,
  CHANGE_NOTIFICATION_COUNT,
} from "../actions/types";

const initialState = {
  notifications: [],
  notificationConfig: {},
  topNotifications: [],
  unSeenCount: 5,
  error: false,
  loading: false,
  thisPage: 1,
  allPages: 1,
  count: 0,
  topLoading: false,
};

export default function notificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_NOTIFICATION_COUNT:
      return { ...state, unSeenCount: payload };
    case NOTIFICATION_ACT:
      let currentNotifications = state.notifications || [];
      let topNotifications = state.topNotifications || [];
      currentNotifications = currentNotifications.filter(
        (n) => n.id !== payload?.notification?.id
      );
      topNotifications = topNotifications.filter(
        (n) => n.id !== payload?.notification?.id
      );

      console.log("Got to reducer", currentNotifications);
      return {
        ...state,
        notifications: currentNotifications,
        topNotifications,
      };
    case GET_TOP_NOTIFICATIONS_LOADING:
      return {
        ...state,
        topLoading: true,
      };
    case GET_TOP_NOTIFICATIONS:
      return {
        ...state,
        topNotifications: action.payload.result,
        unSeenCount: action.payload.unSeenCount,
        topLoading: false,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload.result,
        thisPage: payload.thisPage,
        allPages: payload.allPages,
        count: payload.count,
        error: false,
        loading: false,
      };
    case GET_NOTIFICATION_CONFIG:
      return {
        ...state,
        notificationConfig: payload,
        error: false,
        loading: false,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };

    case GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };

    default:
      return state;
  }
}
