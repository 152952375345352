import React from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { VscFolderActive } from "react-icons/vsc";
import { addNewCategory } from "../../../store/actions/categoryActions";
export const CreateNewCategory = ({ addNewCategory }) => {
  

  const [values, setValues] = React.useState({
    title: "",
  });

  const handleAddNewCat = () => {
    addNewCategory({ title: values.title });
  };

  return (
    <div className="row">

      <div className="col task_form py-3 mt-4 my-5">
        <div className="row px-3">
          <Input
            value={values.title}
            onChange={({ target }) => setValues({ title: target.value })}
            className="my-3 task-input col"
            placeholder="Category Name"
          />
          <div className="col mt-3">
            <VscFolderActive
              onClick={handleAddNewCat}
              size="40"
              style={{ color: "var(--primaryColor)", cursor: "pointer" }}
            />
          </div>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addNewCategory };

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCategory);
