import { Tag } from "antd";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { Row } from "reactstrap";
import { getTags } from "../../../store/actions/tagsActions";

export const SearchTags = ({ tags, getTags, thisPage, allPages }) => {
  const fetchData = () => {
    getTags({ limit: 49 }, thisPage + 1);
  };

  React.useEffect(() => {
    getTags({ limit: 49 }, 1);
  }, []);

  const [selectedTags, setSelectedTags] = React.useState([]);

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };

  return (
    <div className="task-item mx-5">
      <Row>
        <h2 className="head-text text-center">Search using tags</h2>
      </Row>
      <Row>
        <InfiniteScroll
          height={100}
          className="d-flex flex-wrap"
          dataLength={tags.length}
          next={fetchData}
          hasMore={allPages > thisPage}
          loader={<p>Loading...</p>}
        >
        <div className="d-flex flex-wrap">
          {tags &&
            tags?.map((tag, idx) => {
              return (
                <Tag.CheckableTag
                  key={idx}
                  className="m-1"
                  checked={selectedTags.indexOf(tag.id) > -1}
                  onChange={(checked) => handleChange(tag.id, checked)}
                >
                  <p style={{margin: 0, fontSize:15}}>{tag.tag}</p>
                </Tag.CheckableTag>
              );
            })}
            </div>
        </InfiniteScroll>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tags: state?.tags?.tags,
  thisPage: state?.tags?.thisPage,
  allPages: state?.tags?.allPages,
});

const mapDispatchToProps = { getTags };

export default connect(mapStateToProps, mapDispatchToProps)(SearchTags);
