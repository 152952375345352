import React from "react";
import { connect } from "react-redux";
import { AiFillStar } from "react-icons/ai";
import { RiHeartAddLine } from "react-icons/ri";
import PaginateFavourites from "./PaginateFavourites";
import Loader from "../../components/Loader";
import { getFavourites } from "../../store/actions/favouritesActions";
import AddFavourite from "./AddFavourite";
import { Button } from "antd";
import { Row, Col } from "reactstrap";
import Tags from "./Tags";

export const Favourites = ({ getFavourites, loading, thisPage }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [tag, setTag] = React.useState();

  const showModal = () => {
    setIsModalVisible(true);
  };

  React.useEffect(() => {
    getFavourites({ tagId: tag, limit: 6 }, 1);
  }, []);

  React.useEffect(() => {
    getFavourites({ tagId: tag, limit: 6 }, 1);
  },[tag])

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="container mb-5 main-page">
      <AddFavourite
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      />
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            <AiFillStar /> Favourites
          </h2>
        </div>
        <div>
          <Button
            className="text-center main-button"
            style={{ cursor: "pointer" }}
            onClick={showModal}
            id="close"
          >
            <RiHeartAddLine size="25" color="white" />
          </Button>
        </div>
      </div>

      <Row>
        <Col md={2}>
          <Tags filter={(tags) => {setTag(tags[0])}}/>
        </Col>
        <Col md={10}>
          <PaginateFavourites tags={tag}/>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.favourites?.loading, 
  thisPage: state?.favourites?.thisPage
});

const mapDispatchToProps = { getFavourites };

export default connect(mapStateToProps, mapDispatchToProps)(Favourites);
