import React from "react";
import { connect } from "react-redux";
import { BsCircle, BsCircleFill } from "react-icons/bs";
import {
  editTodo,
  updateTodoStatus,
  deleteTodo,
} from "../../../store/actions/todoActions";
import { Row, Col } from "reactstrap";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { BiDotsVertical } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { Modal, Menu, Dropdown } from "antd";
import moment from "moment";
import EditTodo from "../EditTodo";

export const SingleTodo = ({ todo, updateTodoStatus, deleteTodo }) => {
  const [status, setStatus] = React.useState(todo?.done);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  let { t, i18n } = useTranslation(["delete"]);
  let { t: t2 } = useTranslation(["todo"]);

  const toggle = () => {
    updateTodoStatus(todo?.id);
    setStatus(!status);
  };

  React.useEffect(() => {
    setStatus(todo?.done);
  }, [todo]);

  const handleDelete = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteTodo(todo?.id);
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const handleEdit = () => {
    setIsEditVisible(true);
  };
  const handleEditCancel = () => {
    setIsEditVisible(false);
  };

  const MenuOverlay = () => {
    return (
      <Menu>
        <Menu.ItemGroup title="Actions">
          <Menu.Item
            onClick={handleDelete}
            key="1"
            icon={
              <AiFillDelete
                style={{
                  color: "red",
                }}
              />
            }
          >
            Delete
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={handleEdit}
            icon={
              <AiOutlineEdit
                style={{
                  color: "var(--primaryColor)",
                }}
              />
            }
          >
            Edit
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  };

  return (
    <div className="col-sm p-2">
      <div className="task-item">
        <Modal
          title={t("confirm")}
          visible={isModalVisible}
          closable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ className: "main-button" }}
          okText={t("ok")}
          cancelText={t("cancel")}
          cancelButtonProps={{ className: "main-button-cancel" }}
        >
          <p>
            {t("sure")} "{todo?.title}"
          </p>
        </Modal>
        <EditTodo
          isModalVisible={isEditVisible}
          handleCancel={handleEditCancel}
          todo={todo}
        />

        <div className="row">
          <div className="col-1 mx-2">
            {status ? (
              <BsCircleFill
                onClick={toggle}
                style={{ cursor: "pointer", color: "green" }}
              />
            ) : (
              <BsCircle onClick={toggle} style={{ cursor: "pointer" }} />
            )}
          </div>
          <div
            className=" col-7 mx-2"
            style={{
              textDecoration: status ? "line-through" : "none",
              fontFamily: "cairo",
              fontSize: "large",
              wordBreak: "break-word",
            }}
          >
            {todo?.title}
          </div>
          <div className="col-1">
            <Dropdown overlay={MenuOverlay}>
              <BiDotsVertical />
            </Dropdown>
          </div>
        </div>

        {/*         <div className="row">
          <div className="col">
            {t2("form.todo_description")}
            {": "} {todo?.description}
          </div>
        </div>
        <div className="mt-2">
          <div>
            {t2("form.startDate")}
            {": "}{" "}
            {moment(todo?.startDate).locale(i18n?.language).format("lll")}
          </div>
          <div>
            {t2("form.endDate")}
            {moment(todo?.endDate).locale(i18n?.language).format("lll")}
          </div>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  updateTodoStatus,
  deleteTodo,
  editTodo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleTodo);
