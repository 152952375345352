import { toastr } from "react-redux-toastr";
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_LOADED,
  USER_LOADING,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  NEEDS_VERFICATION_ERROR,
  REGISTER_SUCCESS,
} from "./types";
import axios from "axios";
import { apis } from "../../constants/API";

export const userLodaing = () => ({
  type: USER_LOADING,
});

export const tokenConfig = (getState) => {
  //Get token from Local Storage
  let token = getState().auth.token;
  //headers
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  if (token) {
    config.headers["Authorization"] = token;
    config.headers["accept-language"] = localStorage.getItem("lng") || "en";
  }
  return config;
};

export const loadUser = () => async (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
  });
  let token = localStorage.getItem("token");
  let user = localStorage.getItem("user");
  let fbToken = localStorage.getItem("fbToken");

  if (token) {
    let deviceToken = localStorage.getItem("deviceToken");

    return axios
      .get(apis.profile, { ...tokenConfig(getState), params: { deviceToken } })
      .then((res) => {
        if (typeof user === "string") {
          user = JSON.parse(user);
        }
        const { data } = res.data;
        user = { ...user, ...data };
        if (user.fbToken) {
          localStorage.setItem("fbToken", user.fbToken);
        }

        localStorage.setItem("user", JSON.stringify(user));
        return dispatch({
          type: USER_LOADED,
          payload: { token: token, user, fbToken },
        });
      })
      .catch((e) => {
        console.log(e);
        logout()(dispatch, getState);
      });
  } else {
    return dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (dataForLogin) => (dispatch) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  dispatch({ type: USER_LOADING });

  if (dataForLogin.deviceToken) {
    if (dataForLogin.deviceToken.length === 0) {
      delete dataForLogin.deviceToken;
    }
  } else {
    delete dataForLogin.deviceToken;
  }

  axios
    .post(apis.login, dataForLogin, config)
    .then((res) => {
      const user = res.data.data.user;
      const token = res.data.data.token;
      return dispatch({
        type: LOGIN_SUCCESS,
        payload: { user, token },
      });

      /*       window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function () {},
          "expired-callback": function (err) {
            console.error(err);
          },
        }
      );
      firebase
        .auth()
        .signInWithPhoneNumber(userPhoneNumber, window.recaptchaVerifier)
        .then(function (confirmationResult) {
          dispatch({
            type: NEEDS_VERFICATION,
            payload: { user, token, fbToken },
          });
          window.confirmationResult = confirmationResult;
        })
        .catch(function (error) {
          console.log("Error while sending: " + error);
          dispatch({
            type: LOGIN_FAIL,
            payload: {
              customError: error?.message
                ? error.message
                : JSON.stringify(error),
            },
          });
          console.log("Error while sending: " + error);
          console.log("Error while sending: " + error.code);
        }); */
    })
    .catch((error) => {
      console.log(error?.response?.data.error);
      let customError;
      if (
        error.response?.data &&
        typeof error.response?.data.error == "string"
      ) {
        customError = error.response.data.error;
      }

      dispatch({
        type: LOGIN_FAIL,
        payload: { customError },
      });
    });
};

export const verifyPhoneNumber = (data) => (dispatch, getState) => {
  if (!data.code) return;
  let user = getState().auth?.user;
  let token = getState().auth?.token;
  if (!user) return console.error("No User");
  if (!window.confirmationResult)
    return dispatch({
      type: NEEDS_VERFICATION_ERROR,
      payload: "Please refresh the page and try again",
    });
  window.confirmationResult
    .confirm(data.code)
    .then(function (result) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user, token },
      });
    })
    .catch(function (error) {
      // User couldn't sign in (bad verification code?)
      console.error("Error while checking the verification code", error);
      if (error.code === "auth/invalid-verification-code") {
        dispatch({
          type: NEEDS_VERFICATION_ERROR,
          payload: "Invalid code please try again",
        });
        return toastr.error(error.message);
      }
      dispatch({
        type: NEEDS_VERFICATION_ERROR,
        payload: JSON.stringify(error),
      });
    });
};

export const logout = (message) => (dispatch, getState) => {
  let deviceToken = localStorage.getItem("deviceToken");
  if (deviceToken) {
    axios
      .get(apis.logout, {
        ...tokenConfig(getState),
        params: { deviceToken: localStorage.getItem("deviceToken") },
      })
      .catch((e) => console.log(e));
  }
  if (!message) {
    toastr.success("See you later", "You have successfully logged out");
  }

  return dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const updateProfile = (data) => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
  });
  const url = apis.profile;
  axios
    .put(url, data, tokenConfig(getState))
    .then((res) => {
      const {
        data: { data, message },
      } = res;
      toastr.success(message);
      let user = getState().auth.user;
      user = { ...user, ...data };
      let token = localStorage.getItem("token");
      return dispatch({
        type: USER_LOADED,
        payload: { token: token, user },
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const sendPasswordLink = (email, setSubmitted) => (dispatch) => {
  axios
    .post(apis.forgotPassword, email)
    .then((res) => {
      toastr.success(
        "Password reset requested!",
        "Plese check the email you provided"
      );
      setSubmitted(true);
      dispatch({
        payload: { email },
      });
    })
    .catch((error) => {
      console.log(error?.response?.data.error);
      console.log(error);
      if (
        error.response?.data &&
        typeof error.response?.data.error == "string"
      ) {
        toastr.error("Pasword Reset Error", error.response.data.error);
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    });
};

export const changePassword = (data) => (dispatch, getState) => {
  axios
    .put(apis.changePassword, data, { ...tokenConfig(getState) })
    .then((res) => {
      const {
        data: { data, message },
      } = res;
      toastr.success(message);
    })
    .catch((error) => {
      console.log(error?.response?.data.error);
      console.log(error);
      if (
        error.response?.data &&
        typeof error.response?.data.error == "string"
      ) {
        toastr.error("Pasword Change Error", error.response.data.error);
      }
    });
};

export const resetPassword = (data, history) => (dispatch, getState) => {
  axios
    .put(apis.forgotPassword, data, { ...tokenConfig(getState) })
    .then((res) => {
      const {
        data: { data, message },
      } = res;
      toastr.success(message);
      history.push("/login");
    })
    .catch((error) => {
      console.log(error?.response?.data.error);
      console.log(error);
      if (
        error.response?.data &&
        typeof error.response?.data.error == "string"
      ) {
        toastr.error("Pasword Reset Error", error.response.data.error);
      }
    });
};

export const verifyEmail = (data, logout) => (dispatch, getState) => {
  axios
    .put(apis.verifyEmail, data)
    .then((res) => {
      const {
        data: { data, message },
      } = res;
      toastr.success("Email Verified");
      let user = getState().auth.user;
      user = { ...user, ...data };
      let token = localStorage.getItem("token");
      logout && logout();
      return dispatch({
        type: USER_LOADED,
        payload: { token: token, user },
      });
    })
    .catch((error) => {
      console.log(error?.response?.data.error);
      console.log(error);
      if (
        error.response?.data &&
        typeof error.response?.data.error == "string"
      ) {
        toastr.error("Email Verification Error", error.response.data.error);
      }
    });
};

export const googleAuth = (data) => (dispatch, getState) => {
  axios
    .get(apis.google, { ...tokenConfig(getState), params: { ...data } })
    .then((res) => {
      const {
        data: { data },
      } = res;
      console.log(data);
    })
    .catch((error) => {
      console.log(error?.response?.data.error);
      console.log(error);
      if (
        error.response?.data &&
        typeof error.response?.data.error == "string"
      ) {
        toastr.error("Email Verification Error", error.response.data.error);
      }
    });
};

export const register = (data) => (dispatch, getState) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  dispatch({ type: "REGISTER_LODING" });

  axios
    .post(apis.register, data, config)
    .then((res) => {
      dispatch({ type: REGISTER_SUCCESS });
    })
    .catch((error) => {
      let customError = error?.response?.data.error;
      console.log(customError);
      return dispatch({ type: AUTH_ERROR, payload: { customError } });
    });
};
