import React from "react";
import { connect } from "react-redux";
import { getUsers } from "../../store/actions/usersActions";
import { Skeleton, Card, Avatar } from "antd";
import CreateUser from "./CreateUser";
import PaginateUsers from "./PaginateUsers";
import { FiUsers } from "react-icons/fi";
import { useTranslation } from "react-i18next";
export const UsersPage = ({ getUsers, users, loading }) => {
  const { Meta } = Card;
  let { t } = useTranslation(["users"]);
  
  React.useEffect(() => {
    getUsers({ limit: 5 }, 1);
  }, []);

  return (
    <div className="container mb-5 main-page">
      <div className="my-4">
        <h2 className="head-text mx-auto">
          <FiUsers />  {t("users")} 
        </h2>
      </div>
      <CreateUser t={t} />
      <div className="row justify-content-center mx-2">
        {loading && (
          <>
            <Card style={{ width: 300, marginTop: 16 }} className="col mx-2">
              <Skeleton loading={loading} avatar active>
                <Meta
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                />
              </Skeleton>
            </Card>
            <Card style={{ width: 300, marginTop: 16 }} className="col mx-2">
              <Skeleton loading={loading} avatar active>
                <Meta
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                />
              </Skeleton>
            </Card>
          </>
        )}
        <PaginateUsers dataLimit={5} t={t} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state?.users?.users,
  loading: state?.users?.loading,
});

const mapDispatchToProps = { getUsers };

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
