import React from "react";
import { connect } from "react-redux";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { getNotifications } from "../../../store/actions/notificationActions";
import SingleNotification from "../SingleNotification";
import { VscLoading } from "react-icons/vsc";
import { Switch } from "antd";
import emptyicon from "../../../Assets/Icons/empty-white-box.png";

export const PaginateNotifications = ({
  notifications,
  thisPage,
  allPages,
  getNotifications,
  tab,
  loading,
}) => {
  const [switchVal, setSwitchVal] = React.useState(false);
  const [acted, setActed] = React.useState(false);

  const onswitched = () => {
    setSwitchVal(!switchVal);
  };

  const onClick = (event) => {
    switch (event.target.textContent) {
      case "»":
      case "»Last":
      case "Last":
        getNotifications(
          { limit: 8, moduleId: tab, action: switchVal },
          allPages
        );
        break;
      case "«":
      case "«First":
      case "First":
        getNotifications({ limit: 8, moduleId: tab, action: switchVal }, 1);
        break;
      case "›":
      case "›Next":
      case "Next":
        getNotifications(
          { limit: 8, moduleId: tab, action: switchVal },
          thisPage + 1
        );
        break;
      case "‹":
      case "‹Previous":
      case "Previous":
        getNotifications(
          { limit: 8, moduleId: tab, action: switchVal },
          thisPage - 1
        );
        break;
      default:
        getNotifications(
          { limit: 8, moduleId: tab, action: switchVal },
          Number(event.target.textContent)
        );
        break;
    }
  };

  React.useEffect(() => {
    getNotifications({ limit: 8, moduleId: tab, action: switchVal }, 1);
  }, [switchVal]);

  React.useEffect(() => {
    getNotifications({ limit: 8, moduleId: tab, action: switchVal }, thisPage);
  }, [acted]);


  return (
    <div>
      <div className="mt-4">
        <h4 style={{ color: "var(--primaryColor)" }}>
          Action needed? <Switch checked={switchVal} onChange={onswitched} />
        </h4>
      </div>
      {loading && (
        <div
          className="col text-center rotator"
          style={{
            fontSize: "40px",
            color: "var(--primaryColor)",
          }}
        >
          <VscLoading />
        </div>
      )}
      {!loading && notifications.length === 0 && (
        <div>
          <div className="col text-center">
            <img
              src={emptyicon}
              alt="Empty"
              style={{ width: 100, height: 100 }}
            />
          </div>
          <div
            className="col text-center"
            style={{
              fontSize: "40px",
              color: "var(--primaryColor)",
            }}
          >
            No Notifications
          </div>
        </div>
      )}
      <div className="d-flex flex-wrap">
        {!loading &&
          notifications &&
          notifications.map((notification, index) => {
            return (
              <SingleNotification onAction={() => setActed(!acted)} notification={notification} key={index}  actionNeeded={switchVal}/>
            );
          })}
      </div>
      {allPages > 1 && (
        <div className="col d-flex justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className="pag-link"
                >
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index}
                      className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: state?.notifications?.notifications,
  thisPage: state?.notifications?.thisPage,
  allPages: state?.notifications?.allPages,
  loading: state?.notifications?.loading,
});

const mapDispatchToProps = { getNotifications };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginateNotifications);
