import { BASE_URL } from "../../connection/baseURL";

export const apis = {
  //Users
  user: {
    manage: BASE_URL + "/user/manage",
    userProfile: BASE_URL + "/user/profile/user",
    updateChatSettings: BASE_URL + "/user/chat/settings",
  },
  forgotPassword: BASE_URL + "/user/password-reset",
  changePassword: BASE_URL + "/user/password-change",
  verifyEmail: BASE_URL + "/user/verify-email",
  google: BASE_URL + "/user/google",
  logout: BASE_URL + "/user/logout",
  resetPassBySMS: BASE_URL + "/user/password-reset/mobile",
  //Tasks
  login: BASE_URL + "/user/login",
  register: BASE_URL + "/user/signup",
  listTasks: BASE_URL + "/task",
  deleteTask: BASE_URL + "/task",
  updateStatus: BASE_URL + "/task/status",
  updateAssignee: BASE_URL + "/task/assignees",
  listAssignees: BASE_URL + "/task/assignees",
  //Chat
  uploadChatMedia: BASE_URL + "/chat/media",
  //Categories
  viewMyTaskCategories: BASE_URL + "/task/getTaskCategory",
  viewMyCategories: BASE_URL + "/task/category",
  chatWords: BASE_URL + "/user/chatwords",
  chatbotReplies: BASE_URL + "/chatbot/replies",
  notification: BASE_URL + "/notification",
  notificationConfig: BASE_URL + "/notification/config",
  contactList: BASE_URL + "/user/contacts",
  profile: BASE_URL + "/user/profile",
  //Templates
  createTemplate: BASE_URL + "/template",
  getTemplates: BASE_URL + "/template",
  deleteTemplate: BASE_URL + "/template",
  //Favourites
  getFavourites: BASE_URL + "/favourite",
  addFavourite: BASE_URL + "/favourite",
  deleteFavourite: BASE_URL + "/favourite",
  getTags: BASE_URL + "/favourite/tags",
  //Calendar
  getCalendar: BASE_URL + "/calendar",
  //Events
  getEvents: BASE_URL + "/event",
  updateEvents: BASE_URL + "/event/invite",
  //Todo
  listTodo: BASE_URL + "/todo",
  updateTodo: BASE_URL + "/todo",
  deleteTodo: BASE_URL + "/todo",
  updateTodoStatus: BASE_URL + "/todo/status",
  //Appointments
  preAppointment: BASE_URL + "/appointment/pre",
  getAppointments: BASE_URL + "/appointment",
  setAppointmentStatus: BASE_URL + "/appointment/status",
};
