import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  
} from "react-google-maps";
import { useState, useEffect } from "react";
const GoogleMaps = withScriptjs(
  withGoogleMap((props) => {
    const [cords, setCords] = useState({ lat: 0, lng: 0 });

    useEffect(() => {
      setCords({ lng: props.lng, lat: props.lat });
    }, [props]);

    return (
      <GoogleMap
        onClick={props.onClick}
        defaultZoom={props.zoom || 8}
        defaultCenter={cords}
        center={cords}
      >
        {props.isMarkerShown && <Marker position={cords} />}
        {props.infowWindow && <InfoWindow position={cords}>{props.infowWindow}</InfoWindow>}
      </GoogleMap>
    );
  })
);
export default GoogleMaps;
