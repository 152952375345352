import { EVENTS_LOADING, LOAD_EVENTS, LOAD_SINGLE_EVENT, LOAD_EVENTS_ERROR, LOAD_INVITEES } from "../actions/types";

const initialState = {
  events: [],
  error: false,
  loading: false,
  thisPage: 1,
  allPages: 1,
  totalCount: 1,
  singleEvent: {},
};

export default function eventReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_EVENTS:
      return {
        ...state,
        events: payload.result,
        thisPage: payload.thisPage,
        allPages: payload.allPages,
        error: false,
        loading: false,
      };
    case EVENTS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case LOAD_SINGLE_EVENT:
      return {
        ...state,
        error: false,
        loading: false,
        singleEvent: payload,
      };
    case LOAD_INVITEES:
      return {
        ...state,
        error: false,
        loading: false,
        singleEvent: payload.result,
      }
    case LOAD_EVENTS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
