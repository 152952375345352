import React from 'react'
import { connect } from 'react-redux'
import CreateTemplate from './CreateTemplate'
import { CgTemplate, CgPlayListAdd } from 'react-icons/cg'
import { getTemplates } from '../../store/actions/templateActions'
import { Button } from 'antd'
import SingleTemplate from './SingleTemplate'
import Loader from "../../components/Loader";

export const Templates = ({ templates, getTemplates, loading }) => {
    const [createOpen, setcreateOpen] = React.useState(false);

    React.useEffect(() => {
        getTemplates({ limit: 49 }, 1);
    }, []);

    if(loading){
        return <Loader/>
    }

    return (
        <div className="container mb-5 main-page">
            <div className="d-flex my-4 justify-content-between">
                <div>
                    <h2 className="head-text mx-auto">
                        <CgTemplate /> Templates 
                    </h2>
                </div>
                <div>
                    <Button
                        className="text-center main-button"
                        style={{ cursor: "pointer" }}
                        onClick={() => setcreateOpen(!createOpen)}
                        id="close"
                    >
                        <CgPlayListAdd size="30" color="white" />
                    </Button>
                </div>
            </div>

            <div>
                {createOpen && <CreateTemplate />}
            </div>
            <div className="d-flex flex-wrap">
                {templates.map((template, i) => (
                    <SingleTemplate key={i} template={template} />
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    templates: state?.templates?.templates,
    loading: state?.templates?.loading,
})

const mapDispatchToProps = { getTemplates }

export default connect(mapStateToProps, mapDispatchToProps)(Templates)
