import React from "react";
import { connect } from "react-redux";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { BiDotsVertical } from "react-icons/bi";
import { Modal, Menu, Dropdown } from "antd";
import {
  deleteCategory,
  getCategories,
} from "../../../store/actions/categoryActions";
import { useTranslation } from "react-i18next";
import ViewCategory from "../ViewCategory";
import { getTasks } from "../../../store/actions/taskActions";

export const SingleCategory = ({
  category,
  deleteCategory,
  getCategories,
  thisPage,
  getTasks
}) => {
  let { t: t2 } = useTranslation(["delete"]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isCategoryVisible, setIsCategoryVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteCategory(category.id);
    getCategories({ limit: 10 }, thisPage);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showCategory = () => {
    setIsCategoryVisible(true);
  };

  const handleCancelCategory = () => {
    setIsCategoryVisible(false);
  };

  const menuOverlay = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={showModal}
          icon={
            <AiFillDelete
              style={{
                cursor: "pointer",
                color: "red",
              }}
            />
          }
        >
          Delete
        </Menu.Item>
        <Menu.Item
          icon={
            <AiOutlineEdit
              style={{
                cursor: "pointer",
                color: "var(--primaryColor)",
              }}
            />
          }
        >
          Edit
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className="template-box m-2 p-2">
      <Modal
        title={t2("confirm")}
        visible={isModalVisible}
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ className: "main-button" }}
        okText={t2("ok")}
        cancelText={t2("cancel")}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <p>
          {t2("sure")} "{category.title}"
        </p>
      </Modal>
      <ViewCategory visible={isCategoryVisible} handleCancel={handleCancelCategory} category={category} isCard={true} />
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2
            className="template-head-text mx-auto"
            style={{ cursor: "pointer" }}
            onClick={() => {showCategory(); getTasks({ categoryId: category.id })}}
          >
            {category?.title}
          </h2>
        </div>
        <div>
          <Dropdown overlay={menuOverlay}>
            <BiDotsVertical cursor="pointer" size="20" />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  deleteCategory,
  getCategories,
  getTasks
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleCategory);
