import React from "react";
import { connect } from "react-redux";
import { Input, Form } from "antd";
import { FaUserCheck, FaUserAlt } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { updateProfile } from "../../../store/actions/authAcrions";
import validator from "validator";
import { toastr } from "react-redux-toastr";

export const EditProfile = ({ updateProfile }) => {
  const [values, setValues] = React.useState({
    name: "",
    email: "",
    image: "",
  });

  const { name, email, image } = values;

  const handleEditProfile = () => {
    let formData = new FormData();

    name && formData.append("name", name);
    email && validator.isEmail(email) && formData.append("email", email);
    image && formData.append("image", image);

    
    if (!name && !email && !image) {
        return toastr.error("Nothing changed");
    }
    
    console.log(image);
    updateProfile(formData);
  };

  return (
    <div className="row">
      <div className="col task_form py-3 mt-4 px-5 my-5">
        <div className="row px-3">
          <Form>
            <Input
              prefix={<FaUserAlt />}
              value={name}
              onChange={({ target }) =>
                setValues({ ...values, name: target.value })
              }
              className="mt-3 task-input"
              placeholder="User Name"
            />
            <Form.Item
              name="email"
              rules={[
                {
                  required: false,
                  type: "email",
                  message: "Please input a valid email",
                },
              ]}
            >
              <Input
                prefix={<GrMail />}
                value={email}
                onChange={({ target }) =>
                  setValues({ ...values, email: target.value })
                }
                className="mt-3 task-input"
                placeholder="Email"
              />
            </Form.Item>
            <Input
              type="file"
              onChange={(e) =>
                setValues({ ...values, image: e?.target?.files[0] })
              }
              className="mt-3 task-input py-3"
              placeholder="Profile Image"
              accept={"image/*"}
            />
            <div className="col mt-3">
              <FaUserCheck
                onClick={handleEditProfile}
                size="40"
                style={{ color: "var(--primaryColor)", cursor: "pointer" }}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { updateProfile };

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
