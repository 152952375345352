import React from "react";
import { connect } from "react-redux";
import {
  deleteEvent,
  getEvents,
  updateEventStatus,
} from "../../../store/actions/eventActions";
import SingleEvent from "../SingleEvent";
import Loader from "../../../components/Loader";
import {
  Pagination,
  PaginationLink,
  PaginationItem,
  Row,
  Col,
} from "reactstrap";
import { Input, Modal, Button } from "antd";
import { VscLoading } from "react-icons/vsc";
import { FiFilter } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { FaCalendarCheck, FaCalendarTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import FilterBox from "../FilterBox";

export const PaginateEvents = ({
  getEvents,
  allPages,
  thisPage,
  events,
  history,
  tab,
  loading,
  deleteEvent,
  updateEventStatus,
}) => {
  const [filterOpen, setFilterOpen] = React.useState(false);

  const [filters, setFilters] = React.useState({
    type: tab === 1 ? "creator" : "invited",
    status: tab === 1 ? undefined : tab === 2 ? "pending" : "accepted",
    text: undefined,
    startDate: undefined,
    endDate: undefined,
    categoryId: undefined,
  });

  const clearFilters = () => {
    setFilters({
      type: tab === 1 ? "creator" : "invited",
      status: tab === 1 ? undefined : tab === 2 ? "pending" : "accepted",
      text: undefined,
      startDate: undefined,
      endDate: undefined,
      categoryId: undefined,
    });

    return getEvents({ ...filters, limit: 9 }, 1);
  };

  const handleChange = (e) => {
    let { name, value } = e.target || e;
    setFilters({ ...filters, [name]: value === "" ? undefined : value });
    console.log(filters);
    if (name !== "text") {
      return getEvents(
        { ...filters, [name]: value === "" ? undefined : value, limit: 9 },
        thisPage
      );
    }
  };

  const handleSearch = (text) => {
    return getEvents(
      { ...filters, text: text === "" ? undefined : text, limit: 9 },
      thisPage
    );
  };

  const handleDateChange = (target, date) => {
    setFilters({ ...filters, [target]: date });
    console.log(date);
    return getEvents({ ...filters, [target]: date, limit: 5 }, thisPage);
  };

  React.useEffect(() => {
    getEvents({ ...filters, limit: 9 }, 1);
  }, [tab]);

  const onClick = (event) => {
    console.log(event.target.textContent);
    switch (event.target.textContent) {
      case "»":
      case "»Last":
      case "Last":
        getEvents({ limit: 10, ...filters }, allPages);
        break;
      case "«":
      case "«First":
      case "First":
        getEvents({ limit: 10, ...filters }, 1);
        break;
      case "›":
      case "›Next":
      case "Next":
        getEvents({ limit: 10, ...filters }, thisPage + 1);
        break;
      case "‹":
      case "‹Previous":
      case "Previous":
        getEvents({ limit: 10, ...filters }, thisPage - 1);
        break;
      default:
        getEvents({ limit: 10, ...filters }, Number(event.target.textContent));
        break;
    }
  };

  const [searchText, setSearchText] = React.useState("");
  let { t, i18n } = useTranslation(["events"]);
  let { t: t2 } = useTranslation(["delete"]);
  const [selectedEvents, setselectedEvents] = React.useState([]);
  const [selectedActEvents, setselectedActEvents] = React.useState([]);

  const onSelect = (id) => {
    let found = selectedEvents.find((taskId) => taskId === id);
    if (found) {
      setselectedEvents(selectedEvents.filter((taskId) => taskId !== id));
    } else {
      setselectedEvents([...selectedEvents, id]);
    }
  };

  const onActSelect = (id) => {
    let found = selectedActEvents.find((taskId) => taskId === id);
    if (found) {
      setselectedActEvents(selectedActEvents.filter((taskId) => taskId !== id));
    } else {
      setselectedActEvents([...selectedActEvents, id]);
    }
  };

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [acting, setActing] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteEvent(selectedEvents);
    setDeleting(!deleting);
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isActionModalVisible, setIsActionModalVisible] = React.useState(false);

  const showActionModal = () => {
    setIsActionModalVisible(true);
  };

  const handleAction = (responseId) => {
    updateEventStatus(selectedActEvents, responseId, false);
    setActing(!acting);
    handleActionCancel();
  };

  const handleActionCancel = () => {
    setIsActionModalVisible(false);
  };

  React.useEffect(() => {
    setselectedEvents([]);
    setselectedActEvents([]);
  }, [tab, deleting, acting]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Col className="p-2">
      <Modal
        title={t2("confirm")}
        visible={isModalVisible}
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ className: "main-button" }}
        okText={t2("ok")}
        cancelText={t2("cancel")}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <p>
          {t2("sure")} {selectedEvents.length} events
        </p>
      </Modal>
      <Modal
        title={`Act on all ${selectedActEvents.length} events`}
        visible={isActionModalVisible}
        closable={false}
        onCancel={handleActionCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <div className="d-flex flex-wrap justify-content-center">
          <Button
            className="main-button-accept"
            onClick={() => handleAction("1")}
          >
            <FaCalendarCheck className="mx-1 my-auto" />
            Accept all
          </Button>
          <Button
            className="main-button-cancel"
            onClick={() => handleAction("2")}
          >
            <FaCalendarTimes className="mx-1 my-auto" /> Decline All
          </Button>
        </div>
      </Modal>
      {loading && (
        <div
          className="col text-center rotator"
          style={{
            fontSize: "40px",
            marginTop: "20%",
            color: "var(--primaryColor)",
          }}
        >
          <VscLoading />
        </div>
      )}
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">Events</h2>
        </div>
        <div>
          <Button
            className="text-center main-button mx-1"
            style={{ cursor: "pointer" }}
            onClick={() => setFilterOpen(!filterOpen)}
            id="close"
          >
            <FiFilter size="20" color="white" />
          </Button>
        </div>
      </div>
      <div>
        {filterOpen && (
          <FilterBox
            setFilters={setFilters}
            clearFilters={clearFilters}
            filters={filters}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            handleSearch={handleSearch}
            t={t}
          />
        )}
      </div>
      {!loading && events.length === 0 && (
        <div className="row justify-content-center align-items-center align-content-center">
          <div
            className="col text-center"
            style={{
              fontSize: "40px",
              color: "var(--primaryColor)",
            }}
          >
            No Events
          </div>
        </div>
      )}
      <Row className="d-flex flex-wrap">
        {events &&
          events.map((event, i) => {
            return (
              <SingleEvent
                event={event}
                key={i}
                filters={filters}
                history={history}
                onSelect={onSelect}
                onActSelect={onActSelect}
                selectedActEvents={selectedActEvents}
                selectedEvents={selectedEvents}
              />
            );
          })}
        <div>
          <Button
            className="main-button-cancel"
            onClick={showModal}
            disabled={selectedEvents.length === 0}
          >
            <MdDelete /> Delete all selected ({selectedEvents.length})
          </Button>
          <Button
            className="main-button"
            onClick={showActionModal}
            disabled={selectedActEvents.length === 0}
          >
            <MdDelete /> Act on all selected ({selectedActEvents.length})
          </Button>
        </div>
      </Row>

      {allPages > 1 && (
        <div className="col d-flex justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className="pag-link"
                >
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index}
                      className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </Col>
  );
};

const mapStateToProps = (state) => ({
  events: state?.events?.events,
  loading: state?.events?.loading,
  thisPage: state?.events?.thisPage,
  allPages: state?.events?.allPages,
});

const mapDispatchToProps = {
  getEvents,
  deleteEvent,
  updateEventStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaginateEvents);
