import axios from "axios";

import { apis } from "../../constants/API";
import {
  EVENTS_LOADING,
  LOAD_EVENTS,
  LOAD_SINGLE_EVENT,
  LOAD_EVENTS_ERROR,
  LOAD_INVITEES,
} from "./types";

import { tokenConfig } from "./authAcrions";
import { toastr } from "react-redux-toastr";

export const getEvents = (query, page) => (dispatch, getState) => {
  dispatch({
    type: EVENTS_LOADING,
  });
  return axios
    .get(apis.getEvents, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: query.limit ? query.limit : 30,
        page,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_EVENTS,
        payload: {
          result: data.result,
          thisPage: data.thisPage,
          allPages: data.allPages,
        },
      });
      return data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const createNewEvent = (data) => (dispatch, getState) => {
  dispatch({
    type: EVENTS_LOADING,
  });
  axios
    .post(apis.getEvents, data, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      toastr.success("Event Created");
      const oldEvents = getState().events?.events;
      let newEvents = [data, ...oldEvents];
      return dispatch({
        type: LOAD_EVENTS,
        payload: { result: newEvents },
      });
    })
    .catch((err) => console.log(err));
};

export const updateEventStatus =
  (eventId, status, single, fromNotification) => (dispatch, getState) => {
    axios
      .put(
        apis.updateEvents,
        {
          eventId,
          status,
        },
        {
          ...tokenConfig(getState),
        }
      )
      .then((res) => {
        let { data } = res.data;
        let currentEvents = getState().events?.events;
        let currentEvent = getState().events?.singleEvent;
        console.log(data);
        if (Array.isArray(eventId)) {
          let i;
          for (i = 0; i < eventId.length; i++) {
            currentEvents = currentEvents.map((e) => {
              if (e.id === eventId[i]) {
                e = data[i];
                return e;
              } else {
                return e;
              }
            });
          }
        } else {
          currentEvents = currentEvents.map((e) => {
            if (e.id === eventId) {
              let tempInvited = e.invited;
              e = data;
              e.invited = tempInvited;
              return e;
            } else {
              return e;
            }
          });
        }
        if (single) {
          let tempMedia = currentEvent.event.medias;
          let tempTags = currentEvent.event.tags;
          currentEvent.event = data;
          currentEvent.event.medias = tempMedia;
          currentEvent.event.tags = tempTags;
        }
        let message = status === "1" ? "Accepted" : "Rejected";
        toastr.success("Event " + message, "Event status updated");
        if (single) {
          dispatch({
            type: LOAD_SINGLE_EVENT,
            payload: currentEvent,
          });
        } else {
          return dispatch({
            type: LOAD_EVENTS,
            payload: { result: currentEvents },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const editEvent = (eventData, handleCancel) => (dispatch, getState) => {
  dispatch({
    type: EVENTS_LOADING,
  });
  axios
    .put(apis.getEvents, eventData, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      let { data } = res.data;
      let currentEvents = getState().events?.events;
      currentEvents = currentEvents.map((e) => {
        if (e.id === data.id) {
          e = data;
          return e;
        } else {
          return e;
        }
      });
      toastr.success("Event updated");
      if (res.status === 200) handleCancel();
      return dispatch({
        type: LOAD_EVENTS,
        payload: { result: currentEvents },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSingleEvent = (eventId) => (dispatch, getState) => {
  dispatch({
    type: EVENTS_LOADING,
  });
  return axios
    .get(apis.getEvents, {
      ...tokenConfig(getState),
      params: {
        eventId,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_SINGLE_EVENT,
        payload: data,
      });

      return data;
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: LOAD_EVENTS_ERROR,
      });
    });
};

export const getEventInvitees =
  (eventId, limit, page) => (dispatch, getState) => {
    return axios
      .get(apis.getEvents, {
        ...tokenConfig(getState),
        params: {
          eventId,
          limit: limit ? limit : 3,
          page,
        },
      })
      .then((res) => {
        const {
          data: { data },
        } = res;
        let currEvent = getState().events?.singleEvent?.event;
        let currUsers = data.users.result;
        dispatch({
          type: LOAD_INVITEES,
          payload: {
            result: {
              event: currEvent,
              users: {
                result: currUsers,
                thisPage: data.users.thisPage,
                allPages: data.users.allPages,
                count: data.users.count,
              },
            },
          },
        });
      })
      .catch((err) => {
        console.log(err);
        return dispatch({
          type: LOAD_EVENTS_ERROR,
        });
      });
  };

export const deleteEvent = (eventId) => (dispatch, getState) => {
  axios
    .delete(apis.getEvents, {
      ...tokenConfig(getState),
      params: {
        eventId,
      },
    })
    .then((res) => {
      const oldEvents = getState().events?.events;
      let newEvents;
      if (Array.isArray(eventId)) {
        let i;
        for (i = 0; i < eventId.length; i++) {
          if (i === 0) newEvents = oldEvents.filter((e) => e.id !== eventId[i]);
          else newEvents = newEvents.filter((e) => e.id !== eventId[i]);
        }
      } else {
        newEvents = oldEvents.filter((e) => e.id !== eventId);
      }
      toastr.success("Event deleted");
      return dispatch({
        type: LOAD_EVENTS,
        payload: { result: newEvents },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addInvitees = (data) => (dispatch, getState) => {
  dispatch({
    type: EVENTS_LOADING,
  });
  axios
    .post(apis.updateEvents, data, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      toastr.success("Event Updated");
      const oldEvents = getState().events?.events;
      let newEvents = [data, ...oldEvents];
      return dispatch({
        type: LOAD_EVENTS,
        payload: { result: newEvents },
      });
    })
    .catch((err) => console.log(err));
};
