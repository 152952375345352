import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from "./authReducer";
import taskReducer from "./taskReducer";
import catReducer from "./categoryReducer";
import usersReducer from "./usersReducer";
import chatWords from "./chatWords";
import botReducer from "./chatbotReducer";
import notificationReducer from "./notificationReducer";
import contactReducer from "./contactReducer";
import templateReducer from "./templateReducer";
import favouritesReducer from "./favouritesReducer";
import calendarReducer from "./calendarReducer";
import langReducer from "./langReducer";
import eventReducer from "./eventReducer";
import tagsReducer from "./tagsReducer";
import todoReducer from "./todoReducer";
import chatReducer from "./chatReducer";
import appointmentsReducer from "./appointmentsReducer";
import messageSelectReducer from "./messageSelectReducer";
import taskCatReducer from "./taskCategoryReducer";

export default combineReducers({
  toastr: toastrReducer,
  auth: authReducer,
  task: taskReducer,
  todo: todoReducer,
  category: catReducer,
  taskCategory: taskCatReducer,
  users: usersReducer,
  chatwords: chatWords,
  bot: botReducer,
  notifications: notificationReducer,
  contacts: contactReducer,
  templates: templateReducer,
  favourites: favouritesReducer,
  tags: tagsReducer,
  calendar: calendarReducer,
  events: eventReducer,
  language: langReducer,
  chat: chatReducer,
  appointments: appointmentsReducer,
  selectedMessages: messageSelectReducer,
});
