import React from "react";
import { connect } from "react-redux";
import { Button, Select, Modal, Form } from "antd";
import { useTranslation } from "react-i18next";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import {
  addAssignees,
  getSingleTask,
  getTaskAssignees,
} from "../../../../store/actions/taskActions";
import {
  Pagination,
  PaginationLink,
  PaginationItem,
  Row,
  Col,
} from "reactstrap";
import SingleUser from "./SingleUser";
import { getContactList } from "../../../../store/actions/contactActions";
import AsyncSelect from "react-select/async";

export const Users = ({
  getSingleTask,
  addAssignees,
  getContactList,
  task,
  users,
  currVariant,
  getTaskAssignees,
  allPages,
  thisPage,
}) => {
  let { t } = useTranslation(["users"]);
  const [userType, setUserType] = React.useState("users");
  const [addModalVisible, setAddModalVisible] = React.useState(false);

  React.useEffect(() => {
    getTaskAssignees({ taskId: task.id, userType: userType, limit: 4 }, 1);
  }, []);

  const showAdd = () => {
    setAddModalVisible(true);
  };

  const handleChange = (e) => {
    setUserType(e);
    getTaskAssignees({ taskId: task.id, userType: e, limit: 4 }, 1);
  };

  const filterContacts = async (search) => {
    let newCats = await getContactList({ search });

    return newCats.map((contact) => {
      return { label: contact.name, value: contact.id };
    });
  };
  const [usersToAdd, setUsersToAdd] = React.useState([]);

  const addUsers = () => {
    let data = new FormData();
    let users = usersToAdd.map((item) => item.value);
    console.log(users);
    users && users.map((user) => data.append("addingAssignees[]", user));
    data.append("taskId", task.id);
    addAssignees(data);
    setAddModalVisible(false);
  };

  const onClick = (event) => {
    switch (event.target.textContent) {
      case "»":
      case "»Last":
      case "Last":
        getTaskAssignees(
          { limit: 4, userType: userType, taskId: task?.id },
          allPages
        );
        break;
      case "«":
      case "«First":
      case "First":
        getTaskAssignees(
          { limit: 4, userType: userType, taskId: task?.id },
          1
        );
        break;
      case "›":
      case "›Next":
      case "Next":
        getTaskAssignees(
          { limit: 4, userType: userType, taskId: task?.id },
          thisPage + 1
        );
        break;
      case "‹":
      case "‹Previous":
      case "Previous":
        getTaskAssignees(
          { limit: 4, userType: userType, taskId: task?.id },
          thisPage - 1
        );
        break;
      default:
        getTaskAssignees(
          { limit: 4, userType: userType, taskId: task?.id },
          Number(event.target.textContent)
        );
        break;
    }
  };

  return (
    <div className="row justify-content-center" style={{ height: "100%" }}>
      <Modal
        title={t("confirm")}
        visible={addModalVisible}
        closable={false}
        onOk={addUsers}
        onCancel={() => setAddModalVisible(false)}
        okButtonProps={{ className: "main-button" }}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <Form>
          <Row>
            <Col className="col-md-4">{t("assignees")}</Col>
            <Col className="col-md-8">
              <Form.Item>
                <AsyncSelect
                  cacheOptions
                  isMulti
                  defaultOptions
                  loadOptions={filterContacts}
                  onChange={(values) => {
                    setUsersToAdd(values);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2
            className="mx-2"
            style={{
              fontFamily: "cairo",
              color: "var(--primaryColor)",
              fontWeight: "bolder",
            }}
          >
            {t("assignees")}
          </h2>
        </div>
        <div>
          <Select
            defaultValue="users"
            style={{ width: 120 }}
            onChange={handleChange}
          >
            <Select.Option value="users">{t("users")}</Select.Option>
            <Select.Option value="guests">{t("guests")}</Select.Option>
          </Select>
          <Button
            className="main-button"
            icon={<AiOutlineUsergroupAdd />}
            onClick={showAdd}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        {users &&
          users.length !== 0 &&
          users.map((user, idx) => {
            return (
              <SingleUser key={idx} user={user} currVariant={currVariant} />
            );
          })}
      </div>
      {allPages > 1 && (
        <div className="col d-flex justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className="pag-link"
                >
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index}
                      className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  task: state?.task?.singleTask?.task,
  users: state?.task?.taskAssignees?.result,
  allPages: state?.task?.taskAssignees?.allPages,
  thisPage: state?.task?.taskAssignees?.thisPage,
});

const mapDispatchToProps = {
  getSingleTask,
  getContactList,
  addAssignees,
  getTaskAssignees,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
