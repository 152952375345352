import React from "react";
import { connect } from "react-redux";
import { getFreeAppointments } from "../../../store/actions/appointmentsActions";
import SingleSlot from "../SingleSlot";
import Loader from "../../../components/Loader";
import {
  Pagination,
  PaginationLink,
  PaginationItem,
  Row,
} from "reactstrap";
import { VscLoading } from "react-icons/vsc";
import emptyicon from "../../../Assets/Icons/empty-white-box.png";

export const PaginateSlots = ({
  allPages,
  thisPage,
  appointments,
  history,
  userId,
  loading,
  getFreeAppointments,
  book,
}) => {

  const onClick = (event) => {
    console.log(event.target.textContent);
    switch (event.target.textContent) {
      case "»":
      case "»Last":
      case "Last":
        getFreeAppointments({ limit: 10, userId: userId}, allPages);
        break;
      case "«":
      case "«First":
      case "First":
        getFreeAppointments({ limit: 10, userId: userId}, 1);
        break;
      case "›":
      case "›Next":
      case "Next":
        getFreeAppointments({ limit: 10, userId: userId}, thisPage + 1);
        break;
      case "‹":
      case "‹Previous":
      case "Previous":
        getFreeAppointments({ limit: 10, userId: userId}, thisPage - 1);
        break;
      default:
        getFreeAppointments(
          { limit: 10, userId: userId},
          Number(event.target.textContent)
        );
        break;
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="col p-2">
      {loading && (
        <div
          className="col text-center rotator"
          style={{
            fontSize: "40px",
            marginTop: "20%",
            color: "var(--primaryColor)",
          }}
        >
          <VscLoading />
        </div>
      )}
      {!loading && appointments.length === 0 && (
        <div>
          <div className="col text-center">
            <img
              src={emptyicon}
              alt="Empty"
              style={{ width: 100, height: 100 }}
            />
          </div>
          <div
            className="col text-center"
            style={{
              fontSize: "40px",
              color: "var(--primaryColor)",
            }}
          >
            No Free Slots
          </div>
        </div>
      )}
      <Row className="d-flex flex-wrap">
        {appointments &&
          appointments.map((appointment, i) => {
            return (
              <SingleSlot
                appointment={appointment}
                key={i}
                history={history}
                book={book}
              />
            );
          })}
      </Row>

      {allPages > 1 && (
        <div className="col d-flex justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className="pag-link"
                >
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index}
                      className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appointments: state?.appointments?.appointments,
  loading: state?.appointments?.loading,
  thisPage: state?.appointments?.thisPage,
  allPages: state?.appointments?.allPages,
});

const mapDispatchToProps = {
  getFreeAppointments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginateSlots);
