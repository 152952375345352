import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Menu, Dropdown } from "antd";
import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { setAppointmentStatus } from "../../../store/actions/appointmentsActions";
import { BiDotsVertical } from "react-icons/bi";

export const SingleAppointment = ({
  appointment,
  filters,
  setAppointmentStatus,
}) => {
  let { t, i18n } = useTranslation(["appointments"]);

  const statusIcon = () => {
    switch (appointment?.status) {
      case "pending":
        return <AiOutlineExclamationCircle color="orange" />;
      case "accepted":
        return <AiOutlineCheckCircle color="green" />;
      case "declined":
        return <AiOutlineCloseCircle color="red" />;
      default:
        return <></>;
    }
  };

  const handleStatus = (response) => {
    let data = new FormData();
    data.append("status", response);
    data.append("appointmentId", appointment.id);
    setAppointmentStatus(data);
  };

  const MenuOverlay = () => {
    return (
      <Menu>
        <Menu.ItemGroup title="Actions">
          <Menu.Item
            key="3"
            onClick={() => handleStatus("accepted")}
            icon={
              <AiOutlineCheckCircle
                style={{
                  cursor: "pointer",
                  color: "green",
                }}
              />
            }
          >
            Accept
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => handleStatus("declined")}
            icon={
              <AiOutlineCloseCircle
                style={{
                  cursor: "pointer",
                  color: "red",
                }}
              />
            }
          >
            Decline
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  };

  return (
    <div className="p-1 col-md-6">
      <div className="task-item">
        <div className="d-flex justify-content-between">
          <div>
            <h2
              style={{
                color: "var(--primaryColor)",
                fontWeight: "bold",
                fontSize: "20px",
                wordBreak: "break-word",
              }}
            >
              {statusIcon()} {appointment?.title}
            </h2>
          </div>
          {appointment.status === "pending" && filters.type === "invited" && (
            <div>
              <Dropdown overlay={MenuOverlay}>
                <BiDotsVertical cursor="pointer" />
              </Dropdown>
            </div>
          )}
        </div>
        <p>{appointment?.description}</p>
        <Row>
          <Col md={6}>
            From:{" "}
            {moment(appointment?.startDate).locale(i18n.language).format("lll")}
          </Col>
          <Col md={6}>
            To:{" "}
            {moment(appointment?.endDate).locale(i18n.language).format("lll")}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {setAppointmentStatus};

export default connect(mapStateToProps, mapDispatchToProps)(SingleAppointment);
