import { APPOINTMENTS_LOADING, LOAD_APPOINTMENTS, LOAD_SINGLE_APPOINTMENT } from "../actions/types";

const initialState = {
  appointments: [],
  error: false,
  loading: false,
  thisPage: 1,
  allPages: 1,
  totalCount: 1,
  singleAppointment: {},
};

export default function eventReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_APPOINTMENTS:
      return {
        ...state,
        appointments: payload.result,
        thisPage: payload.thisPage,
        allPages: payload.allPages,
        error: false,
        loading: false,
      };
    case APPOINTMENTS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case LOAD_SINGLE_APPOINTMENT:
      return {
        ...state,
        error: false,
        loading: false,
        singleAppointment: payload,
      };
    default:
      return state;
  }
}
