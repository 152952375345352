import { Modal, Timeline, Tooltip } from "antd";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/ar-sa";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { BiCalendarEvent, BiTask } from "react-icons/bi";

export const EventsModal = ({ data, handleOk }) => {
  let { t, i18n } = useTranslation(["calendar"]);
  let history = useHistory();

  const { events, date, isVisible } = data;
  if (events) {
    events.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
  }

  const handleRedirect = (event) => {
    switch(event.moduleName){
      case "task": history.push("/tasks/"+event.entityId);break;
      case "event": history.push("/events/"+event.entityId);break;
      default: return;
    }
  }

  const getDot = (event) => {
    if (event.moduleName === "task") {
      return (
        <Tooltip title={`Go to "${event.title}"`}>
          <BiTask
            cursor="pointer"
            color={event.color}
            onClick={() => history.push("tasks/" + event?.entityId)}
          />
        </Tooltip>
      );
    } else if (event.moduleName === "event") {
      return (
        <Tooltip title={`Go to "${event.title}"`}>
          <BiCalendarEvent color={event.color} />
        </Tooltip>
      );
    }
  };

  return (
    <Modal
      title={
        t("events") +
        " " +
        (date && moment(date).locale(i18n.language).format("DD-MM-YYYY"))
      }
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleOk}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ className: "main-button" }}
      closable={false}
      style={{ overflowY: "auto" }}
    >
      <Timeline mode="left">
        {events &&
          events.map((event, idx) => {
            return (
              <Timeline.Item
                key={idx}
                dot={getDot(event)}
                onClick={() => handleRedirect(event)}
                label={
                  t("from") +
                  " " +
                  moment(event.startDate)
                    .locale(i18n.language)
                    .format("HH:MM") +
                  " " +
                  t("to") +
                  " " +
                  moment(event.endDate)
                    .locale(i18n.language)
                    .format("HH:MM (DD/MM)")
                }
                style={{ whiteSpace: "pre-line", cursor: "pointer" }}
              >
                {event?.title}
              </Timeline.Item>
            );
          })}
      </Timeline>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EventsModal);
