import React from "react";
import { connect } from "react-redux";
import { getNotifications } from "../../store/actions/notificationActions";
import { AiFillNotification } from "react-icons/ai";
import PaginateNotification from "./PaginateNotification";
import { useTranslation } from "react-i18next";
import { TabContent, NavItem, NavLink, Nav, TabPane, Row } from "reactstrap";
import classnames from "classnames";

export const Notifications = ({
  notifications,
  loading,
  count,
  getNotifications,
}) => {
  let { t } = useTranslation(["notifications"]);
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  React.useEffect(() => {
    getNotifications({ limit: 8, moduleId: activeTab }, 1);
  }, [activeTab]);

  return (
    <div className="main-page container" style={{ minHeight: "80vh" }}>
      <div className="my-4">
        <h2 className="head-text mx-auto">
          <AiFillNotification /> {t("notification")}
        </h2>
      </div>
      <Nav tabs>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("1");
            }}
          >
            Tasks
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("2");
            }}
          >
            Favourites
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("3");
            }}
          >
            Events
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "4" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("4");
            }}
          >
            To-do
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "5" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("5");
            }}
          >
            Appointment
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="container">
            <PaginateNotification tab={1} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="2">
          <Row className="container">
            <PaginateNotification tab={2} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="3">
          <Row className="container">
            <PaginateNotification tab={3} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="4">
          <Row className="container">
            <PaginateNotification tab={4} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="5">
          <Row className="container">
            <PaginateNotification tab={5} />
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: state?.notifications?.notifications,
  loading: state?.notifications?.loading,
  count: state?.notifications?.count,
});

const mapDispatchToProps = { getNotifications };

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
