import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Form, Input } from "antd";
import TagsInput from "react-tagsinput";
import { editFavourite } from "../../../store/actions/favouritesActions";

export const EditFavourite = ({
  isModalVisible,
  handleCancel,
  editFavourite,
  favourite,
}) => {
  let { t } = useTranslation(["favourites"]);
  const [favouriteData, setfavouriteData] = React.useState({
    text: "",
    tags: [],
  });

  React.useEffect(() => {
    setfavouriteData({
      text: favourite?.text,
      tags: favourite?.tags.map((t) => (t.tag ? t.tag : t)),
    });
  }, [favourite]);

  const onChange = (e) => {
    let { name, value } = e.target;
    setfavouriteData({ ...favouriteData, [name]: value });
  };

  const handleChangeTags = (tags) => {
    setfavouriteData({ ...favouriteData, tags: tags });
  };

  let { text, tags } = favouriteData;

  const handleEdit = () => {
    let formData = new FormData();
    formData.append("favouriteId", favourite?.id);
    text && formData.append("text", text);
    tags && tags.map((tag) => formData.append("tags[]", tag));
    editFavourite(formData, handleCancel);
  };

  return (
    <Modal
      closable={false}
      title="Edit favourite item"
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={handleEdit}
      okButtonProps={{
        className: "main-button",
      }}
      okText="Ok"
      cancelButtonProps={{ className: "main-button-cancel" }}
      cancelText="Cancel"
    >
      <Form>
        <Form.Item name="text">
          <Input
            defaultValue={text}
            onChange={onChange}
            name="text"
            value={text}
            type="textarea"
            className="task-input"
            label="Text"
          />
        </Form.Item>
        <Form.Item>
          <TagsInput
            maxTags="15"
            className="task-input"
            onChange={handleChangeTags}
            name="tags"
            value={tags}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { editFavourite };

export default connect(mapStateToProps, mapDispatchToProps)(EditFavourite);
