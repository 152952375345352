import axios from "axios";

import { apis } from "../../constants/API";
import {
    CALENDAR_LOADING,
    LOAD_CALENDAR,
} from "./types";

import { tokenConfig } from "./authAcrions";

export const getCalendar = (query, page,) => (dispatch, getState) => {
    dispatch({
        type: CALENDAR_LOADING,
    });
    return axios
        .get(apis.getCalendar, {
            ...tokenConfig(getState),
            params: {
                ...query,
                limit: query.limit ? query.limit : 30,
                startDate: query.startDate,
                endDate: query.endDate,
                page,
            },
        })
        .then((res) => {
            const {
                data: { data },
            } = res;
            dispatch({
                type: LOAD_CALENDAR,
                payload: { result: data.result},
            });
            return data;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
};