import React from "react";
import { connect } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import { createReply } from "../../../store/actions/chatbotActions";

export const CreateReplyModal = ({
  setCreateOpen,
  createOpen,
  createReply,
}) => {
  const togggleModal = () => setCreateOpen(!createOpen);

  const [values, setValues] = React.useState({
    value: "",
    valueAR: "",
    name: "",
  });

  const { name, value, valueAR } = values;

  const onChange = (e) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <div>
      <Modal isOpen={createOpen} toggle={togggleModal}>
        <ModalHeader>
          <span className="text text-capitalize">Create new chatbot reply</span>
        </ModalHeader>
        <ModalBody>
          <Input
            type="text"
            height={10}
            rows={10}
            name="name"
            onChange={onChange}
            value={values.name}
            className="my-2"
            placeholder="Reply Name"
          />
          <Input
            type="textarea"
            height={10}
            rows={10}
            name="value"
            onChange={onChange}
            value={values.value}
            className="my-2"
            placeholder="Reply in English"
          />

          <Input
            type="textarea"
            height={10}
            rows={10}
            name="valueAR"
            onChange={onChange}
            value={values.valueAR}
            className="my-2"
            placeholder="Reply in Arabic"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="main-button"
            disabled={
              name.length === 0 || value.length === 0 || valueAR.length === 0
            }
            onClick={() => createReply(name, value, valueAR, togggleModal)}
          >
            Create
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { createReply };

export default connect(mapStateToProps, mapDispatchToProps)(CreateReplyModal);
