import React from "react";
import { connect } from "react-redux";
import { Skeleton, Card, Avatar, Button } from "antd";
import { FaUserCircle } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { BiDotsVertical } from "react-icons/bi";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu, Modal } from "antd";
import { deleteContact } from "../../../store/actions/contactActions";
import EditContact from "../EditContact";
import { useHistory } from "react-router-dom";
import {CgProfile} from "react-icons/cg"

export const ContactItem = ({ contact, loading, deleteContact }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  let { t, i18n } = useTranslation(["contacts"]);
  let { t: t2 } = useTranslation(["delete"]);
  let history = useHistory()

  const handleDelete = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteContact(contact?.id);
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const handleEdit = () => {
    setIsEditVisible(true);
  };
  const handleEditCancel = () => {
    setIsEditVisible(false);
  };

  const MenuOverlay = () => {
    return (
      <Menu>
        <Menu.ItemGroup title="Actions">
          <Menu.Item
            onClick={handleDelete}
            key="1"
            icon={
              <AiFillDelete
                style={{
                  color: "red",
                }}
              />
            }
          >
            Delete
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={handleEdit}
            icon={
              <AiOutlineEdit
                style={{
                  color: "var(--primaryColor)",
                }}
              />
            }
          >
            Edit
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  };

  const handleCall = () => {
    console.log("Calling ", contact?.name);
    window.location = "tel:+" + contact?.mobile;
  };

  return (
    <div className="col-md-6 p-1">
      <div className="task-item text-left mt-0">
        <Modal
          title={t2("confirm")}
          visible={isModalVisible}
          closable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ className: "main-button" }}
          okText={t2("ok")}
          cancelText={t2("cancel")}
          cancelButtonProps={{ className: "main-button-cancel" }}
        >
          <p>
            {t2("sure")} "{contact?.name}"
          </p>
        </Modal>
        <EditContact isModalVisible={isEditVisible} handleCancel={handleEditCancel} contact={contact}/>
        <Skeleton loading={loading} avatar active>
          <div className="row position-relative">
            <div>
              <Dropdown overlay={MenuOverlay}>
                <BiDotsVertical
                  className="position-absolute top-0 end-0 mx-1"
                  size="15"
                />
              </Dropdown>
            </div>
            <Card.Meta
              avatar={
                <Avatar src={contact?.contact?.image || contact?.image}>
                  <FaUserCircle size="36" />
                </Avatar>
              }
              title={contact?.name}
              description={contact?.mobile}
            />
            <div className="col mt-3">
              <span
                style={{
                  color: "var(--primaryColor)",
                  fontWeight: "bold",
                  fontFamily: "Cairo",
                }}
              >
                {" "}
                {t("added_at")}{" "}
                {moment(contact?.createdAt).locale(i18n.language).format("ll")}
              </span>
            </div>
            <div className="col mb-0">
              {contact?.contact?.mobile && (
                <Button
                  className="rounded-button text-right"
                  onClick={handleCall}
                >
                  <FiPhoneCall />
                </Button>
              )}
              {contact?.contactUserId && <Button
                  className="rounded-button text-right m-1"
                  onClick={() => history.push("/users/"+contact?.contactUserId)}
                >
                  <CgProfile />
                </Button>}
            </div>
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { deleteContact };

export default connect(mapStateToProps, mapDispatchToProps)(ContactItem);
