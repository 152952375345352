import { notification } from "antd";
import admin from "firebase";
import {
  changeNotificationSeenCount,
  getTopNotificationAction,
} from "../store/actions/notificationActions";
import store from "../store/store";
import { AiOutlineNotification } from "react-icons/ai";

if (!admin.apps.length) {
  admin.initializeApp({
    apiKey: "AIzaSyCN0QvAfQ9ypBqA_Y0j9IYM7Azvc2bjN-0",
    authDomain: "businesschat-8f802.firebaseapp.com",
    databaseURL: "https://businesschat-8f802-default-rtdb.firebaseio.com",
    projectId: "businesschat-8f802",
    storageBucket: "businesschat-8f802.appspot.com",
    messagingSenderId: "411457835611",
    appId: "1:411457835611:web:c2bd57d353fb68711d657e",
    measurementId: "G-QNHLD9L3D6",
  });
} else {
  admin.app(); // if already initialized, use that one
}

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = admin.messaging();

export const getToken = (setTokenFound) => {
  return messaging
    .getToken({
      vapidKey:
        "BCp-1y9s2GNkRbJ7x9wlR8aFKgOVOpQJyj0hAKzcqIPk5ZNDtUK0ISkQxhk3MRyqAcr0l97GurrZJiti2mXL1IE",
    })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("deviceToken", currentToken);
        setTokenFound(currentToken);
        onMessageListener();
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

const displayNotification = (payload) => {
  notification.open({
    message: payload?.notification?.title,
    description: payload?.notification?.body,
    icon: <AiOutlineNotification color="var(--primaryColor)" size="26" />,
  });
  changeNotificationSeenCount("increase")(store.dispatch, store.getState);
  getTopNotificationAction()(store.dispatch, store.getState);
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      console.log("Got New Message", payload);
      displayNotification(payload);
      resolve(payload);
    });
  });

export const firebase = admin;
