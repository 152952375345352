import React from "react";
import { connect } from "react-redux";
import { RiLockPasswordFill } from "react-icons/ri";
import { Form, FormGroup, Button } from "reactstrap";
import { Input } from "antd";
import { logout, resetPassword } from "../../store/actions/authAcrions";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
export const ResetPassword = ({ resetPassword, logout, user }) => {
  let history = useHistory();
  let { token } = useParams();
  const [passData, setPassData] = React.useState({
    newPassword: "",
    newPassword1: "",
  });

  const { newPassword, newPassword1 } = passData;

  const onSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    token && formData.append("token", token);
    newPassword && formData.append("newPassword", newPassword);

    resetPassword(formData, history);
  };

  React.useEffect(() => {
    if (user) {
      history.push("/");
    }
  }, [user]);

  return (
    <div className="login-page">
      <Form className="login-form col-md-6" onSubmit={onSubmit}>
        <h1 style={{ color: "var(--mainWhite)" }}>
          Reset Password <RiLockPasswordFill />
        </h1>
        <FormGroup>
          <Input.Password
            className="mt-2 login-input"
            placeholder="New Password"
            invalid={newPassword && newPassword.length < 6}
            value={newPassword}
            onChange={({ target }) => {
              setPassData({ ...passData, newPassword: target.value });
            }}
          />
          {newPassword && newPassword.length < 6 && (
            <p className="invalid-entry-message">
              Length must be atleast 6 characters
            </p>
          )}
        </FormGroup>
        <FormGroup>
          <Input.Password
            className="mt-2 login-input"
            placeholder="Confirm New Password"
            invalid={
              newPassword1 &&
              (newPassword1.length < 6 || newPassword1 !== newPassword)
            }
            value={newPassword1}
            onChange={({ target }) => {
              setPassData({ ...passData, newPassword1: target.value });
            }}
            required={true}
          />
          {newPassword1 && newPassword1.length < 6 && (
            <p className="invalid-entry-message">
              Length must be more than 6 characters
            </p>
          )}
          {newPassword1 && newPassword1 !== newPassword && (
            <p className="invalid-entry-message">Passwords do not match</p>
          )}
        </FormGroup>
        <FormGroup>
          <div className="row">
            <Button
              className="sec-button my-3"
              type="submit"
              disabled={
                newPassword1 !== newPassword ||
                newPassword1.length < 6 ||
                newPassword.length < 6
              }
            >
              Confirm Reset
            </Button>
          </div>
        </FormGroup>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
});

const mapDispatchToProps = { resetPassword, logout };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
