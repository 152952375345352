import React from "react";
import { connect } from "react-redux";
import { getNotifications } from "../../store/actions/notificationActions";
import Loader from "../../components/Loader";
import NotificationItem from "./NotificationItem";
import { GoTasklist } from "react-icons/go";
import { IoMdNotifications } from "react-icons/io";
export const NotificationPage = ({
  notifications,
  loading,
  getNotifications,
  thisPage,
  allPages,
}) => {
  const [filter, setFilter] = React.useState({ limit: 10, action: false });

  const fetchNotification = () => {
    getNotifications(filter, 1);
  };

  const getMore = (e) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;
    if (scrollHeight - scrollTop < clientHeight + 10) {
      if (!loading && allPages > thisPage) getNotifications(filter);
    }
  };

  React.useEffect(() => {
    fetchNotification();
  }, []);

  React.useEffect(() => {
    fetchNotification();
  }, [filter.action]);

  return (
    <div className="main-page" style={{ height: "100vh" }} onScroll={getMore}>
      <div className="container-fluid">
        {loading && <Loader />}
        <div className="notificationItem justify-content-center row">
          <div className="col text-center">
            <IoMdNotifications
              size="40"
              onClick={() => setFilter({ ...filter, action: false })}
              color={filter.action ? "black" : "var(--primaryColor)"}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="col text-center">
            <GoTasklist
              size="35"
              onClick={() => setFilter({ ...filter, action: true })}
              color={filter.action ? "var(--primaryColor)" : "black"}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div>
          {notifications.length === 0 && !loading && (
            <>
              <div className="text-center align-self-center my-5">
                <IoMdNotifications size="50" color="var(--primaryColor)" />
                <div>
                  <p className="main-text">No notifications yet...</p>
                </div>
              </div>
            </>
          )}
          {notifications &&
            notifications?.map((notification, i) => {
              return (
                <NotificationItem
                  notification={notification}
                  key={i}
                  currentAction={filter.action}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: state?.notifications?.notifications,
  loading: state?.notifications?.loading,
  thisPage: state?.notifications?.thisPage,
  allPages: state?.notifications?.allPages,
});

const mapDispatchToProps = { getNotifications };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
