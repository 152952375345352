import React from "react";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import { getChatbotReplies } from "../../store/actions/chatbotActions";
import { SingleReply } from "./SingleReply";
import { Button } from "reactstrap";
import CreateReplyModal from "./CreateReply";
import { FaRobot } from "react-icons/fa";

export const ChatBotManagement = ({ loading, getChatbotReplies, replies }) => {
  const [createOpen, setCreateOpen] = React.useState(false);

  React.useEffect(() => {
    getChatbotReplies();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mb-5 main-page">
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            <FaRobot /> Manage Bot Replies 
          </h2>
        </div>
        <div>
          <Button className="main-button" onClick={() => setCreateOpen(true)}>
            Create Reply
          </Button>
        </div>
      </div>
      <CreateReplyModal createOpen={createOpen} setCreateOpen={setCreateOpen} />
      {replies.map((reply, i) => (
        <SingleReply key={i} reply={reply} />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  replies: state?.bot?.replies,
  loading: state.bot.loading,
});

const mapDispatchToProps = {
  getChatbotReplies,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatBotManagement);
