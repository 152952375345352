import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Loader from "../../components/Loader";

export const ProtectedRoute = ({ auth, component: Component, ...rest }) => {
  if (auth.isLoading) return <Loader />;
  return (
    <Route
      {...rest}
      render={(props) =>
        auth && auth?.isAuthenticated ? (
          <Component {...props}></Component>
        ) : (
          <Redirect to="/login"></Redirect>
        )
      }
    ></Route>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
