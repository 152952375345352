import axios from "axios";

import { apis } from "../../constants/API";
import {
  APPOINTMENTS_LOADING,
  LOAD_APPOINTMENTS,
  LOAD_SINGLE_APPOINTMENT,
} from "./types";

import { tokenConfig } from "./authAcrions";
import { toastr } from "react-redux-toastr";

export const createNewAppointment =
  (data, handleCancel) => (dispatch, getState) => {
    dispatch({
      type: APPOINTMENTS_LOADING,
    });
    axios
      .post(apis.preAppointment, data, {
        ...tokenConfig(getState),
      })
      .then((res) => {
        const {
          data: { data },
        } = res;
        toastr.success("Appointment Slot Created");
        handleCancel && handleCancel();
        const oldAppointments = getState().appointments?.appointments;
        let newAppointments = [data, ...oldAppointments];
        return dispatch({
          type: LOAD_APPOINTMENTS,
          payload: { result: newAppointments },
        });
      })
      .catch((err) => console.log(err));
  };

export const getMyAppointments = (query, page) => (dispatch, getState) => {
  dispatch({
    type: APPOINTMENTS_LOADING,
  });
  return axios
    .get(apis.getAppointments, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: query.limit ? query.limit : 30,
        page,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_APPOINTMENTS,
        payload: {
          result: data.result,
          thisPage: data.thisPage,
          allPages: data.allPages,
        },
      });
      return data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getFreeAppointments = (query, page) => (dispatch, getState) => {
  dispatch({
    type: APPOINTMENTS_LOADING,
  });
  return axios
    .get(apis.preAppointment, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: query.limit ? query.limit : 30,
        page,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_APPOINTMENTS,
        payload: {
          result: data.result,
          thisPage: data.thisPage,
          allPages: data.allPages,
        },
      });
      return data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const requestAppointment =
  (data, handleCancel) => (dispatch, getState) => {
    axios
      .post(apis.getAppointments, data, {
        ...tokenConfig(getState),
      })
      .then((res) => {
        toastr.success("Appointment Requested");
        let oldFreeSlots = getState()?.appointments?.appointments;
        let newFreeSlots = oldFreeSlots.filter(
          (s) => s.id !== parseInt(data.get("appointmentId"))
        );
        handleCancel && handleCancel();
        return dispatch({
          type: LOAD_APPOINTMENTS,
          payload: { result: newFreeSlots },
        });
      })
      .catch((err) => console.log(err));
  };

export const setAppointmentStatus =
  (data, fromNotification) => (dispatch, getState) => {
    axios
      .put(apis.setAppointmentStatus, data, {
        ...tokenConfig(getState),
      })
      .then((res) => {
        if (fromNotification) return;
        toastr.success("Appointment " + data.get("status"));
        let oldFreeSlots = getState()?.appointments?.appointments;
        let newFreeSlots = oldFreeSlots.filter(
          (s) => s.id !== parseInt(data.get("appointmentId"))
        );
        return dispatch({
          type: LOAD_APPOINTMENTS,
          payload: { result: newFreeSlots },
        });
      })
      .catch((err) => console.log(err));
  };
