import React from "react";
import { connect } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import { updateReply } from "../../../../store/actions/chatbotActions";

export const ReplyEditModal = ({
  setEditOpen,
  editOpen,
  reply,
  updateReply,
}) => {
  const [errors, setErrors] = React.useState({ value: "", valueAR: "" });
  const togggleModal = () => setEditOpen(!editOpen);

  const [values, setValues] = React.useState({
    id: reply?.id,
    value: reply?.value,
    valueAR: reply?.valueAR,
  });

  const { value, valueAR } = values;

  const onChange = (e) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
    let neededError = null;
    let chatLengthErrorValid = value.length > 1600 || value.length == 0;

    let words = [
      "%addToFav%",
      "%addToFavs%",
      "%createTask%",
      "%listFav%",
      "%listFavs%",
      "%name%",
      "%mobile%",
      "%%",
      "%password%",
      "%date%",
      "%todo%",
      "%listTasks%",
      "%group%",
    ];

    if (chatLengthErrorValid) {
      return setErrors({
        ...errors,
        [name]: `Reply characters must be betewwn 0 and 160`,
      });
    }
    words.every((item) => {
      if (!value.includes(item) && reply.value.includes(item)) {
        neededError = item;
      }
      return true;
    });

    if (neededError) {
      return setErrors({
        ...errors,
        [name]: `Reply must contain ${neededError}`,
      });
    }

    setErrors({ ...errors, [name]: "" });
  };

  return (
    <div>
      <Modal isOpen={editOpen} toggle={togggleModal}>
        <ModalHeader>
          <span className="text text-capitalize">Update {reply.key}</span>
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            height={10}
            invalid={errors.value}
            rows={10}
            name="value"
            onChange={onChange}
            value={values.value}
          />{" "}
          {
            <div className="row">
              <div className="col"></div>
              <div className="col">
                <span style={{ color: "red" }}>{errors.value}</span>
              </div>
            </div>
          }
          <Input
            type="textarea"
            height={10}
            invalid={errors.valueAR}
            rows={10}
            name="valueAR"
            onChange={onChange}
            value={values.valueAR}
          />
          {
            <div className="row">
              <div className="col"></div>
              <div className="col">
                <span style={{ color: "red" }}>{errors.valueAR}</span>
              </div>
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={
              errors.valueAR ||
              errors.value ||
              (reply?.value === value && reply?.valueAR === valueAR)
            }
            onClick={() => updateReply(reply.id, value, valueAR, togggleModal)}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { updateReply };

export default connect(mapStateToProps, mapDispatchToProps)(ReplyEditModal);
