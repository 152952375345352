import {
  CATS_LOADING,
  CATS_FAIL,
  LOAD_CATS,
  LOAD_SINGLE_CAT,
} from "../actions/types";

const initialState = {
  categories: [],
  error: false,
  loading: false,
  thisPage: 1,
  allPages: 1,
  totalCount: 1,
  signleCategory: {},
};

export default function catReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_CATS:
      return {
        ...state,
        categories: payload.result,
        thisPage: payload.thisPage,
        allPages: payload.allPages,
        error: false,
        loading: false,
      };
    case CATS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case CATS_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case LOAD_SINGLE_CAT:
      return {
        ...state,
        error: false,
        loading: false,
        signleCategory: payload,
      };
    default:
      return state;
  }
}
