import {
  GET_CHAT_WORDS,
  UPDATE_CHAT_WORDS,
  CHAT_WORDS_ERROR,
  GET_CHAT_WORDS_LOADING,
} from "../actions/types";

const initialState = {
  chatwords: {},
  loading: false,
  error: false,
};

export default function chatwords(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CHAT_WORDS:
      return {
        ...state,
        chatwords: payload,
        loading: false,
        error: false,
      };
    case UPDATE_CHAT_WORDS:
      return {
        ...state,
        chatwords: payload,
        loading: false,
        error: false,
      };
    case GET_CHAT_WORDS_LOADING:
      return {
        ...state,
        chatwords: {},
        loading: true,
        error: false,
      };
    case CHAT_WORDS_ERROR:
      return {
        ...state,
        chatwords: {},
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
