import React from "react";
import { connect } from "react-redux";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import { getCategories } from "../../../store/actions/categoryActions";
import SingleCategory from "../SingleCategory";
import Loader from "../../../components/Loader";
import emptyicon from "../../../Assets/Icons/empty-white-box.png"
export const CardsView = ({
  categories,
  getCategories,
  thisPage,
  allPages,
  loading,
}) => {
  const onClick = (event) => {
    switch (event.target.textContent) {
      case "»":
      case "»Last":
      case "Last":
        getCategories({ limit: 10 }, allPages);
        break;
      case "«":
      case "«First":
      case "First":
        getCategories({ limit: 10 }, 1);
        break;
      case "›":
      case "›Next":
      case "Next":
        getCategories({ limit: 10 }, thisPage + 1);
        break;
      case "‹":
      case "‹Previous":
      case "Previous":
        getCategories({ limit: 10 }, thisPage - 1);
        break;
      default:
        getCategories({ limit: 10 }, Number(event.target.textContent));
        break;
    }
  };

  React.useEffect(() => {
    getCategories({ limit: 10 }, 1);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="col p-2">
      {!loading && categories.length === 0 && (
        <div>
          <div className="col text-center">
            <img
              src={emptyicon}
              alt="Empty"
              style={{ width: 100, height: 100 }}
            />
          </div>
          <div
            className="col text-center"
            style={{
              fontSize: "40px",
              color: "var(--primaryColor)",
            }}
          >
            No Categories
          </div>
        </div>
      )}
      <div className="d-flex flex-wrap">
        {!loading && categories.length !== 0 &&
          categories.map((task, i) => {
            return <SingleCategory category={task} key={i} page={thisPage} />;
          })}
      </div>
      {allPages > 1 && (
        <div className="col d-flex justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className="pag-link"
                >
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index}
                      className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state?.category?.categories,
  thisPage: state?.category?.thisPage,
  allPages: state?.category?.allPages,
  loading: state?.category?.loading
});

const mapDispatchToProps = {
  getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsView);
