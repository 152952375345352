import React from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { getTags } from "../../../store/actions/tagsActions";
import { Tag } from "antd";
import { AiFillTags } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import { getFavourites } from "../../../store/actions/favouritesActions";

export const Tags = ({ tags, getTags, count, thisPage, allPages, filter }) => {
  const fetchData = () => {
    getTags({ limit: 40 }, thisPage + 1);
  };

  React.useEffect(() => {
    getTags({ limit: 40 }, 1);
  }, []);

  const [selectedTags, setSelectedTags] = React.useState([]);

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };

  const clearTags = () => {
    setSelectedTags([]);
  };

  React.useEffect(() => {
    filter(selectedTags);
  }, [selectedTags]);

  return (
    <div className="task-item pb-5">
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto" style={{ fontSize: "x-large" }}>
            <AiFillTags /> Tags
            <MdClear
              size="15"
              color="red"
              style={{ cursor: "pointer" }}
              onClick={clearTags}
            />
          </h2>
        </div>
      </div>
      <div className=" infinite_scroller">
        <InfiniteScroll
          height={250}
          className="d-flex flex-wrap"
          dataLength={tags.length}
          next={fetchData}
          hasMore={allPages > thisPage}
          loader={<p>Loading...</p>}
        >
          {tags &&
            tags?.map((tag, idx) => {
              let size = 12;
              let maxSize = 40;
              const factor = 2.5;
              if (tag.TagCount * factor > size) {
                size = tag.TagCount * factor;
              }
              if (tag.TagCount * factor > maxSize) {
                size = maxSize;
              }
              return (
                <Tag.CheckableTag
                  key={idx}
                  checked={selectedTags.indexOf(tag.id) > -1}
                  onChange={(checked) => handleChange(tag.id, checked)}
                  className="m-1 p-1"
                  style={{ fontSize: size, wordBreak: "break-word" }}
                >
                  {tag.tag}
                </Tag.CheckableTag>
              );
            })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tags: state?.tags?.tags,
  thisPage: state?.tags?.thisPage,
  allPages: state?.tags?.allPages,
  count: state?.tags?.count,
  thisFavouritePage: state?.favourites?.thisPage,
});

const mapDispatchToProps = { getTags, getFavourites };

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
