import {
    TEMPLATE_CREATED,
    TEMPLATE_CREATE_FAILED,
    TEMPLATES_LOADING,
    TEMPLATES_LOADED,
} from "../actions/types";

const initialState = {
    templates: [],
    error: false,
    loading: false,
    thisPage: 1,
    allPages: 1,
    totalCount: 1,
    singleTemplate: {},
};

export default function templateReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TEMPLATE_CREATED:
            return {
                ...state,
                templates: payload.result,
                error: false,
                loading: false,
            };
        case TEMPLATES_LOADING:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case TEMPLATES_LOADED:
            return {
                ...state,
                templates: payload.result,
                thisPage: payload.thisPage,
                allPages: payload.allPages,
                error: false,
                loading: false,
            };
        case TEMPLATE_CREATE_FAILED:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return {
                ...state
            }

    }
}
