import React from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Button } from "reactstrap";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { RiLockPasswordLine } from "react-icons/ri";
import { GrMail } from "react-icons/gr";
import { sendPasswordLink } from "../../store/actions/authAcrions";
import validator from "validator";
import Countdown from "react-countdown";
import moment from "moment";

export const ForgotPassword = ({ sendPasswordLink }) => {
  const [email, setEmail] = React.useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
    console.log(email);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    sendPasswordLink({ email }, setSubmitted);
  };

  const renderer = ({ seconds, completed, started, api }) => {
    if (completed) {
      return (
        <p
          style={{
            color: "var(--mainWhite)",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={(e) => {
            onSubmit(e);
            api.start();
          }}
        >
          Resend Link
        </p>
      );
    } else {
      if (!started) {
        api.start();
      }
      return <span>{seconds}</span>;
    }
  };

  const [submitted, setSubmit] = React.useState(false);
  const [date, setDate] = React.useState();

  const setSubmitted = (r) => {
    setDate(moment() + 30000);
    setSubmit(r);
  };

  return (
    <div className="login-page">
      {!submitted && (
        <Form className="login-form col-md-5" onSubmit={onSubmit}>
          <h1 style={{ color: "var(--mainWhite)" }}>
            <RiLockPasswordLine /> Forgot Password
          </h1>
          <FormGroup>
            <Input
              prefix={<GrMail />}
              className="mt-3 login-input"
              name="email"
              placeholder="User Email"
              value={email}
              invalid={email && !validator.isEmail(email)}
              onChange={handleChange}
            />
            {email && !validator.isEmail(email) && (
              <p className="invalid-entry-message">
                Please enter a valid Email
              </p>
            )}
          </FormGroup>
          <FormGroup className="row justify-content-center">
            <div className="col-md-9">
              <div className="row">
                <Button
                  className="sec-button m-2"
                  type="submit"
                  disabled={!validator.isEmail(email)}
                >
                  Send Request
                </Button>
              </div>
              <div className="row">
                <Button className="sec-button m-2" tag={Link} to="/login">
                  Back
                </Button>
              </div>
            </div>
          </FormGroup>
          <FormGroup className="row justify-content-center">
            <div className="col-md-12">
              <h6 className="text-end mt-2">
                <Link style={{ color: "white" }} to="/phone-reset">
                  Reset using SMS
                </Link>
              </h6>
            </div>
          </FormGroup>
        </Form>
      )}
      {submitted && (
        <div className="login-form col-md-5">
          <h1 style={{ color: "var(--mainWhite)" }}>
            Forgot password link sent
          </h1>
          <div className="d-flex flex-wrap justify-content-between">
            <p style={{ color: "var(--mainWhite)" }}>
              Please check the email you provided
            </p>
            <Countdown
              date={date}
              autoStart={false}
              onStart={() => console.log("started")}
              renderer={renderer}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { sendPasswordLink };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
