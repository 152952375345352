import React from "react";
import { connect } from "react-redux";
import { getContactList } from "../../store/actions/contactActions";
import ContactItem from "./ContactItem";
import { RiContactsBookFill, RiUserAddLine } from "react-icons/ri";
import QuickContactForm from "../../components/Navbar/Items/QuickContactForm";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import emptyicon from "../../Assets/Icons/empty-white-box.png";

export const ContactList = ({ getContactList, contacts, loading }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  let { t } = useTranslation(["contacts"]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  React.useEffect(() => {
    getContactList();
  }, []);

  if (loading) return <div className="loader" />;

  return (
    <div className="main-page container" style={{ minHeight: "100vh" }}>
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            <RiContactsBookFill />
            {t("contacts")}
          </h2>
        </div>
        <div>
          <Button
            className="text-center main-button"
            style={{ cursor: "pointer" }}
            onClick={showModal}
            id="close"
          >
            <RiUserAddLine size="25" color="white" />
          </Button>
        </div>
      </div>
      <QuickContactForm
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        t={t}
      />
      <div className="row">
        {!loading && contacts.length === 0 && (
          <div>
            <div className="col text-center">
              <img
                src={emptyicon}
                alt="Empty"
                style={{ width: 100, height: 100 }}
              />
            </div>
            <div
              className="col text-center"
              style={{
                fontSize: "40px",
                color: "var(--primaryColor)",
              }}
            >
              No Contacts
            </div>
          </div>
        )}
        {!loading &&
          contacts.length !== 0 &&
          contacts.map((contact, i) => {
            return <ContactItem contact={contact} i={i} t={t} />;
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contacts: state.contacts.contacts,
  loading: state.contacts.loading,
});

const mapDispatchToProps = {
  getContactList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
