import React from "react";
import { connect } from "react-redux";
import { getMyAppointments } from "../../../store/actions/appointmentsActions";
import SingleAppointment from "../SingleAppointment";
import Loader from "../../../components/Loader";
import {
  Pagination,
  PaginationLink,
  PaginationItem,
  Row,
  Col,
  Label,
} from "reactstrap";
import { Select } from "antd";
import { VscLoading } from "react-icons/vsc";
import emptyicon from "../../../Assets/Icons/empty-white-box.png";

export const PaginateAppointments = ({
  allPages,
  thisPage,
  appointments,
  history,
  tab,
  loading,
  getMyAppointments,
}) => {
  const [filters, setFilters] = React.useState({
    type: tab === 1 ? "invited" : "sent",
    status: "pending",
  });

  const onClick = (event) => {
    console.log(event.target.textContent);
    switch (event.target.textContent) {
      case "»":
      case "»Last":
      case "Last":
        getMyAppointments({ limit: 10, ...filters }, allPages);
        break;
      case "«":
      case "«First":
      case "First":
        getMyAppointments({ limit: 10, ...filters }, 1);
        break;
      case "›":
      case "›Next":
      case "Next":
        getMyAppointments({ limit: 10, ...filters }, thisPage + 1);
        break;
      case "‹":
      case "‹Previous":
      case "Previous":
        getMyAppointments({ limit: 10, ...filters }, thisPage - 1);
        break;
      default:
        getMyAppointments(
          { limit: 10, ...filters },
          Number(event.target.textContent)
        );
        break;
    }
  };

  const handleFilter = (e) => {
    setFilters({ ...filters, status: e });
    getMyAppointments(
      {
        limit: 10,
        ...filters,
        status: e,
      },
      1
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="col p-2">
      {loading && (
        <div
          className="col text-center rotator"
          style={{
            fontSize: "40px",
            marginTop: "20%",
            color: "var(--primaryColor)",
          }}
        >
          <VscLoading />
        </div>
      )}
      <Row className="d-flex justify-content-center">
        <Col md={5} className="m-1">
          <Label>Appointment Status</Label>
          <Select
            className="task-input"
            defaultValue={filters.status}
            onChange={handleFilter}
            help="Appointment Status"
            style={{ width: "100%" }}
          >
            <Select.Option value={"pending"}>Pending</Select.Option>
            <Select.Option value={"accepted"}>Accepted</Select.Option>
            <Select.Option value={"declined"}>Declined</Select.Option>
          </Select>
        </Col>
      </Row>
      {!loading && appointments.length === 0 && (
        <div>
          <div className="col text-center">
            <img
              src={emptyicon}
              alt="Empty"
              style={{ width: 100, height: 100 }}
            />
          </div>
          <div
            className="col text-center"
            style={{
              fontSize: "40px",
              color: "var(--primaryColor)",
            }}
          >
            No Appointments
          </div>
        </div>
      )}
      <Row className="d-flex flex-wrap">
        {appointments &&
          appointments.map((appointment, i) => {
            return (
              <SingleAppointment
                appointment={appointment}
                key={i}
                filters={filters}
                history={history}
              />
            );
          })}
      </Row>

      {allPages > 1 && (
        <div className="col d-flex justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className="pag-link"
                >
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index}
                      className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appointments: state?.appointments?.appointments,
  loading: state?.appointments?.loading,
  thisPage: state?.appointments?.thisPage,
  allPages: state?.appointments?.allPages,
});

const mapDispatchToProps = {
  getMyAppointments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginateAppointments);
