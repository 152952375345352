import axios from "axios";

import { apis } from "../../constants/API";
import {
    TEMPLATE_CREATED,
    TEMPLATE_CREATE_FAILED,
    TEMPLATES_LOADING,
    TEMPLATES_LOADED,
} from "./types";

import { tokenConfig } from "./authAcrions";
import { toastr } from "react-redux-toastr";

export const createTemplate = (data) => (dispatch, getState) => {
    dispatch({
        type: TEMPLATES_LOADING,
    })
    axios
        .post(apis.createTemplate, data, {
            ...tokenConfig(getState),
        })
        .then((res) => {
            const {
                data: { data },
            } = res;
            const oldTemplates = getState().templates?.templates;
            let newTemplates = [data, ...oldTemplates];
            toastr.success("New Template Created");
            return dispatch({
                type: TEMPLATE_CREATED,
                payload: { result: newTemplates },
            })
        })
        .catch((err) => {
            console.log(err);
            toastr.error(err.message);
            return dispatch({
                type: TEMPLATE_CREATE_FAILED,
            })
        })
};

export const getTemplates = (query, page) => (dispatch, getState) => {
    dispatch({
        type: TEMPLATES_LOADING,
    });
    return axios
        .get(apis.getTemplates, {
            ...tokenConfig(getState),
            params: {
                ...query,
                limit: query.limit ? query.limit : 50,
                page,
            },
        })
        .then((res) => {
            const {
                data: { data },
            } = res;
            dispatch({
                type: TEMPLATES_LOADED,
                payload: { result: data.result, thisPage: data.thisPage, allPages: data.allPages },
                
            });
            console.log("hey")
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
};

export const deleteTemplate = (templateId) => (dispatch, getState) => {
    axios
      .delete(apis.deleteTemplate, {
        ...tokenConfig(getState),
        params: {
            templateId,
        },
      })
      .then((res) => {
        const { message } = res.data;
        const oldTemps = getState().templates?.templates;
        let newTemps = oldTemps.filter((t) => t.id != templateId);
        toastr.success("Template Deleted", message);
        return dispatch({
          type: TEMPLATES_LOADED,
          payload: { result: newTemps },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };