import axios from "axios";

import { apis } from "../../constants/API";
import {
  TASKS_FAIL,
  TASKS_LOADING,
  LOAD_TASKS,
  LOAD_SINGLE_TASK,
  LOAD_ASSIGNEES,
} from "./types";

import { tokenConfig } from "./authAcrions";
import { toastr } from "react-redux-toastr";

export const createNewTask = (data, handleCancel) => (dispatch, getState) => {
  axios
    .post(apis.listTasks, data, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      toastr.success("Task Created");
      if (handleCancel) {
        handleCancel();
      }
      const oldTasks = getState().task?.tasks;
      let newTasks = [data, ...oldTasks];
      return dispatch({
        type: LOAD_TASKS,
        payload: { result: newTasks },
      });
    })
    .catch((err) => console.log(err));
};

export const getTasks = (query, page, notState) => (dispatch, getState) => {
  dispatch({
    type: TASKS_LOADING,
  });
  return axios
    .get(apis.listTasks, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: query.limit ? query.limit : 30,
        page,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;

      if (!notState) {
        dispatch({
          type: LOAD_TASKS,
          payload: {
            result: data.result,
            thisPage: data.thisPage,
            allPages: data.allPages,
          },
        });
      }

      return data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getSingleTask = (query) => (dispatch, getState) => {
  if (!query.userType) {
    dispatch({
      type: TASKS_LOADING,
    });
  }
  return axios
    .get(apis.listTasks, {
      ...tokenConfig(getState),
      params: {
        taskId: query.taskId,
        usersType: query.userType ? query.userType : null,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_SINGLE_TASK,
        payload: data,
      });

      return data;
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: TASKS_FAIL,
      });
    });
};

let deleteFromFireBase = async (entityId, entityType) => {
  /*   let messages = await firebase
    .database()
    .ref("/messages")
    .orderByChild(`type/${entityType}`)
    .limitToLast(5).once('value')

  console.log("Got Task Messages", messages.val()); */
};

export const deleteTask = (taskId) => (dispatch, getState) => {
  dispatch({
    type: TASKS_LOADING,
  });

  deleteFromFireBase(taskId, "task");

  axios
    .delete(apis.deleteTask, {
      ...tokenConfig(getState),
      params: {
        taskId,
      },
    })
    .then((res) => {
      const oldTasks = getState().task?.tasks;
      let newTasks;
      if (Array.isArray(taskId)) {
        let i;
        for (i = 0; i < taskId.length; i++) {
          if (i === 0) newTasks = oldTasks.filter((t) => t.id !== taskId[i]);
          else newTasks = newTasks.filter((t) => t.id !== taskId[i]);
        }
      } else {
        newTasks = oldTasks.filter((t) => t.id !== taskId);
      }
      toastr.success("Task deleted");
      return dispatch({
        type: LOAD_TASKS,
        payload: { result: newTasks },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateTaskStatus =
  (data, toggler, fromNotification) => (dispatch, getState) => {
    axios
      .put(apis.updateStatus, data, {
        ...tokenConfig(getState),
      })
      .then((res) => {
        let { data } = res.data;
        if (fromNotification) return;
        let singleTask = getState().task?.singleTask;
        let curr = singleTask.task?.assignees[0]?.taskStatus.find(
          (status) => status.id === parseInt(data.get("taskStatusId"))
        );
        singleTask.task.assignees[0].taskStatus =
          singleTask?.task?.assignees[0]?.taskStatus?.map((status) => {
            if (status.id === curr.id) {
              status = data;
            }
            return status;
          });

        dispatch({
          type: LOAD_SINGLE_TASK,
          payload: singleTask,
        });
        if (toggler) {
          toggler();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const updateTaskPercent =
  (taskStatusId, percentage, toggler) => (dispatch, getState) => {
    axios
      .put(
        apis.updateStatus,
        {
          taskStatusId: taskStatusId,
          percentage,
        },
        {
          ...tokenConfig(getState),
        }
      )
      .then((res) => {
        let { data } = res.data;
        let currentTasks = getState().task?.tasks;
        let singleTask = getState().task?.singleTask;

        let curr = singleTask.task.assignees[0].taskStatus.find(
          (status) => status.id === taskStatusId
        );
        singleTask.task.assignees[0].taskStatus =
          singleTask.task.assignees[0].taskStatus.map((status) => {
            if (status.id === curr.id) {
              status = data;
            }
            return status;
          });
        console.log(singleTask);
        dispatch({
          type: LOAD_SINGLE_TASK,
          payload: singleTask,
        });
        if (toggler) {
          toggler();
        }
        currentTasks = currentTasks.map((t) => {
          if (t.id == data.assignee.taskId) {
            t.assignees[0].taskStatus = data;
            return t;
          } else {
            return t;
          }
        });
        return dispatch({
          type: LOAD_TASKS,
          payload: { result: currentTasks },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const removeAssignee = (taskId, asigneeId) => (dispatch, getState) => {
  axios
    .put(
      apis.updateAssignee,
      {
        taskId,
        deletingAssignees: [asigneeId],
      },
      {
        ...tokenConfig(getState),
      }
    )
    .then((res) => {
      let currentTasks = getState().task?.tasks;
      currentTasks = currentTasks.map((t) => {
        if (t.id === taskId) {
          t = res.data.data;
          return t;
        } else {
          return t;
        }
      });
      toastr.success("Assignee removed");
      return dispatch({
        type: LOAD_TASKS,
        payload: { result: currentTasks },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editTask = (taskData, handleCancel) => (dispatch, getState) => {
  dispatch({
    type: TASKS_LOADING,
  });
  axios
    .put(apis.listTasks, taskData, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      let { data } = res.data;
      let currentTasks = getState().task?.tasks;
      currentTasks = currentTasks.map((t) => {
        if (t.id === data.id) {
          t = data;
          return t;
        } else {
          return t;
        }
      });
      toastr.success("Task updated");
      handleCancel && handleCancel();
      return dispatch({
        type: LOAD_TASKS,
        payload: { result: currentTasks },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addAssignees = (data) => (dispatch, getState) => {
  dispatch({
    type: TASKS_LOADING,
  });
  axios
    .put(apis.updateAssignee, data, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      toastr.success("Task Updated");
      const oldTasks = getState().task?.tasks;
      let newTasks = [data, ...oldTasks];
      return dispatch({
        type: LOAD_TASKS,
        payload: { result: newTasks },
      });
    })
    .catch((err) => console.log(err));
};

export const getTaskAssignees = (query, page) => (dispatch, getState) => {
  return axios
    .get(apis.listAssignees, {
      ...tokenConfig(getState),
      params: {
        taskId: query.taskId,
        usersType: query.userType ? query.userType : "users",
        limit: query.limit ? query.limit : 10,
        page,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_ASSIGNEES,
        payload: data,
      });

      return data;
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: TASKS_FAIL,
      });
    });
};

export const categorize = (taskId, categoryId) => (dispatch, getState) => {
  axios
    .put(
      apis.viewMyCategories,
      {
        taskIds: [taskId],
        categoryId,
      },
      {
        ...tokenConfig(getState),
      }
    )
    .then((res) => {
      const {
        data: { data },
      } = res;
      toastr.success("Task added to category");
      let currentTasks = getState().task?.tasks;
      currentTasks = currentTasks.map((t) => {
        if (t.id === data.id) {
          t = data;
          return t;
        } else {
          return t;
        }
      });
      return dispatch({
        type: LOAD_TASKS,
        payload: { result: currentTasks },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
