import { CHANGE_LANG, GET_LANG } from "../actions/types";

const initialState = {
  t: null,
  lang: "en",
};

export default function langReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LANG:
      return {
        ...state,
        t: payload,
      };
    case CHANGE_LANG:
      return {
        ...state,
        lang: payload,
      };
    default:
      return state;
  }
}
