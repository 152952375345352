import React from "react";
import { connect } from "react-redux";
import { BiTask } from "react-icons/bi";
import { FcTodoList } from "react-icons/fc";
import HomeTasks from "./HomeTasks";
import TodoList from "./TodoList";
import { Button } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import QuickTodoForm from "../../components/Navbar/Items/QuickTodoForm";
import { useTranslation } from "react-i18next";
import SearchTags from "./SearchTags";
import { getContactListUsersOnly } from "../../store/actions/contactActions";

export const Home = ({ history, user, contacts, getContactListUsersOnly }) => {
  let { t } = useTranslation(["tasks"]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const setModalVisible = () => {
    setIsModalVisible(true);
  };

  React.useEffect(() => {
    getContactListUsersOnly();
  }, []);

  return (
    <div className="container main-page" style={{ height: "100vh" }}>
      <QuickTodoForm
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
      />

      {/*  <SearchTags /> */}
      <div className="row justify-content-center">
        <div className="col-sm-5 home-tasks py-4 m-5 position-relative">
          <p className="task-text">
            <span className="ml-5 pl-5">
              <span>
                <FcTodoList />
              </span>
              <span> {t("todo")} </span>
            </span>
          </p>
          <TodoList />
          <Button
            className="rounded-button position-absolute top-100 start-50 translate-middle"
            onClick={setModalVisible}
          >
            <AiOutlinePlus />
          </Button>
        </div>
        <div className="col-sm-5 home-tasks py-4 m-5">
          <p className="task-text">
            <span>
              <BiTask />
            </span>
            <span className="mr-5"> {t("tasks")} </span>
          </p>
          <HomeTasks />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  contacts: state.contacts.contacts,
});

const mapDispatchToProps = { getContactListUsersOnly };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
