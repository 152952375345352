import {
  TASKS_FAIL,
  TASKS_LOADING,
  LOAD_TASKS,
  LOAD_SINGLE_TASK,
  LOAD_ASSIGNEES,
} from "../actions/types";

const initialState = {
  tasks: [],
  error: false,
  loading: false,
  thisPage: 1,
  allPages: 1,
  totalCount: 1,
  singleTask: {},
  taskAssignees: {},
};

export default function taskReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_TASKS:
      return {
        ...state,
        tasks: payload.result,
        thisPage: payload.thisPage,
        allPages: payload.allPages,
        error: false,
        loading: false,
      };
    case TASKS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TASKS_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case LOAD_SINGLE_TASK:
      return {
        ...state,
        error: false,
        loading: false,
        singleTask: payload,
      };
    case LOAD_ASSIGNEES:
      return {...state,
        error: false,
        loading: false,
        taskAssignees: payload};
    default:
      return state;
  }
}
