import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Form, Input, Upload } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { editContact } from "../../../store/actions/contactActions";
import ImgCrop from "antd-img-crop";

export const EditContact = ({
  isModalVisible,
  handleCancel,
  editContact,
  contact,
}) => {
  let { t } = useTranslation(["contacts"]);
  const [contactData, setContactData] = React.useState({
    name: "",
  });

  React.useEffect(() => {
    setContactData({
      name: contact?.name,
    });
  }, [contact]);

  const onChange = (e) => {
    let { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
  };

  const fileList = [
    {
      uid: "1",
      status: "done",
      url: contact?.image ? contact?.image : contact?.contact?.image ? contact?.contact?.image:null,
    },
  ];

  let { name } = contactData;
  const [image, setImage] = React.useState(fileList[0]);

  const onImageChange = (e) => {
    setImage(e.file);
  };

  const handleEdit = () => {
    let formData = new FormData();
    formData.append("contactId", contact?.id);
    name && formData.append("name", name);
    image && formData.append("image", image.originFileObj);
    editContact(formData);
    handleCancel();
  };

  return (
    <Modal
      closable={false}
      title={t("edit")}
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={handleEdit}
      okButtonProps={{
        className: "main-button",
      }}
      okText={t("ok_edit")}
      cancelButtonProps={{ className: "main-button-cancel" }}
      cancelText={t("cancel")}
    >
      <Form>
        <Form.Item>
          <ImgCrop rotate>
            <Upload
              className="d-flex justify-content-center"
              listType="picture-card"
              onChange={onImageChange}
              multiple={false}
              maxCount={1}
              defaultFileList={fileList}
            >
              {(!image ||
                (image && image.status === "removed") ||
                !fileList[0].url) &&
                "Upload Contact Image"}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item name="name">
          <Input
            prefix={<FaUserAlt />}
            defaultValue={name}
            onChange={onChange}
            name="name"
            value={name}
            type="textarea"
            className="task-input"
            placeholder={t("")}
            label="Contact Name"
          />
        </Form.Item>
        {/* <Form.Item
          prefix={<FaPhoneAlt />}
          name="mobile"
          rules={[
            { required: true, message: t("") },
            { len: 12, message: t("") },
          ]}
        >
          <Input.TextArea
            defaultValue={mobile}
            onChange={onChange}
            name="mobile"
            value={mobile}
            className="task-input"
            placeholder={t("")}
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { editContact };

export default connect(mapStateToProps, mapDispatchToProps)(EditContact);
