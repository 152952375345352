import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button } from "antd";
import { FaUserAlt, FaPhoneAlt } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { createNewUser } from "../../../store/actions/usersActions";

export const CreateUser = ({ createNewUser, t }) => {
  const onFinish = (values) => {
    createNewUser(values);
  };

  return (
    <div>
      <Form
        name="control-hooks"
        onFinish={onFinish}
        className="row my-4 py-4 justify-content-center task_form px-4"
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: t("form.required.username") }]}
          className="col-md-8 mx-2 my-2"
        >
          <Input
            type="text"
            prefix={<FaUserAlt />}
            className="task-input"
            placeholder={` ${t("form.username")}`}
          />
        </Form.Item>

        <Form.Item
          name="mobile"
          rules={[
            { required: true, message: t("form.required.mobile") },
            { len: 12, message: t("form.required.mobile") },
          ]}
          className="col-md-8 mx-2 my-2"
        >
          <Input
            type="text"
            prefix={<FaPhoneAlt />}
            className="task-input"
            placeholder={` ${t("form.mobile")}`}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: t("form.required.password") },
            { min: 6, message: t("form.required.password") },
          ]}
          className="col-md-8 mx-2 my-2"
        >
          <Input.Password
            prefix={<RiLockPasswordFill />}
            className="task-input"
            placeholder={` ${t("form.password")}`}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="sec-button col-md-8 mx-2 my-2"
        >
          {t("form.submit")}
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { createNewUser };

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
