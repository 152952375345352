import React from 'react'
import { connect } from 'react-redux'
import { AiFillDelete } from 'react-icons/ai'
import { Modal } from "antd"
import { deleteTemplate } from '../../../store/actions/templateActions'
import { useTranslation } from 'react-i18next'
import { FileMedia } from '../../../components/FileMedia/FileMedia'

export const SingleTemplate = ({ template, deleteTemplate }) => {
    let {t: t2} = useTranslation(["delete"]);
    const [isModalVisible, setIsModalVisible] = React.useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        deleteTemplate(template.id)
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <div className="template-box m-2 p-2">
            <Modal title={t2("confirm")} visible={isModalVisible} closable={false}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ className: "main-button" }}
                okText={t2("ok")}
                cancelText={t2("cancel")}
                cancelButtonProps={{ className: "main-button-cancel" }}>
                <p>{t2("sure")} "{template.title}"</p>
            </Modal>
            <div className="d-flex my-4 justify-content-between">
                <div>
                    <h2 className="template-head-text mx-auto">
                        {template?.title}
                    </h2>
                </div>
                <div>
                    <AiFillDelete
                        className="mx-2"
                        onClick={showModal}
                        size="20"
                        style={{
                            cursor: "pointer",
                            color: "red",
                        }}
                    />
                </div>
            </div>
            <div className="template-desc-box">
                <p className="template-text">
                    {template.description}
                </p>
            </div>
            <div className="d-flex flex-wrap justify-content-center media-box">
                {
                    template?.medias?.length !== 0 &&
                    template?.medias.map((file, i) => <div className="m-1" key={i}><FileMedia file={file} /></div>)
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    deleteTemplate
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleTemplate)
