import React from "react";
import { connect } from "react-redux";
import { Skeleton, Card, Avatar } from "antd";
import { useHistory } from "react-router-dom";

export const SingleUser = ({ user, loading }) => {
  let history = useHistory()

  return (
    <Card
      style={{ width: 300, marginTop: 16 }}
      className="col-md-5 col-sm-4 mx-1"
    >
      <Skeleton loading={loading} avatar active>
        <Card.Meta
          avatar={<Avatar src={user.image} onClick={()=>history.push("/users/"+user.id)} style={{cursor:"pointer"}}/>}
          title={user.name}
          description={user.mobile}
        />
      </Skeleton>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loading: state.users.loading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SingleUser);
