import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from "antd";
import { getTaskCategories } from "../../../store/actions/categoryActions";
import { VscLoading } from "react-icons/vsc";
export const ViewCategory = ({
  visible,
  handleCancel,
  category,
  getTaskCategories,
  tasks,
  loading,
  isCard,
}) => {
  React.useEffect(() => {
    !isCard && getTaskCategories({ categoryId: category.id });
  }, [category]);
  return (
    <Modal
      title={category.title}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleCancel}
      okButtonProps={{ className: "main-button" }}
      cancelButtonProps={{ hidden: true }}
    >
      {loading && (
        <div
          className="col text-center rotator"
          style={{
            fontSize: "40px",
            color: "var(--primaryColor)",
          }}
        >
          <VscLoading />
        </div>
      )}
      {!loading &&
        tasks &&
        tasks.map((task, idx) => {
          return <Button key={idx}>{task.title}</Button>;
        })}
      {!loading && tasks && tasks.length === 0 && (
        <div
          className="col text-center"
          style={{
            fontSize: "40px",
            color: "var(--primaryColor)",
          }}
        >
          No Tasks
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  tasks: state?.task?.tasks,
  loading: state?.task?.loading,
});

const mapDispatchToProps = {
  getTaskCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCategory);
