import React from "react";
import { connect } from "react-redux";
import { Skeleton, Card, Avatar } from "antd";
import { CardBody } from "reactstrap";
import { FaUserCircle } from "react-icons/fa";
import { BiCalendarEvent, BiTask } from "react-icons/bi";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment";
import "moment/locale/ar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setAppointmentStatus } from "../../../store/actions/appointmentsActions";
import { updateTaskStatus } from "../../../store/actions/taskActions";
import { updateEventStatus } from "../../../store/actions/eventActions";

export const SingleNotification = ({
  notification,
  loading,
  setAppointmentStatus,
  updateTaskStatus,
  updateEventStatus,
  actionNeeded,
  onAction
}) => {
  let { t, i18n } = useTranslation(["notifications"]);
  let history = useHistory();

  const handleNavigate = () => {
    if (notification?.actionType !== "deleted") {
      switch (notification?.moduleName) {
        case "event":
          history.push("/events/" + notification?.entityId);
          break;
        case "task":
          history.push("/tasks/" + notification?.entityId);
          break;
        case "appointment":
        default:
          break;
      }
    }
  };

  const handleAction = (r) => {
    switch (notification?.moduleName) {
      case "event": updateEventStatus(notification?.entityId , r===1 ? "1":"2");
        break;
      case "task": updateTaskStatus({taskId: notification?.entityId , statusId: r===1 ? 2:4});
        break;
      case "appointment": setAppointmentStatus({appointmentId: notification?.entityId , status: r===1 ? "accepted":"declined"})
        break;
      default:
        break;
    }
    onAction();
  };

  return (
    <div className="p-2 col-md-6 justify-content-center">
      <Card bordered={false} className="notification">
        <Skeleton loading={loading} avatar active>
          <Card.Meta
            avatar={
              <Avatar src={notification.image}>
                <FaUserCircle size="38" />
              </Avatar>
            }
            onClick={handleNavigate}
            title={notification.actorName}
            description={notification.message}
          />
        </Skeleton>
        <CardBody className="p-0">
          {actionNeeded && (
            <div className="col p-1 text-end">
              <div onClick={() => handleAction(1)}><AiOutlineCheckCircle color="green"/> Accept</div>
              <div onClick={() => handleAction(2)}><AiOutlineCloseCircle color="red"/> Decline</div>
            </div>
          )}
          <div className="col p-1 text-end">
            <p>
              {(notification?.moduleId === 3 && (
                <BiCalendarEvent color="orange" />
              )) ||
                (notification?.moduleId === 1 && <BiTask color="green" />)}{" "}
              {moment(notification?.createdAt).locale(i18n.language).fromNow()}
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.notifications?.loading,
});

const mapDispatchToProps = {
  setAppointmentStatus,
  updateTaskStatus,
  updateEventStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleNotification);
