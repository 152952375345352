import React from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, Collapse, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Avatar, Card, Button, Badge, Tooltip, Image } from "antd";
import { Link } from "react-router-dom";
import { BiTask } from "react-icons/bi";
import { AiFillHome, AiOutlineSchedule } from "react-icons/ai";
import {
  BsFolderFill,
  BsChatQuoteFill,
  BsCalendar,
  BsStar,
} from "react-icons/bs";
import { logout } from "../../store/actions/authAcrions";
import { RiContactsBookFill, RiUserAddLine } from "react-icons/ri";
import { AiFillMessage } from "react-icons/ai";
import { FiUsers, FiEdit3 } from "react-icons/fi";
import { CgTemplate } from "react-icons/cg";
import { MdPlaylistAdd, MdEvent } from "react-icons/md";
import QuickTodoForm from "./Items/QuickTodoForm";
import QuickContactForm from "./Items/QuickContactForm";
import ChangeImage from "./Items/ChangeImage";
import { getTasks } from "../../store/actions/taskActions";
import { getTodos } from "../../store/actions/todoActions";
import { getContactList } from "../../store/actions/contactActions";
import { getCategories } from "../../store/actions/categoryActions";
import { getEvents } from "../../store/actions/eventActions";
import { getCalendar } from "../../store/actions/calendarActions";
import { getChatbotReplies } from "../../store/actions/chatbotActions";
import { getTemplates } from "../../store/actions/templateActions";
import { getFavourites } from "../../store/actions/favouritesActions";

export const Navbar = ({
  user,
  getTasks,
  getTodos,
  getContactList,
  getEvents,
  getCalendar,
  getChatbotReplies,
  getTemplates,
  getFavourites,
  isOpen,
  setIsOpen,
}) => {
  let { t } = useTranslation(["sidebar"]);

  const [isTaskModalVisible, setIsTaskModalVisible] = React.useState(false);
  const [isContactModalVisible, setIsContactModalVisible] =
    React.useState(false);
  const [isChangeImageVisible, setIsChangeImageVisible] = React.useState(false);

  const showTaskModal = () => {
    setIsTaskModalVisible(true);
  };

  const showImageModal = () => {
    setIsChangeImageVisible(true);
  };

  const handleTaskCancel = () => {
    setIsTaskModalVisible(false);
  };

  const showContactModal = () => {
    setIsContactModalVisible(true);
  };

  const handleContactCancel = () => {
    setIsContactModalVisible(false);
  };

  const handleImageCancel = () => {
    setIsChangeImageVisible(false);
  };

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div
      className={`${
        isOpen
          ? "main-side-bar col-sm-2 mx-0 side_col navbar mt-0"
          : "hiddenNavbar"
      }  `}
      style={{ width: isOpen ? "" : "-10px" }}
    >
      <button onClick={toggle} id="side-toggler2" />
      <Collapse isOpen={isOpen} navbar>
        <Nav vertical style={{ width: "100%" }}>
          <QuickTodoForm
            handleCancel={handleTaskCancel}
            isModalVisible={isTaskModalVisible}
          />
          <QuickContactForm
            handleCancel={handleContactCancel}
            isModalVisible={isContactModalVisible}
          />
          <ChangeImage
            handleCancel={handleImageCancel}
            isModalVisible={isChangeImageVisible}
          />
          <div className="row m-1">
            <Card className="quick-profile">
              <Card.Meta
                avatar={
                  <Badge
                    dot
                    style={{ cursor: "pointer" }}
                    offset={[3, 40]}
                    count={<FiEdit3 size="20" onClick={showImageModal} />}
                  >
                    <Avatar
                      shape="square"
                      size="large"
                      src={<Image src={user?.image} />}
                    />
                  </Badge>
                }
                title={user?.name}
              />
              <CardBody>
                <div className="row">
                  <div className="d-flex justify-content-center">
                    <Tooltip title="Add item to to-do list">
                      <Button
                        className="main-button m-1"
                        onClick={showTaskModal}
                      >
                        <MdPlaylistAdd />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Create new contact">
                      <Button
                        className="main-button m-1"
                        onClick={showContactModal}
                      >
                        <RiUserAddLine />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="scroller-side-bar p-2">
            <NavItem>
              <NavLink className="side-bar-link my-2" tag={Link} to="/">
                <span>
                  <AiFillHome />
                </span>{" "}
                <span className="nav_text"> {t("home")}</span>
              </NavLink>
            </NavItem>
            {user && user.accountType === "SystemOwner" && (
              <>
                <NavItem>
                  <NavLink
                    className="side-bar-link my-2"
                    tag={Link}
                    to="/users"
                  >
                    <span>
                      <FiUsers />
                    </span>
                    <span className="nav_text"> {t("users")}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="side-bar-link my-2"
                    tag={Link}
                    to="/chatbot/manage"
                  >
                    <span>
                      <BiTask />
                    </span>
                    <span className="nav_text"> {t("bot_replies")}</span>
                  </NavLink>
                </NavItem>
              </>
            )}
            <NavItem>
              <NavLink className="side-bar-link my-2" tag={Link} to="/contacts">
                <span>
                  <RiContactsBookFill />
                </span>
                <span className="nav_text"> {t("contact_list")}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="side-bar-link my-2"
                tag={Link}
                to="/categories"
              >
                <span>
                  <BsFolderFill />
                </span>{" "}
                <span className="nav_text"> {t("categories")}</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink className="side-bar-link my-2" tag={Link} to="/tasks">
                <span>
                  <BiTask />
                </span>{" "}
                <span className="nav_text"> {t("tasks")}</span>
              </NavLink>
            </NavItem>

            {/* <NavItem>
              <NavLink className="side-bar-link my-2" tag={Link} to="/todo">
                <span>
                  <SiTodoist />
                </span>{" "}
                <span className="nav_text"> {t("todo")}</span>
              </NavLink>
            </NavItem> */}

            <NavItem>
              <NavLink className="side-bar-link my-2" tag={Link} to="/events">
                <span>
                  <MdEvent />
                </span>{" "}
                <span className="nav_text"> {t("events")}</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className="side-bar-link my-2"
                tag={Link}
                to="/appointments"
              >
                <span>
                  <AiOutlineSchedule />
                </span>{" "}
                <span className="nav_text"> {t("appointments")}</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink className="side-bar-link my-2" tag={Link} to="/chatbot">
                <span>
                  <BsChatQuoteFill />
                </span>{" "}
                <span className="nav_text"> {t("bot_words")}</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className="side-bar-link my-2"
                tag={Link}
                to="/templates"
              >
                <span>
                  <CgTemplate />
                </span>{" "}
                <span className="nav_text"> {t("templates")}</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className="side-bar-link my-2"
                tag={Link}
                to="/favourites"
              >
                <span>
                  <BsStar />
                </span>{" "}
                <span className="nav_text"> {t("favourites")}</span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink className="side-bar-link my-2" tag={Link} to="/calendar">
                <span>
                  <BsCalendar />
                </span>{" "}
                <span className="nav_text"> {t("calendar")}</span>
              </NavLink>
            </NavItem>

            {/* <NavItem>
              <NavLink
                className="side-bar-link my-2"
                tag={Link}
                to="/chatbot"
                onClick={logout}
              >
                <span>
                  <RiLogoutBoxFill />
                </span>{" "}
                <span className="nav_text"> {t("logout")}</span>
              </NavLink>
            </NavItem> */}
          </div>
        </Nav>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.auth.user });

const mapDispatchToProps = {
  getFavourites,
  getTasks,
  getTodos,
  getContactList,
  getCategories,
  getEvents,
  getCalendar,
  getChatbotReplies,
  getTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
