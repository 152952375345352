import {
  GET_MESSAGES,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_LOADING,
  GET_CHATS,
  GET_CHATS_FAIL,
  GET_CHATS_LOADING,
  SET_HAS_MORE_MESSAGES,
  GET_CHAT_MEDIA,
  GET_CHAT_MEDIA_FAILED,
  GET_CHAT_MEDIA_LOADING,
  CHANGE_CURRENT_CHAT,
  UPDATE_CHAT_IN_LIST,
  UPDATE_CHAT_IN_LIST_AND_MOVE_FIRST,
  UPDATE_UN_SEEN_COUNT,
  UPDATE_MESSAGE,
  UPDATE_CHAT_USER_STATUS,
  UPDATE_CHAT_USER_STATUS_IN_LIST,
  LEAVE_CHAT,
  ADD_CHAT_TO_LIST,
  CLOSE_SELECTOR,
} from "../actions/types";

const initialState = {
  messages: [],
  singleChat: null,
  chats: [],
  media: [],
  error: false,
  loading: false,
  hasMore: false,
};

export default function chatReducer(state = initialState, action) {
  const { type, payload } = action;
  let currChat = state?.singleChat;

  switch (type) {
    case ADD_CHAT_TO_LIST: {
      const { singleChat: newChatAdd } = payload;

      let found = state.chats?.find((c) => c.id === newChatAdd?.id);

      if (found || !newChatAdd) {
        return { ...state };
      }
      console.log("Chat will be added", newChatAdd);
      return {
        ...state,
        chats: [newChatAdd, ...state.chats],
      };
    }

    case UPDATE_CHAT_USER_STATUS:
      console.log("Runned chat reducer for status", payload);
      const { singleChat, otherUser } = payload;

      if (state?.singleChat && singleChat?.id === state?.singleChat?.id) {
        if (state?.singleChat?.otherUser) {
          let newChat = {
            ...state.singleChat,
            otherUser: { ...(state.singleChat?.otherUser || {}), ...otherUser },
          };

          return { ...state, singleChat: newChat };
        }
      }

      return { ...state };
    case UPDATE_UN_SEEN_COUNT:
      let oldChatToUpdateSeen = state.chats.find(
        (chat) => chat.id === payload.singleChat?.id
      );
      if (oldChatToUpdateSeen) {
        if (currChat) {
          if (currChat?.id === payload.singleChat?.id) {
            return { ...state };
          }
        }

        let newChats = state.chats.map((chat, i) =>
          chat.id === payload.singleChat.id
            ? {
                ...oldChatToUpdateSeen,
                ...payload.singleChat,
                unseenCount: payload.singleChat.unseenCount,
              }
            : chat
        );

        return { ...state, chats: [...newChats] };
      } else {
        return { ...state };
      }

    case UPDATE_CHAT_IN_LIST_AND_MOVE_FIRST:
      let fetchedIndex = state.chats.find(
        (chat) => chat.id === payload.singleChat?.id
      );

      if (fetchedIndex) {
        let newChats = state.chats.map((chat, i) =>
          chat.id === payload.singleChat.id
            ? { ...chat, ...payload.singleChat }
            : chat
        );
        newChats = newChats.filter((ch) => ch.id !== fetchedIndex.id);
        delete payload.singleChat.unseenCount;

        newChats = [
          {
            ...fetchedIndex,
            ...payload.singleChat,
            messages: fetchedIndex.messages || [],
          },
          ...newChats,
        ];
        return { ...state, chats: [...newChats] };
      } else {
        return console.log("Chat not found");
      }
    case UPDATE_CHAT_USER_STATUS_IN_LIST:
      let newChatstoUpdate = state.chats.map((chat, i) => {
        if (chat?.id === payload?.singleChat?.id) {
          chat = {
            ...chat,
            otherUser: { ...chat?.otherUser, ...payload?.otherUser },
          };
        }
        return chat;
      });

      return {
        ...state,
        chats: [...newChatstoUpdate],
      };

    case UPDATE_CHAT_IN_LIST:
      let newChats = state.chats.map((chat, i) => {
        if (chat?.id === payload?.singleChat?.id) {
          let addToFirst =
            typeof payload.singleChat?.addToFirst === "undefined"
              ? true
              : false;
          let messages;

          if (addToFirst) {
            messages = [
              ...[
                ...(payload?.singleChat?.messages || []),
                ...(chat?.messages || []),
              ],
            ];
          } else {
            messages = [
              ...[
                ...(chat?.messages || []),
                ...(payload?.singleChat?.messages || []),
              ],
            ];
          }

          chat = {
            ...chat,
            ...payload?.singleChat,
            messages,
          };
        }

        return chat;
      });

      return {
        ...state,
        chats: [...newChats],
      };

    case CHANGE_CURRENT_CHAT:
      let newMessagesCount = state?.singleChat?.newMessages || 0;

      let newCount =
        newMessagesCount + parseInt(payload.singleChat?.newMessages);
      if (payload.singleChat?.newMessages === 0) {
        newCount = 0;
      }
      if (
        payload.singleChat?.newMessages !== 0 &&
        !payload.singleChat?.newMessages
      ) {
        newCount = newMessagesCount;
      }
      return {
        ...state,
        singleChat: state?.singleChat
          ? {
              ...state?.singleChat,
              ...payload.singleChat,
              newMessages: newCount,
            }
          : state?.singleChat,
      };

    case GET_CHAT_MEDIA:
      return {
        ...state,
        media: payload.result,
      };

    case SET_HAS_MORE_MESSAGES:
      return {
        ...state,
        hasMore: payload,
      };
    case GET_CHATS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_CHATS:
      console.log(payload);
      return {
        ...state,
        chats: payload.result,
        error: false,
        loading: false,
      };
    case GET_MESSAGES:
      let newMessages = payload.result || [];
      return {
        ...state,
        messages: newMessages,
        singleChat:
          typeof payload.singleChat !== "undefined"
            ? { ...state.singleChat, ...payload.singleChat }
            : state.singleChat,
        error: false,
        loading: false,
      };
    case GET_MESSAGES_LOADING:
      return {
        ...state,
        messages: [],
        error: false,
        loading: true,
      };
    case GET_MESSAGES_FAIL:
      return {
        ...state,
        messages: [],
        error: true,
        loading: false,
      };
    case UPDATE_MESSAGE:
      const { message, chatId } = payload;
      let chatIndex = state.chats.findIndex((chat) => chat.id === chatId);
      let chat = state.chats[chatIndex];
      console.log(chat, message);
      if (!chat) return state;
      let messageIndex = chat?.messages?.findIndex((m) => m.id === message.id);
      let currMessage = chat?.messages[messageIndex];

      if (!currMessage) return state;

      currMessage = { ...currMessage, ...message };

      chat.messages[messageIndex] = currMessage;

      state.chats[chatIndex] = chat;
      if (state.singleChat?.id !== chatId) return state;

      let newStateAfterUpdate = state?.messages.map((thisMessage) => {
        if (thisMessage.id === payload.message.id) {
          return { ...thisMessage, ...message };
        }
        return thisMessage;
      });

      state.messages = newStateAfterUpdate;
      return state;
    case LEAVE_CHAT:
      return {
        ...state,
        chats: state.chats?.filter((chat) => chat.id !== payload),
        singleChat: state.singleChat?.id === payload ? null : state.singleChat,
      };
    default:
      return state;
  }
}
