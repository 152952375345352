import {
  AiFillFilePdf,
  AiFillFileWord,
  AiFillFileExcel,
  AiFillFile,
} from "react-icons/ai";
import { FaFilePowerpoint } from "react-icons/fa";
import { Image } from "antd";

export const FileMedia = ({file}) => {
  let fileURL;
  let fileExt = file?.media?.split("?X-Amz-Algorithm");
  if (fileExt && fileExt[0]) {
    fileURL = fileExt[0];
  } else {
    fileURL = file;
  }
  if (fileURL.endsWith(".pdf")) {
    return (
      <AiFillFilePdf
        className="file-display"
        color="red"
        onClick={() => window.open(file.media)}
      />
    );
  }
  if (fileURL.endsWith(".docx") || fileURL.endsWith(".doc")) {
    return (
      <AiFillFileWord
        className="file-display"
        color="blue"
        onClick={() => window.open(file.media)}
      />
    );
  }
  if (fileURL.endsWith(".xlsx") || fileURL.endsWith(".csv")) {
    return (
      <AiFillFileExcel
        className="file-display"
        color="green"
        onClick={() => window.open(file.media)}
      />
    );
  }
  if (fileURL.endsWith(".ppt") || fileURL.endsWith(".pptx")) {
    return (
      <FaFilePowerpoint
        className="file-display"
        color="orange"
        onClick={() => window.open(file.media)}
      />
    );
  }
  if (
    fileURL.endsWith(".jpg") ||
    fileURL.endsWith(".jpeg") ||
    fileURL.endsWith(".png") ||
    fileURL.endsWith(".tiff") ||
    fileURL.endsWith(".gif") ||
    fileURL.endsWith(".webp")
  ) {
    return <Image className="file-display" src={file.media || file} />;
  }
  if (file) {
    return (
      <AiFillFile
        className="file-display"
        color="var(--primaryColor)"
        onClick={() => window.open(file.media)}
      />
    );
  }
};
