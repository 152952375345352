import axios from "axios";

import { apis } from "../../constants/API";
import { TODO_LOADING, LOAD_TODO } from "./types";
import { tokenConfig } from "./authAcrions";
import { toastr } from "react-redux-toastr";

export const createNewTodo =
  (data, handleClose, firebaseMessage, firebaseChat) =>
  (dispatch, getState) => {
    axios
      .post(apis.listTodo, data, {
        ...tokenConfig(getState),
      })
      .then((res) => {
        const {
          data: { data },
        } = res;
        /*       if (firebaseMessage) {
          convertMessage(firebaseMessage, firebaseChat, data.id, "todo");
        } */
        toastr.success("Item added to Todo list");
        const oldTodos = getState().todo?.todo;
        let newTodos = [data, ...oldTodos];
        return dispatch({
          type: LOAD_TODO,
          payload: { result: newTodos },
        });
      })
      .catch((err) => console.log(err));
  };

export const getTodos = (query, page) => (dispatch, getState) => {
  dispatch({
    type: TODO_LOADING,
  });
  return axios
    .get(apis.listTodo, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: query.limit ? query.limit : 50,
        page,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_TODO,
        payload: {
          result: data.result,
          thisPage: data.thisPage,
          allPages: data.allPages,
          count: data.count,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateTodoStatus = (todoId) => (dispatch, getState) => {
  axios
    .put(
      apis.updateTodoStatus,
      {
        todoId,
      },
      {
        ...tokenConfig(getState),
      }
    )
    .then((res) => {
      let { data } = res.data;
      let currentTodo = getState().todo?.todos;
      currentTodo = currentTodo.map((t) => {
        if (t.id === data.id) {
          t.done = data.done;
          return t;
        } else {
          return t;
        }
      });
      return dispatch({
        type: LOAD_TODO,
        payload: { result: currentTodo },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteTodo = (todoId) => (dispatch, getState) => {
  axios
    .delete(apis.deleteTodo, {
      ...tokenConfig(getState),
      params: {
        todoId,
      },
    })
    .then((res) => {
      const oldTodos = getState().todo?.todo;
      let newTodos = oldTodos.filter((t) => t.id !== todoId);
      return dispatch({
        type: LOAD_TODO,
        payload: { result: newTodos },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editTodo = (todoId, data) => (dispatch, getState) => {
  dispatch({
    type: TODO_LOADING,
  });
  axios
    .put(
      apis.updateTodo,
      {
        ...data,
        todoId,
      },
      {
        ...tokenConfig(getState),
      }
    )
    .then((res) => {
      let { data } = res.data;
      let currentTodo = getState().todo?.todo;
      currentTodo = currentTodo.map((e) => {
        if (e.id === data.id) {
          e = data;
          return e;
        } else {
          return e;
        }
      });
      toastr.success("To-do list entry updated");
      return dispatch({
        type: LOAD_TODO,
        payload: { result: currentTodo },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
