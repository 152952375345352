import React from "react";
import { connect } from "react-redux";
import { googleAuth } from "../../store/actions/authAcrions";
import { GoogleLogin } from "react-google-login";
import { BASE_URL } from "../../connection/baseURL";

export const GoogleAuth = ({ googleAuth }) => {
  const googleFail = (failData) => {
    console.log(failData);
  };
  const googleSuccess = (loginData) => {
    const { code } = loginData;
    googleAuth({ code });
  };
  return (
    <div>
      <GoogleLogin
        clientId="411457835611-fg6tn6tehsfaanq9jc60pemostitlppi.apps.googleusercontent.com"
        buttonText=" Bind Gmail"
        onSuccess={googleSuccess}
        onFailure={googleFail}
        accessType="offline"
        className="google-button"
        responseType="code"
        redirectUri="postmessage"
        prompt="consent"
        icon={true}
        scope="https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email  https://www.googleapis.com/auth/admin.directory.resource.calendar  https://www.googleapis.com/auth/calendar.events  https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/contacts.readonly"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { googleAuth };

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuth);
