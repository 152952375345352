import React from "react";
import { connect } from "react-redux";
import { Card, Skeleton, Avatar, Progress, Modal } from "antd";
import { CardBody } from "reactstrap";
import { HiUserRemove } from "react-icons/hi";
import { IoMdNotifications } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { removeAssignee } from "../../../../../store/actions/taskActions";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const SingleUser = ({ user, loading, removeAssignee, task }) => {
  let { t } = useTranslation(["delete"]);
  let history = useHistory();

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    removeAssignee(task.id, user.id);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="col-5 text-center align-self-center justify-self-center mx-2">
      <Modal
        title={t("confirm")}
        visible={isModalVisible}
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ className: "main-button" }}
        okText={t("ok")}
        cancelText={t("cancel")}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <p>
          {t("sure")} "{user.name}"
        </p>
      </Modal>
      <Card
        style={{ marginTop: 16 }}
        actions={
          user.type === "user" && [
            <HiUserRemove onClick={showModal} />,
            <IoMdNotifications />,
          ]
        }
      >
        <Skeleton avatar loading={loading} active>
          <Card.Meta
            avatar={
              <Avatar
                src={user?.image || user?.contact?.image || null}
                onClick={() => history.push("/users/" + user.id)}
                style={{ cursor: "pointer" }}
              >
                <FaUserCircle size="25" />
              </Avatar>
            }
            title={user.name || user.contact.name}
            description={user.type === "contact" ? user.mobile : null}
          />
        </Skeleton>
        {user && user.type === "user" && (
          <CardBody>
            <Progress
              percent={user.status[0].percentage}
              size="small"
              status={user.status[0].statusId === "1" && "exception"}
            />
          </CardBody>
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state?.task?.loading,
  task: state?.task?.singleTask?.task,
});

const mapDispatchToProps = {
  removeAssignee,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleUser);
