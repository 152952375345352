import React from "react";
import { connect } from "react-redux";
import { Modal, Form, DatePicker, Input, Button } from "antd";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { editEvent, getSingleEvent } from "../../../store/actions/eventActions";
import TagsInput from "react-tagsinput";

export const EditEvent = ({
  isModalVisible,
  handleCancel,
  getSingleEvent,
  editEvent,
  event,
}) => {
  let { t } = useTranslation(["events"]);
  const [eventData, setEventData] = React.useState({
    title: "",
    startDate: "",
    endDate: "",
    description: "",
    tags: [],
  });

  React.useEffect(() => {
    setEventData({
      title: event?.title,
      startDate: moment(event?.startDate),
      endDate: moment(event?.endDate),
      description: event?.description,
      tags: event?.tags?.map((tag) => tag.tag),
    });
  }, [event]);

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const onChange = (e) => {
    let { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
    console.log(title);
  };

  let { title, startDate, endDate, description, tags } = eventData;

  const onStartDateChange = (date) => {
    setEventData({ ...eventData, startDate: date.locale("en-us") });
  };

  const onEndDateChange = (date) => {
    setEventData({ ...eventData, endDate: date.locale("en-us") });
  };

  function disabledDates(e) {
    return moment(e).dayOfYear() < moment(startDate).dayOfYear();
  }
  function disabledEndDateTime(e) {
    return {
      disabledHours: () =>
        range(0, moment(e) < moment(startDate) && moment(startDate).hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment(startDate) && moment(startDate).minute()),
    };
  }
  function disabledStartDateTime(e) {
    return {
      disabledHours: () => range(0, moment(e) < moment() && moment().hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment() && moment().minute()),
    };
  }

  const [editTags, setEditTags] = React.useState(false);

  const handleChangeTags = (tags) => {
    setEventData({...eventData, tags: tags})
  }

  const handleEdit = () => {
    let formData = new FormData();
    formData.append("eventId", event?.id);
    title && formData.append("title", title); 
    startDate && formData.append("startDate", startDate);
    endDate && formData.append("endDate", endDate);
    description && formData.append("description", description);
    tags && tags.map((tag) => formData.append("tags[]", tag));
    editEvent(formData, handleCancel);
    getSingleEvent(event?.id);
  };

  return (
    <Modal
      closable={false}
      title={t("edit")}
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={handleEdit}
      okButtonProps={{
        className: "main-button",
        disabled: !title && !description && !startDate && !endDate,
      }}
      okText={t("ok_edit")}
      cancelButtonProps={{ className: "main-button-cancel" }}
      cancelText={t("cancel")}
    >
      <Form>
        <Form.Item
          name="title"
          rules={[{ required: true, message: t("form.required.event_title") }]}
        >
          <Input
            defaultValue={title}
            onChange={onChange}
            name="title"
            value={title}
            type="textarea"
            className="task-input"
            placeholder={t("form.event_title")}
            label="Event Title"
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            { required: true, message: t("form.required.event_description") },
          ]}
        >
          <Input.TextArea
            defaultValue={description}
            onChange={onChange}
            name="description"
            value={description}
            className="task-input"
            placeholder={t("form.event_description")}
          />
        </Form.Item>
        <Row>
          <Col className="col-md-4">{t("start")}</Col>
          <Col className="col-md-8">
            <Form.Item
              name="startDate"
              rules={[
                {
                  required: true,
                  message: t("form.required.startDate"),
                },
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onOk={onStartDateChange}
                defaultValue={startDate}
                name="startDate"
                className="task-input col-md-12"
                placeholder={t("form.startDate")}
                disabledDate={(e) => e < moment().startOf("day")}
                disabledTime={disabledStartDateTime}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-4">{t("end")}</Col>
          <Col className="col-md-8">
            <Form.Item
              name="endDate"
              rules={[
                {
                  required: true,
                  message: t("form.required.endDate"),
                },
              ]}
            >
              <DatePicker
                showTime
                showSecond={false}
                onOk={onEndDateChange}
                defaultValue={endDate}
                name="endDate"
                className="task-input col-md-12"
                placeholder={t("form.endDate")}
                disabledDate={disabledDates}
                disabledTime={disabledEndDateTime}
              />
            </Form.Item>
          </Col>
        </Row>
        {editTags && (
          <Row className="mb-2">
            <Col className="col-md-4">{t("tags")}</Col>
            <Col className="col-md-8">
              <TagsInput
                maxTags="15"
                className="task-input"
                onChange={handleChangeTags}
                name="tags"
                value={tags}
              />
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <Col>
            <Button
              className={`main-button${editTags ? "-selected" : ""}`}
              onClick={() => setEditTags(!editTags)}
            >
              Edit Tags
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  editEvent,
  getSingleEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
