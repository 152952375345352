import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "antd";
import { FaRegCalendarPlus } from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";
import { TabContent, NavItem, NavLink, Nav, TabPane, Row } from "reactstrap";
import classnames from "classnames";
import PaginateAppointments from "./PaginateAppointments";
import PaginateSlots from "./PaginateSlots";
import CreateAppointment from "./CreateAppointment";
import {
  getMyAppointments,
  getFreeAppointments,
} from "../../store/actions/appointmentsActions";

export const Appointments = ({ getMyAppointments, getFreeAppointments, history, user }) => {
  let { t } = useTranslation(["appointments"]);

  const [createOpen, setcreateOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("3");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  React.useEffect(() => {
    if (activeTab === "3") {
      getFreeAppointments({ limit: 10, userId: user?.id }, 1);
    } else {
      getMyAppointments(
        {
          limit: 10,
          type: activeTab === "1" ? "invited" : "sent",
          status: "pending",
        },
        1
      );
    }
  }, [activeTab]);

  const handleCancel = () => {
    setcreateOpen(false);
  };

  return (
    <div className=" mb-5 main-page">
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            <AiOutlineSchedule /> {t("appointments")}
          </h2>
        </div>
        <div>
          <Button
            className="text-center main-button"
            style={{ cursor: "pointer" }}
            onClick={() => setcreateOpen(!createOpen)}
            id="close"
          >
            <FaRegCalendarPlus size="25" color="white" />
          </Button>
        </div>
      </div>
      <div>
        <CreateAppointment
          isModalVisible={createOpen}
          handleCancel={handleCancel}
        />
      </div>
      <Nav tabs>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("3");
            }}
          >
            My Free Slots
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("1");
            }}
          >
            Incoming Appointments
          </NavLink>
        </NavItem>
        <NavItem className="mx-1">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("2");
            }}
          >
            Sent Appointments
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <PaginateAppointments tab={1} history={history} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="2">
          <Row>
            <PaginateAppointments tab={2} history={history} />
          </Row>
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="3">
          <Row>
            <PaginateSlots book={false} userId={user?.id} history={history} />
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appointments: state?.appointments?.appointments,
  loading: state?.appointments?.loading,
  thisPage: state?.appointments?.thisPage,
  user: state?.auth?.user,
});

const mapDispatchToProps = {
  getMyAppointments,
  getFreeAppointments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
