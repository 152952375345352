import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { MdCloudDone } from "react-icons/md";
import { HiClock } from "react-icons/hi";
import { getTasks } from "../../../store/actions/taskActions";
import { useTranslation } from "react-i18next";
import { Row, Col, Avatar } from "antd";
import { getUsers } from "../../../store/actions/usersActions";
import { useHistory } from "react-router-dom";

export const HomeTasks = ({ loading, tasks, getTasks, getUsers }) => {
  let { t, i18n } = useTranslation(["tasks"]);
  let history = useHistory();

  const getTaskStatus = (task) => {
    const status =
      task?.assignees && task.assignees[0]?.taskStatus[0]?.status?.status;
    if (status === "PENDING") {
      return (
        <HiClock
          className="mx-2"
          style={{
            color: "var(--primaryColor)",
          }}
        />
      );
    }

    if (status === "DONE") {
      return (
        <MdCloudDone
          className="mx-2"
          style={{
            color: "var(--primaryColor)",
          }}
        />
      );
    }
  };

  React.useEffect(() => {
    getTasks({ type: "assigned", limit: 5 });
  }, []);
  return (
    <div>
      {loading && "Loading Tasks"}
      {tasks &&
        tasks.length !== 0 &&
        tasks.map((task, idx) => {
          const status =
            task?.assignees &&
            task?.assignees[0]?.taskStatus[0]?.status?.status;
          return (
            <Row key={idx}>
              <Col className="col-md-6">
                <div className="my-3" style={{ color: "var(--primaryColor" }}>
                  {getTaskStatus(task)}
                  {status !== "DONE" ? (
                    <span
                      style={{
                        wordBreak: "break-word",
                        color: "var(--primaryColor)",
                        fontWeight: "bold",
                      }}
                    >
                      {task.title}
                    </span>
                  ) : (
                    <span
                      style={{
                        wordBreak: "break-word",
                        color: "var(--darkGrey)",
                        fontWeight: "bold",
                        textDecoration: "line-through",
                      }}
                    >
                      {task.title}
                    </span>
                  )}

                  <div>
                    <span
                      style={{
                        color: "var(--terColor)",
                        fontWeight: "normal",
                      }}
                    >
                      {status !== "DONE" ? (
                        <span>
                          {t("ends_at")} -{" "}
                          {moment(task.endDate)
                            .locale(i18n.language)
                            .format("ll")}
                        </span>
                      ) : (
                        <span>{t("done")}</span>
                      )}
                    </span>
                  </div>
                </div>
              </Col>
              <Col className="col-md-6">
                {console.log(task?.users)}
                <Avatar.Group maxCount={5}>
                  {task?.users?.map((user, idx) => {
                    return (
                      <Avatar
                        key={idx}
                        src={user?.image}
                        onClick={() => history.push("/users/" + user.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {user?.name ? user?.name[0] : ""}
                      </Avatar>
                    );
                  })}
                </Avatar.Group>
              </Col>
            </Row>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.task.loading,
  tasks: state?.task?.tasks,
});

const mapDispatchToProps = { getTasks, getUsers };

export default connect(mapStateToProps, mapDispatchToProps)(HomeTasks);
