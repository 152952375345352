import {
    TODO_LOADING,
    LOAD_TODO,
  } from "../actions/types";
  
  const initialState = {
    todo: [],
    error: false,
    loading: false,
    thisPage: 1,
    allPages: 1,
    count: 1,
    singleTodo: {},
  };
  
  export default function todoReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case LOAD_TODO:
        return {
          ...state,
          todo: payload.result,
          thisPage: payload.thisPage,
          allPages: payload.allPages,
          count: payload.count,
          error: false,
          loading: false,
        };
      case TODO_LOADING:
        return {
          ...state,
          error: false,
          loading: true,
        };
      default:
        return state;
    }
  }
  