import { TOGGLE_SELECT_MESSAGE, CLOSE_SELECTOR } from "../actions/types";

const initialState = {
  selectedMessages: [],
  chatId: "",
  isSelectorOpen: false,
};

export default function messageSelectReducer(state = initialState, action) {
  const { type, payload } = action;
  const { selectedMessages } = state;
  switch (type) {
    case CLOSE_SELECTOR:
      return {
        ...state,
        chatId: "",
        isSelectorOpen: false,
        selectedMessages: [],
      };
    case TOGGLE_SELECT_MESSAGE:
      const { message, chatId = "" } = payload;
      let messageId = message?.id;
      let isSelected = selectedMessages?.find(
        (selectMessage) => selectMessage?.id === messageId
      );
      if (isSelected) {
        let newMessages = selectedMessages.filter(
          (selected) => selected.id !== messageId
        );
        return {
          ...state,
          isSelectorOpen: newMessages.length === 0 ? false : true,
          selectedMessages: newMessages,
          chatId,
        };
      } else {
        let newMessages = [...selectedMessages, message];
        return {
          ...state,
          isSelectorOpen: newMessages.length === 0 ? false : true,
          selectedMessages: newMessages,
          chatId,
        };
      }

    default:
      return state;
  }
}
