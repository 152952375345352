import { Button } from "reactstrap";
import React from "react";
import { connect } from "react-redux";
import ReplyEditModal from "./ReplyEditModal";
export const SingleReply = ({ reply }) => {
  const [editOpen, setEditOpen] = React.useState(false);
  return (
    <div className="my-5 mx-4 reply-box py-4 px-4">
      <ReplyEditModal
        reply={reply}
        editOpen={editOpen}
        setEditOpen={setEditOpen}
      />
      <div className="row my-2">
        <div className="col">
          <span>Name</span>
        </div>
        <div className="col">
          <span className="reply-title text text-capitalize">{reply?.key}</span>
        </div>
      </div>

      <div className="row my-4">
        <div className="col">
          <span>English Reply</span>
        </div>
        <div className="col">
          <span
            className="reply-title text"
            style={{ fontSize: "15px", fontWeight: "bold" }}
          >
            {reply?.value}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span>Arabic Reply</span>
        </div>
        <div className="col">
          <span
            className="reply-title text"
            style={{ fontSize: "15px", fontWeight: "bold" }}
          >
            {reply?.valueAR}
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col"></div>
        <div className="col mt-2">
          <Button className="main-button" onClick={() => setEditOpen(true)}>
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SingleReply);
