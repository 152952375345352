import {
  USERS_FAIL,
  USERS_LOADING,
  LOAD_USERS,
  LOAD_SINGLE_USER,
} from "../actions/types";

const initialState = {
  users: [],
  error: false,
  loading: false,
  thisPage: 1,
  allPages: 1,
  totalCount: 1,
  singleUser: {},
};

export default function usersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_USERS:
      return {
        ...state,
        users: payload.result,
        error: false,
        loading: false,
        allPages: payload.allPages,
        thisPage: payload.thisPage,

      };
    case USERS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case USERS_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case LOAD_SINGLE_USER:
      return {
        ...state,
        error: false,
        loading: false,
        singleUser: payload,
      };
    default:
      return state;
  }
}
