import {
  GET_CHAT_BOT_REPLIES,
  GET_CHAT_BOT_REPLIES_LOADING,
  GET_CHAT_BOT_REPLIES_ERROR,
  UPDATE_CHAT_BOT_REPLY,
} from "../actions/types";

const initialState = {
  replies: [],
  error: false,
  loading: false,
};

export default function botReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CHAT_BOT_REPLIES:
    case UPDATE_CHAT_BOT_REPLY:
      return {
        ...state,
        replies: payload.result,
        error: false,
        loading: false,
      };
    case GET_CHAT_BOT_REPLIES_LOADING:
      return {
        ...state,
        replies: [],
        error: false,
        loading: true,
      };
    case GET_CHAT_BOT_REPLIES_ERROR:
      return {
        ...state,
        replies: [],
        error: true,
        loading: false,
      };

    default:
      return state;
  }
}
