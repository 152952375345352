import axios from "axios";

import { apis } from "../../constants/API";
import {
  GET_NOTIFICATION_CONFIG,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_LOADING,
  GET_TOP_NOTIFICATIONS,
  GET_TOP_NOTIFICATIONS_LOADING,
  NOTIFICATION_ACT,
  CHANGE_NOTIFICATION_COUNT,
} from "./types";

import { tokenConfig } from "./authAcrions";
import { updateEventStatus } from "./eventActions";
import { setAppointmentStatus } from "./appointmentsActions";
import { updateTaskStatus } from "./taskActions";

export const getTopNotificationAction = (query) => (dispatch, getState) => {
  dispatch({
    type: GET_TOP_NOTIFICATIONS_LOADING,
  });
  axios
    .get(apis.notification, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: 5,
        dashboard: true,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: GET_TOP_NOTIFICATIONS,
        payload: {
          result: data.result,
          unSeenCount: data.unseenCount || 0,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_NOTIFICATIONS_FAIL });
    });
};

export const getNotificationConfig = (query) => (dispatch, getState) => {
  dispatch({
    type: GET_NOTIFICATIONS_LOADING,
  });
  axios
    .get(apis.notificationConfig, {
      ...tokenConfig(getState),
      params: {
        ...query,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: GET_NOTIFICATION_CONFIG,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);

      return dispatch({
        type: GET_NOTIFICATIONS_FAIL,
      });
    });
};

export const updateNotificationConfig = (body) => (dispatch, getState) => {
  dispatch({
    type: GET_NOTIFICATIONS_LOADING,
  });
  axios
    .put(apis.notificationConfig, body, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: GET_NOTIFICATION_CONFIG,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);

      return dispatch({
        type: GET_NOTIFICATIONS_FAIL,
      });
    });
};

export const getNotifications = (query, page) => (dispatch, getState) => {
  dispatch({
    type: GET_NOTIFICATIONS_LOADING,
  });
  let currentPage = getState().notifications.thisPage;
  let getPage = page ? page : currentPage + 1;

  axios
    .get(apis.notification, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: query?.limit ? query.limit : 40,
        dashboard: true,
        page: getPage,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      let currentNotifications = getState().notifications?.notifications;
      let result = [];

      if (page === 1) {
        result = data.result;
      } else {
        result = [...currentNotifications, ...data.result];
      }

      dispatch({
        type: GET_NOTIFICATIONS,
        payload: {
          result: result,
          thisPage: data.thisPage,
          allPages: data.allPages,
          count: data.count,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_NOTIFICATIONS_FAIL });
    });
};

export const notificationRequestAction =
  (status, notification) => (dispatch, getState) => {
    switch (notification.moduleName) {
      case "event":
        updateEventStatus(
          notification?.entityId,
          status === "accept" ? String(1) : String(2),
          undefined,
          true
        )(dispatch, getState);
        break;
      case "task":
        updateTaskStatus(
          {
            taskId: notification?.entityId,
            statusId: status === "accept" ? String(2) : String(4),
          },
          undefined,
          true
        )(dispatch, getState);
        break;
      case "appointment":
        setAppointmentStatus(
          {
            appointmentId: notification?.entityId,
            status: status === "accept" ? "accepted" : "declined",
          },
          true
        )(dispatch, getState);
        break;
      default:
        break;
    }
    dispatch({ type: NOTIFICATION_ACT, payload: { notification } });
  };

export const changeNotificationSeenCount = (status) => (dispatch, getState) => {
  let current = getState().notifications.unSeenCount;
  if (status === "increase")
    return dispatch({ type: CHANGE_NOTIFICATION_COUNT, payload: current + 1 });
  if (status === "decrease")
    return dispatch({ type: CHANGE_NOTIFICATION_COUNT, payload: current - 1 });
  if (status === "clear")
    return dispatch({ type: CHANGE_NOTIFICATION_COUNT, payload: 0 });
};
