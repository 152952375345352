import "./App.css";
import Main from "./Main";
import store from "./store/store";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import React from "react";
import { loadUser } from "./store/actions/authAcrions";
import { getToken as getFireBaseToken } from "./firebase/config";
import { useTranslation } from "react-i18next";

import "./i18n";
function App() {
  const [firebaseToken, setFirebaseToken] = React.useState(null);

  React.useEffect(() => {
    getFireBaseToken(setFirebaseToken);
    store.dispatch(loadUser());
  }, []);

  const { i18n } = useTranslation();
  const isRTL = i18n.dir(window["lang"]) === "rtl";
  document.body.setAttribute("dir", isRTL ? "rtl" : "ltr");

  return (
    <div className="App" dir={isRTL ? "rtl" : "ltr"}>
      <Provider store={store}>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        ></ReduxToastr>
        <Main firebaseToken={firebaseToken} />
      </Provider>
    </div>
  );
}

export default App;
