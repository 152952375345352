export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const LOAD_TASKS = "LOAD_TASKS";
export const LOAD_SINGLE_TASK = "LOAD_SINGLE_TASK";
export const TASKS_FAIL = "TASKS_FAIL";
export const TASKS_LOADING = "TASKS_LOADING";
export const LOAD_ASSIGNEES = "LOAD_ASSIGNEES";

export const LOAD_CATS = "LOAD_CATS";
export const LOAD_SINGLE_CAT = "LOAD_SINGLE_CAT";
export const CATS_FAIL = "CATS_FAIL";
export const CATS_LOADING = "CATS_LOADING";
export const TASK_CATS_LOADING = "TASK_CATS_LOADING";
export const LOAD_TASK_CATS = "LOAD_TASK_CATS";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_SINGLE_USER = "LOAD_SINGLE_USER";
export const USERS_FAIL = "USERS_FAIL";
export const USERS_LOADING = "USERS_LOADING";

export const GET_CHAT_WORDS = "GET_CHAT_WORDS";
export const GET_CHAT_WORDS_LOADING = "GET_CHAT_WORDS_LOADING";
export const UPDATE_CHAT_WORDS = "UPDATE_CHAT_WORDS";
export const CHAT_WORDS_ERROR = "CHAT_WORDS_ERROR";

export const GET_CHAT_BOT_REPLIES = "GET_CHAT_BOT_REPLIES";
export const GET_CHAT_BOT_REPLIES_LOADING = "GET_CHAT_BOT_REPLIES_LOADING";
export const UPDATE_CHAT_BOT_REPLY = "UPDATE_CHAT_BOT_REPLY";
export const GET_CHAT_BOT_REPLIES_ERROR = "GET_CHAT_BOT_REPLIES_ERROR";

export const GET_NOTIFICATION_CONFIG = "GET_NOTIFICATION_CONFIG";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_LOADING = "GET_NOTIFICATIONS_LOADING";
export const CHANGE_NOTIFICATION_COUNT = "CHANGE_NOTIFICATION_COUNT";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";
export const NOTIFICATION_ACT = "NOTIFICATION_ACT";
export const GET_TOP_NOTIFICATIONS = "GET_TOP_NOTIFICATIONS";
export const GET_TOP_NOTIFICATIONS_LOADING = "GET_TOP_NOTIFICATIONS_LOADING";

export const GET_CONTACT_LIST = "GET_CONTACT_LIST";
export const GET_CONTACT_LIST_LOADING = "GET_CONTACT_LIST_LOADING";
export const CREATE_CONTACT_LIST = "CREATE_CONTACT_LIST";
export const GET_CONTACT_LIST_ERROR = "GET_CONTACT_LIST_ERROR";

export const TEMPLATE_CREATED = "TEMPLATE_CREATED";
export const TEMPLATE_CREATE_FAILED = "TEMPLATE_CREATE_FAILED";
export const TEMPLATES_LOADING = "TEMPLATES_LOADING";
export const TEMPLATES_LOADED = "TEMPLATES_LOADED";

export const LOADING_FAVOURITES = "LOADING_FAVOURITES";
export const LOAD_FAVOURITES = "LOAD_FAVOURITES";
export const ADDED_FAVOURITE = "ADDED_FAVOURITE";
export const FAVOURITE_ERROR = "FAVOURITE_ERROR";
export const LOADING_TAGS = "LOADING_TAGS";
export const LOAD_TAGS = "LOAD_TAGS";

export const LOAD_CALENDAR = "LOAD_CALENDAR";
export const CALENDAR_LOADING = "CALENDAR_LOADING";

export const GET_LANG = "GET_LANG";
export const CHANGE_LANG = "CHANGE_LANG";

export const LOAD_EVENTS = "LOAD_EVENTS";
export const EVENTS_LOADING = "EVENTS_LOADING";
export const LOAD_SINGLE_EVENT = "LOAD_SINGLE_EVENT";
export const LOAD_EVENTS_ERROR = "LOAD_EVENTS_ERROR";
export const LOAD_INVITEES = "LOAD_INVITEES";

export const TODO_LOADING = "TODO_LOADING";
export const LOAD_TODO = "LOAD_TODO";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_LOADING = "GET_MESSAGES_LOADING";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";

export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_LOADING = "GET_CHATS_LOADING";
export const GET_CHATS_FAIL = "GET_CHATS_FAIL";

export const LOAD_APPOINTMENTS = "LOAD_APPOINTMENTS";
export const APPOINTMENTS_LOADING = "APPOINTMENTS_LOADING";
export const LOAD_SINGLE_APPOINTMENT = "LOAD_SINGLE_APPOINTMENT";

export const SET_HAS_MORE_MESSAGES = "SET_HAS_MORE_MESSAGES";

export const GET_CHAT_MEDIA = "GET_CHAT_MEDIA";
export const GET_CHAT_MEDIA_LOADING = "GET_CHAT_MEDIA_LOADING";
export const GET_CHAT_MEDIA_FAILED = "GET_CHAT_MEDIA_FAILED";
export const CHANGE_CURRENT_CHAT = "CHANGE_CURRENT_CHAT";
export const UPDATE_CHAT_IN_LIST = "UPDATE_CHAT_IN_LIST";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const ADD_CHAT_TO_LIST = "ADD_CHAT_TO_LIST";
export const UPDATE_CHAT_USER_STATUS = "UPDATE_CHAT_USER_STATUS";
export const UPDATE_CHAT_USER_STATUS_IN_LIST =
  "UPDATE_CHAT_USER_STATUS_IN_LIST";
export const LEAVE_CHAT = "LEAVE_CHAT";

export const NEEDS_VERFICATION = "NEEDS_VERFICATION";
export const NEEDS_VERFICATION_ERROR = "NEEDS_VERFICATION_ERROR";

export const UPDATE_CHAT_IN_LIST_AND_MOVE_FIRST =
  "UPDATE_CHAT_IN_LIST_AND_MOVE_FIRST";
export const UPDATE_UN_SEEN_COUNT = "UPDATE_UN_SEEN_COUNT";

///Message select action types
export const TOGGLE_SELECT_MESSAGE = "TOGGLE_SELECT_MESSAGE";
export const GET_SELECTED_MESSAGES = "GET_SELECTED_MESSAGES";
export const CLOSE_SELECTOR = "CLOSE_SELECTOR";
