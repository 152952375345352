import React from "react";
import { connect } from "react-redux";
import { Avatar, Button, Card, Image } from "antd";
import { Row, Col } from "reactstrap";
import EditProfile from "./EditProfile";
import { FaUserEdit, FaPhoneAlt, FaUserCircle } from "react-icons/fa";
import { MdEdit, MdEmail } from "react-icons/md";
import { BiUserPin } from "react-icons/bi";
import cover from "../../Assets/Images/stock-cover.jpg";

import Tabs from "./Tabs";
import GoogleAuth from "../../components/GoogleAuth";
export const Profile = ({ user, loading }) => {
  const [editOpen, setEditOpen] = React.useState(false);

  return (
    <div className="container mb-5 main-page">
      <div className="my-4  container">
        <h2 className="head-text mx-auto">
          {user?.name}'s Profile <BiUserPin />
        </h2>
      </div>

      <div className="task_form">
        <Row className="position-relative">
          <Image preview={false} src={cover} height="250px" />
          <Avatar
            className="position-absolute top-100 start-50 translate-middle"
            style={{ border: "solid 5px white" }}
            size={200}
            src={<Image src={user?.image} />}
          />
        </Row>
        <Row className="position-relative" style={{ marginTop: 100 }}>
          <Col>
            <Row>
              <span className="text-center profile-user-name">
                {user?.name}
              </span>
            </Row>
            <Row className="flex-wrap justify-content-center m-2">
              <Col className="text-center">
                <GoogleAuth />
              </Col>
            </Row>
            <Col
              style={{ borderLeft: "solid 2px gray" }}
              className="position-absolute end-0 top-0 translate-middle-y px-1 mx-5 profile-data-web"
            >
              <Row>
                <div className="profile-data p-2">
                  <FaPhoneAlt className="mx-1" />
                  {user?.mobile}
                </div>
              </Row>
              <Row>
                <div className="profile-data p-2">
                  <MdEmail className="mx-1" />
                  {user?.email}
                </div>
              </Row>
              <Row className="text-center">
                <div>
                  <Button className="main-button">
                    Edit{" "}
                    <MdEdit
                      className="mx-1"
                      cursor="pointer"
                      onClick={() => setEditOpen(!editOpen)}
                    />
                  </Button>
                </div>
              </Row>
            </Col>
            <Row className="profile-data-mob">
              <Col className="text-center">
                <div className="profile-data p-2">
                  <MdEmail className="mx-1" />
                  {user?.email}
                </div>
              </Col>
              <Col className="text-center">
                <div className="profile-data p-2">
                  <FaPhoneAlt className="mx-1" />
                  {user?.mobile}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>{editOpen && <EditProfile />}</div>
      <Tabs />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  loading: state?.auth?.loading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
