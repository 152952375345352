import React from "react";
import { connect } from "react-redux";
import { deleteTask, getSingleTask } from "../../store/actions/taskActions";
import { useParams } from "react-router-dom";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiFillFolderAdd,
  AiFillDelete,
} from "react-icons/ai";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import Tabs from "./Tabs";
import CategorizeForm from "../Tasks/CategorizeForm";
import NotAllowed from "../../components/NotAllowed";

export const Task = ({
  getSingleTask,
  task,
  users,
  history,
  loading,
  user,
  deleteTask,
  error,
}) => {
  let { taskId } = useParams();
  let { t, i18n } = useTranslation(["delete"]);

  React.useEffect(() => {
    getSingleTask({ taskId: taskId });
  }, []);

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteTask(task.id);
    history.push("/tasks");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isCategorizeVisible, setIsCategorizeVisible] = React.useState(false);
  const showCategorize = () => {
    setIsCategorizeVisible(true);
  };

  const handleCategorizeCancel = () => {
    setIsCategorizeVisible(false);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotAllowed path="/tasks" />;
  }

  let creator = user?.id === task?.creatorId;

  return (
    <div className="container mb-5 main-page" style={{ minHeight: "100vh" }}>
      <Modal
        title={t("confirm")}
        visible={isModalVisible}
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ className: "main-button" }}
        okText={t("ok")}
        cancelText={t("cancel")}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <p>
          {t("sure")} "{task?.title}"
        </p>
      </Modal>
      <CategorizeForm
        taskId={task?.id}
        visible={isCategorizeVisible}
        handleCancel={handleCategorizeCancel}
      />
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            {i18n.language === "en" && (
              <AiOutlineArrowLeft
                size="40"
                style={{ color: "var(--primaryColor)", cursor: "pointer" }}
                onClick={() => history.push("/tasks")}
              />
            )}
            {i18n.language === "ar" && (
              <AiOutlineArrowRight
                size="40"
                style={{ color: "var(--primaryColor)", cursor: "pointer" }}
                onClick={() => history.push("/tasks")}
              />
            )}
            {task && task.title}
          </h2>
        </div>
        <div>
          {creator && (
            <Button className="main-button-cancel" onClick={showModal}>
              <AiFillDelete />
            </Button>
          )}
          <Button className="main-button" onClick={showCategorize}>
            <AiFillFolderAdd />
          </Button>
        </div>
      </div>
      <div>
        <Tabs isCreator={creator} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  task: state?.task?.singleTask?.task,
  users: state?.task?.singleTask?.users,
  loading: state?.task?.loading,
  error: state?.task?.error,
  user: state?.auth?.user,
});

const mapDispatchToProps = {
  getSingleTask,
  deleteTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
