import React from "react";
import { connect } from "react-redux";
import { getTodos } from "../../../store/actions/todoActions";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import SingleTodo from "../SingleTodo";
import { VscLoading } from "react-icons/vsc";
import emptyicon from "../../../../src/Assets/Icons/empty-white-box.png";
import FilterBox from "../FilterBox/FilterBox";
import { useTranslation } from "react-i18next";

export const PaginateTodo = ({
  list,
  getTodos,
  thisPage,
  allPages,
  done,
  loading,
  filterOpen,
}) => {
  const onClick = (event) => {
    try {
      switch (event.target.textContent) {
        case "»":
        case "»Last":
        case "Last":
          getTodos({ limit: 6, done: done }, allPages);
          break;
        case "«":
        case "«First":
        case "First":
          getTodos({ limit: 6, done: done }, 1);
          break;
        case "›":
        case "›Next":
        case "Next":
          getTodos({ limit: 6, done: done }, thisPage + 1);
          break;
        case "‹":
        case "‹Previous":
        case "Previous":
          getTodos({ limit: 6, done: done }, thisPage - 1);
          break;
        default:
          getTodos({ limit: 6, done: done }, Number(event.target.textContent));
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  let { t, i18n } = useTranslation(["todo"]);

  const [filters, setFilters] = React.useState({
    done: false,
    text: undefined,
    startDate: undefined,
    endDate: undefined,
    categoryId: undefined,
  });

  const clearFilters = () => {
    setFilters({
      done: false,
      text: undefined,
      startDate: undefined,
      endDate: undefined,
      categoryId: undefined,
    });

    return getTodos({ ...filters, limit: 6 }, 1);
  };

  const handleChange = (e) => {
    let { name, value } = e.target || e;
    setFilters({ ...filters, [name]: value === "" ? undefined : value });
    console.log(filters);
    if (name !== "text") {
      return getTodos(
        { ...filters, [name]: value === "" ? undefined : value, limit: 6 },
        thisPage
      );
    }
  };

  const toggleState = () => {
    let prevDone = filters.done;
    setFilters({ ...filters, done: !prevDone });
  };

  React.useEffect(() => {
    getTodos({ ...filters, limit: 6 }, thisPage);
  }, [filters.done]);

  const handleSearch = (text) => {
    return getTodos(
      { ...filters, text: text === "" ? undefined : text, limit: 6 },
      thisPage
    );
  };

  const handleDateChange = (target, date) => {
    setFilters({ ...filters, [target]: date });
    console.log(date);
    return getTodos({ ...filters, [target]: date, limit: 6 }, thisPage);
  };

  if (loading)
    return (
      <div
        className="col text-center rotator"
        style={{
          fontSize: "40px",
          color: "var(--primaryColor)",
        }}
      >
        <VscLoading />
      </div>
    );
  return (
    <div>
      <div>
        {filterOpen && (
          <FilterBox
            setFilters={setFilters}
            clearFilters={clearFilters}
            filters={filters}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            handleSearch={handleSearch}
            toggleState={toggleState}
            t={t}
          />
        )}
      </div>
      <div>
        {list.map((todo, index) => {
          return <SingleTodo todo={todo} key={index} />;
        })}

        {!loading && list.length === 0 && (
          <div className="col text-center">
            <div className="col text-center">
              <img
                src={emptyicon}
                alt="Empty"
                style={{ width: 100, height: 100 }}
              />
            </div>
            <div
              className="col text-center"
              style={{
                fontSize: "40px",
                color: "var(--primaryColor)",
              }}
            >
              No to-do list items
            </div>
          </div>
        )}
      </div>
      {allPages > 1 && (
        <div className="col justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className="pag-link"
                >
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index}
                      className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state?.todo?.todo,
  loading: state?.todo?.loading,
  thisPage: state?.todo?.thisPage,
  allPages: state?.todo?.allPages,
});

const mapDispatchToProps = { getTodos };

export default connect(mapStateToProps, mapDispatchToProps)(PaginateTodo);
