import axios from "axios";

import { apis } from "../../constants/API";
import {
  GET_CHAT_BOT_REPLIES,
  GET_CHAT_BOT_REPLIES_LOADING,
  GET_CHAT_BOT_REPLIES_ERROR,
  UPDATE_CHAT_BOT_REPLY,
} from "./types";

import { tokenConfig } from "./authAcrions";

import { toastr } from "react-redux-toastr";

export const getChatbotReplies = (query) => (dispatch, getState) => {
  dispatch({
    type: GET_CHAT_BOT_REPLIES_LOADING,
  });
  axios
    .get(apis.chatbotReplies, {
      ...tokenConfig(getState),
      params: {
        ...query,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: GET_CHAT_BOT_REPLIES,
        payload: { result: data },
      });
    })
    .catch((err) => {
      console.log(err);

      return dispatch({
        type: GET_CHAT_BOT_REPLIES_ERROR,
      });
    });
};

export const updateReply =
  (replyId, value, valueAR, toggle) => (dispatch, getState) => {
    axios
      .put(
        apis.chatbotReplies,
        { reply: value, replyId, replyAR: valueAR },
        {
          ...tokenConfig(getState),
        }
      )
      .then((res) => {
        const {
          data: { data, message },
        } = res;

        let oldReplies = getState().bot.replies;
        let newReplies = oldReplies.map((rep) => {
          if (rep.id == replyId) {
            rep = data;
            return rep;
          }
          return rep;
        });
        toggle();
        toastr.success(message);
        dispatch({
          type: GET_CHAT_BOT_REPLIES,
          payload: { result: newReplies },
        });
      })
      .catch((err) => {
        console.log(err);

        return dispatch({
          type: GET_CHAT_BOT_REPLIES_ERROR,
        });
      });
  };

export const createReply =
  (name, value, valueAR, toggle) => (dispatch, getState) => {
    axios
      .post(
        apis.chatbotReplies,
        { reply: value, replyAR: valueAR, name },
        {
          ...tokenConfig(getState),
        }
      )
      .then((res) => {
        const {
          data: { data, message },
        } = res;
        let oldReplies = getState().bot.replies;
        let newReplies = [...oldReplies, data];
        toggle();
        toastr.success(message);
        dispatch({
          type: GET_CHAT_BOT_REPLIES,
          payload: { result: newReplies },
        });
      })
      .catch((err) => {
        console.log(err);

        return dispatch({
          type: GET_CHAT_BOT_REPLIES_ERROR,
        });
      });
  };
