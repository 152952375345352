import React from "react";
import { connect } from "react-redux";
import { getFavourites } from "../../../store/actions/favouritesActions";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import { VscLoading } from "react-icons/vsc";
import SingleFavourite from "../SingleFavourite";

export const PaginateFavourites = ({
  getFavourites,
  allPages,
  thisPage,
  favourites,
  tags,
  loading,
}) => {
  const onClick = (event) => {
    switch (event.target.textContent) {
      case "»":
      case "»Last":
      case "Last":
        getFavourites({ limit: 6, tagId: tags }, allPages);
        break;
      case "«":
      case "«First":
      case "First":
        getFavourites({ limit: 6, tagId: tags }, 1);
        break;
      case "›":
      case "›Next":
      case "Next":
        getFavourites({ limit: 6, tagId: tags }, thisPage + 1);
        break;
      case "‹":
      case "‹Previous":
      case "Previous":
        getFavourites({ limit: 6, tagId: tags }, thisPage - 1);
        break;
      default:
        getFavourites(
          { limit: 6, tagId: tags },
          Number(event.target.textContent)
        );
        break;
    }
  };

  return (
    <div className="col p-2">
      <div className="d-flex flex-wrap">
        {!loading &&
          favourites &&
          favourites.map((favourite, i) => {
            return (
              <SingleFavourite
                favourite={favourite}
                key={i}
                thisPage={thisPage}
              />
            );
          })}
        {loading && (
          <div
            className="col text-center rotator"
            style={{
              fontSize: "40px",
              color: "var(--primaryColor)",
            }}
          >
            <VscLoading />
          </div>
        )}
        {!loading && favourites?.length === 0 && (
          <div className="row justify-content-center align-items-center align-content-center">
            <div
              className="col text-center"
              style={{
                fontSize: "40px",
                color: "var(--primaryColor)",
              }}
            >
              No Favourites
            </div>
          </div>
        )}
      </div>
      {allPages > 1 && (
        <div className="col d-flex justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index}
                  className="pag-link"
                >
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index}
                      className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  favourites: state?.favourites?.favourites,
  loading: state?.favourites?.loading,
  allPages: state?.favourites?.allPages,
  thisPage: state?.favourites?.thisPage,
});

const mapDispatchToProps = {
  getFavourites,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaginateFavourites);
