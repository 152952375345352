import {
    CALENDAR_LOADING,
    LOAD_CALENDAR,
} from "../actions/types";

const initialState = {
    thisMonth: [],
    error: false,
    loading: false,
    thisPage: 1,
    allPages: 1,
    totalCount: 1,
};

export default function calendarReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CALENDAR_LOADING:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case LOAD_CALENDAR:
            return {
                ...state,
                thisMonth: payload.result,
                error: false,
                loading: false,
            };
        default:
            return {
                ...state
            }

    }
}
