import { Modal, Form, Input } from "antd";
import React from "react";
import { connect } from "react-redux";
import { addToFavourites } from "../../../store/actions/favouritesActions";
import TagsInput from "react-tagsinput";

export const AddFavourite = ({
  addToFavourites,
  isModalVisible,
  handleCancel,
}) => {
  const [data, setData] = React.useState({
    type: "none",
    text: "",
    media: null,
    tags: [],
  });

  const { type, text, media, tags } = data;

  const handleOk = () => {
    let formData = new FormData();

    type && formData.append("type", type);
    text && formData.append("text", text);
    tags && tags.map((tag) => formData.append("tags", tag));
    if (media) {
      var i;
      for (i = 0; i < media.length; i++) formData.append("media", media[i]);
    }

    console.log(tags);

    addToFavourites(formData, handleCancel);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleChangeTags = (tags) => {
    setData({ ...data, tags: tags });
  };

  return (
    <Modal
      title="Add to favourites"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !text, className: "main-button" }}
      cancelButtonProps={{ className: "main-button-cancel" }}
    >
      <div className="col-md-12 justify-content-center">
        <Form>
          <Form.Item
            name="text"
            rules={[
              { required: true, message: "Text is required" },
              { max: 5000, message: "Exceeded maximum character length" },
            ]}
          >
            <Input.TextArea
              onChange={onChange}
              name="text"
              value={text}
              className="task-input"
              placeholder="Favourite Text"
            />
          </Form.Item>
          <Form.Item>
            <TagsInput
              maxTags="15"
              className="task-input"
              onChange={handleChangeTags}
              name="tags"
              value={tags}
            />
          </Form.Item>
          <Form.Item name="media" help="Media Files">
            <Input
              value={media}
              onChange={({ target }) => {
                setData({ ...data, media: target.files });
                console.log(media);
              }}
              multiple="true"
              className="mt-3 task-input"
              type="file"
            ></Input>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  addToFavourites,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFavourite);
