import React from "react";
import { connect } from "react-redux";
import { Modal, Form, Input, DatePicker, message } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Row, Col } from "reactstrap";
import { requestAppointment } from "../../../../../store/actions/appointmentsActions";

export const BookAppointment = ({
  userId,
  appointment,
  requestAppointment,
  visible,
  handleCancel,
}) => {
  let { t, i18n } = useTranslation(["appointments"]);

  const [slotData, setSlotData] = React.useState({
    title: "",
    description: "",
  });

  let { title, description } = slotData;

  const onChange = (e) => {
    let { name, value } = e.target;
    setSlotData({ ...slotData, [name]: value });
  };

  const handleOk = () => {
    let data = new FormData();
    title && data.append("title", title);
    description && data.append("description", description);
    data.append("inviteeId", userId);
    data.append("appointmentId", appointment.id);
    requestAppointment(data, handleCancel);
  };

  return (
    <Modal
      title={t("book")}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        className: "main-button",
        disabled: !title,
      }}
      cancelButtonProps={{ className: "main-button-cancel" }}
      okText={t("ok")}
      cancelText={t("cancel")}
    >
      <Form>
        <Row className="mb-2">
          <Col className="text-center">
            <h4>
              {moment(appointment?.startDate)
                .locale(i18n.language)
                .format("lll")}{" "}
              -{" "}
              {moment(appointment?.endDate)
                .locale(i18n.language)
                .format("HH:mm A")}{" "}
            </h4>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={4}>{t("title")}</Col>
          <Col md={8}>
            <Input
              className="task-input"
              name="title"
              value={title}
              onChange={onChange}
              placeholder={t("title")}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={4}>{t("description")}</Col>
          <Col md={8}>
            <Input.TextArea
              className="task-input"
              name="description"
              value={description}
              onChange={onChange}
              type="text"
              placeholder={t("description")}
              showCount
              maxLength={500}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { requestAppointment };

export default connect(mapStateToProps, mapDispatchToProps)(BookAppointment);
