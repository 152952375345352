import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserProfile } from "../../store/actions/usersActions";
import { Avatar, Image } from "antd";
import { Card, CardBody, CardText, Row, Col } from "reactstrap";
import { FaPhoneAlt, FaUserCircle } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BiUserPin } from "react-icons/bi";
import Loader from "../../components/Loader";
import { Tabs } from "./Tabs";
import NotAllowed from "../../components/NotAllowed";
import cover from "../../Assets/Images/stock-cover.jpg";

export const UserProfile = ({ getUserProfile, user, loading, error }) => {
  let { userId } = useParams();

  React.useEffect(() => {
    getUserProfile(userId);
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotAllowed path="/contacts" />;
  }
  return (
    <div className="main-page">
      <div className="my-4  container">
        <h2 className="head-text mx-auto">
          {user?.name}'s Profile <BiUserPin />
        </h2>
      </div>

      {/* <div className="task_form">
        <Card style={{ border: "none" }}>
          <CardBody>
            <div>
              <Avatar
                src={
                  <Image src={user?.image || user?.isContact?.image}></Image>
                }
                style={{ width: 50, height: 50 }}
                className="text-center"
              >
                <FaUserCircle />
              </Avatar>
            </div>
            <div className="row  mt-5">
              <div className="col-sm mb-2">
                <CardText className="row">
                  <div className="col-2">
                    <FaUserCircle color="var(--primaryColor)" size="20" />
                  </div>
                  <div className="col-10">
                    <span className="black_bold_text">{user?.name}</span>
                  </div>
                </CardText>
                <CardText className="row">
                  <div className="col-2">
                    <FaPhoneAlt color="var(--primaryColor)" size="20" />
                  </div>
                  <div className="col-10">
                    <span className="black_bold_text">{user?.mobile}</span>
                  </div>
                </CardText>

                <CardText style={{ wordWrap: "break-word" }} className="row">
                  <div className="col-2">
                    <MdEmail color="var(--primaryColor)" size="20" />
                  </div>
                  <div className="col-10">
                    <a
                      href={"mailto:" + user?.email}
                      className="black_bold_text"
                    >
                      {user?.email}
                    </a>
                  </div>
                </CardText>
              </div>
            </div>
          </CardBody>
        </Card>
      </div> */}
      <div className="task_form">
        <Row className="position-relative">
          <Image preview={false} src={cover} height="250px" />
          <Avatar
            className="position-absolute top-100 start-50 translate-middle"
            style={{ border: "solid 5px white" }}
            size={200}
            src={<Image src={user?.image || user?.isContact?.image} />}
          />
        </Row>
        <Row style={{ marginTop: 100 }}>
          <Col>
            <Row>
              <span className="text-center profile-user-name">
                {user?.name}
              </span>
            </Row>
            <Row>
              <Col className="text-center">
                <div className="profile-data p-2">
                  <MdEmail className="mx-1" />
                  <a
                      href={"mailto:" + user?.email}
                      className="black_bold_text"
                    >
                      {user?.email || "No email"}
                    </a>
                </div>
              </Col>
              <Col className="text-center">
                <div className="profile-data p-2">
                  <FaPhoneAlt className="mx-1" />
                  <a
                      href={"tel:" + user?.mobile}
                      className="black_bold_text"
                    >
                      {user?.mobile}
                    </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Tabs />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.users?.singleUser,
  loading: state?.users?.loading,
  error: state?.users?.error,
});

const mapDispatchToProps = {
  getUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
