import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Modal, Button, Form, Tag } from "antd";
import AsyncSelect from "react-select/async";
import { Row, Col } from "reactstrap";
import {
  deleteEvent,
  getSingleEvent,
  updateEventStatus,
  addInvitees,
} from "../../store/actions/eventActions";
import {
  FaCalendarCheck,
  FaCalendarTimes,
  GiPartyPopper,
  BsHeartFill,
  GiRoundTable,
  FaUsers,
  RiMailAddLine,
  IoMdPin,
} from "react-icons/all";
import {
  AiFillMail,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiFillDelete,
  AiOutlineEdit,
  AiFillTags,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { MdTitle, MdDescription } from "react-icons/md";
import { BiTime, BiTimeFive, BiHash } from "react-icons/bi";
import { GoFileMedia } from "react-icons/go";
import moment from "moment";
import "moment/locale/ar";
import { FileMedia } from "../../components/FileMedia/FileMedia";
import { getContactList } from "../../store/actions/contactActions";
import EditEvent from "./EditEvent";
import GoogleMaps from "../../components/GoogleMap/GoogleMaps";
import NotAllowed from "../../components/NotAllowed";
import InviteesSlider from "./PaginateInvitees/InviteesSlider";

export const EventPage = ({
  event,
  getSingleEvent,
  history,
  user,
  users,
  loading,
  deleteEvent,
  updateEventStatus,
  getContactList,
  addInvitees,
  error
}) => {
  let { eventId } = useParams();
  let { t, i18n } = useTranslation(["delete"]);
  let { t: t2 } = useTranslation(["events"]);
  const creator = user?.id === event?.userId;

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  React.useEffect(() => {
    getSingleEvent(eventId);
  }, []);

  const handleOk = () => {
    deleteEvent([eventId]);
    handleCancel();
    history.push("/events");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isAddModalVisible, setIsAddModalVisible] = React.useState(false);
  const handleAddUsers = () => {
    setIsAddModalVisible(true);
  };
  const filterContacts = async (search) => {
    let newCats = await getContactList({ search });

    return newCats.map((contact) => {
      return { label: contact.name, value: contact.id };
    });
  };
  const [usersToAdd, setUsersToAdd] = React.useState([]);
  const addUsers = () => {
    let data = new FormData();
    let users = usersToAdd.map((item) => item.value);
    console.log(users);
    users && users.map((user) => data.append("users[]", user));
    data.append("eventId", event.id);
    addInvitees(data);
    getSingleEvent(event.id);
    setIsAddModalVisible(false);
  };

  const getEventType = () => {
    switch (event?.typeId) {
      case 1:
        return "date";
      case 2:
        return "invitation";
      case 3:
        return "meeting";
      case 4:
        return "party";
      default:
        return;
    }
  };

  const eventIcon = () => {
    switch (event?.typeId) {
      case 1:
        return <BsHeartFill size="20" />;
      case 2:
        return <AiFillMail size="20" />;
      case 3:
        return <GiRoundTable size="20" />;
      case 4:
        return <GiPartyPopper size="20" />;
      default:
        return <></>;
    }
  };

  const handleResponse = (responseId) => {
    updateEventStatus(eventId, responseId, true);
  };

  const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <NotAllowed path="/events"/>
  }

  return (
    <div className="container mb-5 main-page">
      <Modal
        title={t("confirm")}
        visible={isModalVisible}
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ className: "main-button" }}
        okText={t("ok")}
        cancelText={t("cancel")}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <p>
          {t("sure")} "{event?.title}"
        </p>
      </Modal>

      <Modal
        title={t("confirm")}
        visible={isAddModalVisible}
        closable={false}
        onOk={addUsers}
        onCancel={() => setIsAddModalVisible(false)}
        okButtonProps={{ className: "main-button" }}
        cancelButtonProps={{ className: "main-button-cancel" }}
      >
        <Form>
          <Row>
            <Col className="col-md-4">{t2("invitees")}</Col>
            <Col className="col-md-8">
              <Form.Item>
                <AsyncSelect
                  cacheOptions
                  isMulti
                  defaultOptions
                  loadOptions={filterContacts}
                  onChange={(values) => {
                    setUsersToAdd(values);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <EditEvent
        isModalVisible={isEditModalVisible}
        handleCancel={() => setIsEditModalVisible(false)}
        event={event}
      />

      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            {i18n.language === "en" && (
              <AiOutlineArrowLeft
                size="40"
                style={{ color: "var(--primaryColor)", cursor: "pointer" }}
                onClick={() => history.push("/events")}
              />
            )}
            {i18n.language === "ar" && (
              <AiOutlineArrowRight
                size="40"
                style={{ color: "var(--primaryColor)", cursor: "pointer" }}
                onClick={() => history.push("/events")}
              />
            )}
            {event && event.title}
          </h2>
        </div>
        <div>
          {creator && (
            <Button className="main-button-cancel" onClick={showModal}>
              <AiFillDelete />
            </Button>
          )}
          {creator && (
            <Button
              className="main-button"
              onClick={() => setIsEditModalVisible(true)}
            >
              <AiOutlineEdit />
            </Button>
          )}
        </div>
      </div>
      <div className="task-item">
        <Row className="justify-content-center">
          <h2
            className="text-center"
            style={{
              fontFamily: "cairo",
              color: "var(--primaryColor)",
              fontWeight: "bolder",
            }}
          >
            {t2("overview")}
          </h2>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              <MdTitle /> {t2("title")}:{" "}
            </p>
          </Col>
          <Col className="col-md-9">
            <p className="overview-variables">{event?.title}</p>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              <MdDescription /> {t2("description")}:{" "}
            </p>
          </Col>
          <Col className="col-md-9">
            <p className="overview-variables">
              {event?.description ? event?.description : t2("no_description")}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              {eventIcon()} {t2("event_type")}:{" "}
            </p>
          </Col>
          <Col className="col-md-9">
            <p className="overview-variables">{t2("form." + getEventType())}</p>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              <BiTime /> {t2("form.startTime")}:{" "}
            </p>
          </Col>
          <Col className="col-md-9">
            <p className="overview-variables">
              {event?.startDate &&
                moment(event?.startDate).locale(i18n.language).format("lll")}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              <BiTimeFive /> {t2("form.endTime")}:{" "}
            </p>
          </Col>
          <Col className="col-md-9">
            <p className="overview-variables">
              {event?.endDate &&
                moment(event?.endDate).locale(i18n.language).format("lll")}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              <AiOutlineExclamationCircle /> {t2("actions.actions")}:{" "}
            </p>
          </Col>
          <Col className="col-md-9">
            {event?.invited?.status === "pending" && (
              <>
                <FaCalendarCheck
                  color="green"
                  size="30"
                  style={{ cursor: "pointer", margin: 5 }}
                  onClick={() => handleResponse("1")}
                />
                <FaCalendarTimes
                  color="red"
                  size="30"
                  style={{ cursor: "pointer", margin: 5 }}
                  onClick={() => {
                    handleResponse("2");
                  }}
                />
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              <GoFileMedia /> {t2("media")}:{" "}
            </p>
          </Col>
          <Col className="col-md-9">
            {event?.medias &&
              event?.medias.map((media, idx) => {
                return <FileMedia file={media} key={idx} />;
              })}
            {event?.medias && event?.medias.length === 0 && "No Media"}
          </Col>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              <FaUsers /> {t2("invitees")}:
            </p>
          </Col>
          <Col className="col-md-9 d-flex flex-wrap">
            <InviteesSlider eventId={eventId}/>
            {creator && (
              <RiMailAddLine
                color="var(--primaryColor)"
                size="30"
                style={{ cursor: "pointer", margin: 5 }}
                onClick={handleAddUsers}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              <AiFillTags /> {t2("tags")}:{" "}
            </p>
          </Col>
          <Col className="col-md-9">
            {event?.tags &&
              event?.tags.length !== 0 &&
              event?.tags.map((tag, i) => {
                return (
                  <Tag key={i} icon={<BiHash />} color="geekblue">
                    {tag.tag}
                  </Tag>
                );
              })}
            {event?.tags && event?.tags.length === 0 && "No Tags"}
          </Col>
        </Row>
        <Row>
          <Col className="col-md-3">
            <p className="overview-labels mx-2">
              <IoMdPin /> {t2("location")}:{" "}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            {event?.latitude && event?.longitude && (
              <GoogleMaps
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDGEsJYp-Oc9e03h_pWHoTwITKP9VVNqTo&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                lat={parseFloat(event?.latitude)}
                lng={parseFloat(event?.longitude)}
                infowWindow={
                  <div>
                    <span>{event?.title}</span>
                  </div>
                }
                zoom={15}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  event: state?.events?.singleEvent?.event,
  users: state?.events?.singleEvent?.users?.result,
  user: state?.auth?.user,
  loading: state?.events?.loading,
  error: state?.events?.error,
});

const mapDispatchToProps = {
  getSingleEvent,
  deleteEvent,
  updateEventStatus,
  getContactList,
  addInvitees,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
