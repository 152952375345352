import React from 'react'
import { connect } from 'react-redux'
import { Modal, Input, Form, Select } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { createNewTodo } from '../../../store/actions/todoActions'

export const QuickTaskForm = ({ isModalVisible, createNewTodo, handleCancel }) => {
    let { t } = useTranslation(["todo"]);

    const [unit, setUnit] = React.useState("Hours")
    const [taskData, setTaskData] = React.useState({
        title: "",
        startDate: moment(new Date()).locale("en-us").format('lll'),
        endDate: "",
        endDateVal: "",
        description: "",
        image: null,
    });

    const { title, startDate, endDate, endDateVal, description, image } = taskData;

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === "endDateVal") {
            handleDate(unit, value)
        } else {
            setTaskData({ ...taskData, [name]: value })
        }
    };

    const onChangeUnit = (e) => {
        handleDate(e, endDateVal)
    }

    const handleDate = (e,val) => {
        setUnit(e)
        switch (e) {
            case "Days": {
                let newDate = moment(new Date()).add(val, 'day').locale("en-us").format('lll')
                setTaskData({ ...taskData, endDate: newDate, endDateVal: val }); return;
            }
            case "Hours": {
                let newDate = moment(new Date()).add(val, 'hour').locale("en-us").format('lll')
                setTaskData({ ...taskData, endDate: newDate, endDateVal: val }); return;
            }
            default: return
        }
    }

    const publishTodo = () => {
        let data = new FormData()

        title && data.append('title', title)
        startDate && data.append('startDate', startDate)
        endDate && data.append('endDate', endDate)
        description && data.append('description', description)
        image && data.append('image', image)

        createNewTodo(data);
        setTaskData({
            title: "",
            startDate: moment(new Date()).locale('en-us').format('lll'),
            endDateVal: "",
            endDate: "",
            description: "",
            image: null,
        })
        handleCancel();
    };

    const selectAfter = (
        <Select defaultValue="Hours" className="select-after" onChange={onChangeUnit}>
            <Select.Option value="Hours">{t("hours")}</Select.Option>
            <Select.Option value="Days">{t("days")}</Select.Option>
        </Select>
    );



    return (
        <Modal title={t("add")}
            visible={isModalVisible}
            onCancel={handleCancel}
            onOk={publishTodo}
            okText={t("add_todo")}
            cancelText={t("cancel")}
            okButtonProps={{ disabled: !title || !description || !endDateVal , className:"main-button" }}
            closable={false}
            cancelButtonProps={{className: "main-button-cancel"}}
        >
            <div className="col-md-12 justify-content-center">
                <div className="col">
                    <Form>
                        <Form.Item name="title" rules={[{ required: true, message: t("form.required.todo_title") }]}>
                            <Input
                                onChange={onChange}
                                name="title"
                                value={title}
                                type="textarea"
                                className="task-input"
                                placeholder={t("form.todo_title")}
                            />
                        </Form.Item>
                        <Form.Item name="description" rules={[
                            { required: true, message: t("form.required.todo_description") },
                            { max: 1500, message: "Description must be less than 1500 characters"}
                            ]}>
                            <Input.TextArea
                                onChange={onChange}
                                name="description"
                                value={description}
                                className="task-input"
                                placeholder={t("form.todo_description")}
                            />
                        </Form.Item>
                        <Form.Item name="endDateVal" rules={[{ required: true, message: t("form.required.endDate") }]}>
                            <Input
                                addonBefore={t("ends_after")}
                                onChange={onChange}
                                name="endDateVal"
                                type="number"
                                value={endDateVal}
                                min={1}
                                addonAfter={selectAfter}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = { createNewTodo }

export default connect(mapStateToProps, mapDispatchToProps)(QuickTaskForm)



