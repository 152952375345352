import React from "react";
import { connect } from "react-redux";
import { AiFillDelete, AiFillFolderAdd, AiOutlineEdit } from "react-icons/ai";
import { FaRegFolderOpen } from "react-icons/fa";
import { BiDotsVertical } from "react-icons/bi";
import moment from "moment";
import "moment/locale/ar";
import {
  deleteTask,
  updateTaskStatus,
  getTasks,
} from "../../../store/actions/taskActions";
import { Modal, Menu, Dropdown, Checkbox, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import CategorizeForm from "../CategorizeForm";
import EditTask from "../EditTask";

export const TaskListItem = ({
  task,
  deleteTask,
  user,
  updateTaskStatus,
  type: filterType,
  loading,
  page,
  getTasks,
  filters,
  history,
  onSelect,
}) => {
  let { t, i18n } = useTranslation(["tasks"]);
  let { t: t2 } = useTranslation(["delete"]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const MenuOverlay = () => {
    return (
      <Menu>
        {task.creatorId === user.id && (
          <Menu.ItemGroup title="Creator Actions">
            <Menu.Item
              onClick={showModal}
              key="1"
              icon={
                <AiFillDelete
                  style={{
                    cursor: "pointer",
                    color: "red",
                  }}
                />
              }
            >
              Delete
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={handleOpenEdit}
              icon={
                <AiOutlineEdit
                  style={{
                    cursor: "pointer",
                    color: "var(--primaryColor)",
                  }}
                />
              }
            >
              Edit
            </Menu.Item>
          </Menu.ItemGroup>
        )}
        <Menu.ItemGroup title="Actions">
          <Menu.Item
            key="3"
            onClick={showCategorize}
            icon={
              <AiFillFolderAdd
                style={{
                  cursor: "pointer",
                  color: "var(--primaryColor)",
                }}
              />
            }
          >
            Categorize
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  };

  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const handleOpenEdit = () => {
    setIsEditVisible(true);
  };
  const handleCloseEdit = () => {
    setIsEditVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    deleteTask(task.id);
    getTasks({ limit: 5, ...filters }, page);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isCategorizeVisible, setIsCategorizeVisible] = React.useState(false);
  const showCategorize = () => {
    setIsCategorizeVisible(true);
  };

  const handleCategorizeCancel = () => {
    setIsCategorizeVisible(false);
  };

  const handleUpdateStatus = (statusId) => {
    updateTaskStatus(taskStatusId, statusId);
    getTasks({ limit: 5, ...filters }, page);
  };
  let taskStatusId =
    task?.assignees && task?.assignees[0]
      ? task?.assignees[0]?.taskStatus?.id
      : task?.id;
  let taskStartDate =
    task?.assignees && task?.assignees[0]
      ? task?.assignees[0]?.taskStatus?.startDate
      : task?.startDate;
  let taskEndDate =
    task?.assignees && task?.assignees[0]
      ? task?.assignees[0]?.taskStatus?.endDate
      : task?.endDate;
  return (
    <div className="col-md-6 p-1">
      <div className="task-item">
        <Modal
          title={t2("confirm")}
          visible={isModalVisible}
          closable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ className: "main-button" }}
          okText={t2("ok")}
          cancelText={t2("cancel")}
          cancelButtonProps={{ className: "main-button-cancel" }}
        >
          <p>
            {t2("sure")} "{task.title}"
          </p>
        </Modal>
        <CategorizeForm
          taskId={task?.id}
          visible={isCategorizeVisible}
          handleCancel={handleCategorizeCancel}
        />
        <EditTask
          isModalVisible={isEditVisible}
          handleCancel={handleCloseEdit}
          task={task}
        />
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-wrap">
            {task?.creatorId === user?.id && (
              <Checkbox className="mx-1" onClick={() => onSelect(task.id)} />
            )}
            <h2
              style={{
                color: "var(--primaryColor)",
                fontWeight: "bold",
                fontSize: "20px",
                wordBreak: "break-word",
                cursor: "pointer",
              }}
              onClick={() => history.push("/tasks/" + task.id)}
            >
              {task.title}
            </h2>
          </div>
          <div>
            <Dropdown overlay={MenuOverlay}>
              <BiDotsVertical cursor="pointer" />
            </Dropdown>
          </div>
        </div>
        <div className="container mb-4 p-0">
          <div>
            {filterType === "assigned" && (
              <span
                style={{
                  color: "var(--darkGrey)",
                }}
              >
                {task?.createdAt &&
                  moment(task?.createdAt).locale(i18n.language).fromNow()}
              </span>
            )}
          </div>
          {task?.description && (
            <Row>
              <Col>
                {t("form.task_description")}
                {": "}{" "}
                {task?.description.length > 300
                  ? task?.description.substring(0, 300) + " ..."
                  : task?.description}
              </Col>
            </Row>
          )}
          <Row className="mt-2">
            <Col>
              {t("form.start")}
              {": "}{" "}
              {moment(task?.startDate).locale(i18n.language).format("ll")}
            </Col>
            <Col>
              {t("form.end")}
              {": "} {moment(task?.endDate).locale(i18n.language).format("ll")}
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          {task?.category &&
            task?.category.length !== 0 &&
            task?.category.map((category) => {
              return (
                <Tag icon={<FaRegFolderOpen />} color="#FFCA28">
                  {" "}
                  {category.title}
                </Tag>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.task?.loading,
});

const mapDispatchToProps = { deleteTask, updateTaskStatus, getTasks };

export default connect(mapStateToProps, mapDispatchToProps)(TaskListItem);
