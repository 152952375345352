import React, { useState } from "react";
import { connect } from "react-redux";
import { Nav, Navbar, NavItem, NavLink, NavbarBrand } from "reactstrap";

import { BiUserCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsList } from "react-icons/bs";
import { RiLogoutBoxFill } from "react-icons/ri";
import { logout } from "../../store/actions/authAcrions";
import { Tooltip } from "antd";
import NotificationDropdown from "../NotificationDropdown";
import { changeNotificationSeenCount } from "../../store/actions/notificationActions";

export const TopNavBar = ({ logout, changeNotificationSeenCount }) => {
  let { t, i18n } = useTranslation(["sidebar"]);
  let [currentLang, setCurrentLang] = useState(i18n.language || "ar");

  const toggleSideBar = () => {
    let button = document.querySelector("#side-toggler2");
    button.click();
  };

  const changeLanguage = () => {
    let newLang = i18n.language === "ar" ? "en" : "ar";
    setCurrentLang(newLang === "ar" ? "En" : "عربي");
    i18n.changeLanguage(newLang);
    localStorage.setItem("lng", newLang);
  };
  return (
    <div>
      <Navbar
        id="navbar"
        style={{ background: "var(--primaryColor)" }}
        expand="md"
      >
        <div className="container-fluid">
          <div>
            <Nav>
              <NavItem>
                <NavLink id="top-nav-link">
                  <BsList size="30" id="side-toggler" onClick={toggleSideBar} />
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <NavbarBrand></NavbarBrand>
          <div className="justify-content-end">
            <Nav>
              <div className="row">
                <div className="col px-2">
                  <NavItem>
                    <NavLink
                      to="/"
                      id="top-nav-link"
                      tag={Link}
                      onClick={logout}
                    >
                      <Tooltip title={t("logout")}>
                        <RiLogoutBoxFill size="30" />
                      </Tooltip>
                    </NavLink>
                  </NavItem>
                </div>
                <div className="col px-2">
                  <NavItem>
                    <NavLink
                      id="top-nav-link"
                      onClick={() => changeNotificationSeenCount("clear")}
                    >
                      <NotificationDropdown />
                    </NavLink>
                  </NavItem>
                </div>
                <div className="col px-2">
                  <NavItem>
                    <NavLink to="/profile" id="top-nav-link" tag={Link}>
                      <Tooltip title={t("profile")}>
                        <BiUserCircle size="30" />
                      </Tooltip>
                    </NavLink>
                  </NavItem>
                </div>
                <div className="col px-4">
                  <NavItem>
                    <NavLink
                      id="top-nav-link"
                      onClick={changeLanguage}
                      tag={Link}
                    >
                      <span className="text text-capitalize">
                        {currentLang}
                      </span>
                    </NavLink>
                  </NavItem>
                </div>
              </div>
            </Nav>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { logout, changeNotificationSeenCount };

export default connect(mapStateToProps, mapDispatchToProps)(TopNavBar);
