import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button } from "antd";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaMobile } from "react-icons/fa";
import axios from "axios";
import { apis } from "../../constants/API";
import { useHistory } from "react-router";
import InputVerificationCode from "../../components/VerificationInput";
export const ResetPasswordPhone = ({}) => {
  const history = useHistory();
  const [initState, setInitState] = React.useState({
    loading: false,
    error: "",
    mobile: "",
    vCode: null,
    verifyCode: false,
    changePassword: false,
    message: "",
  });
  const { verifyCode, changePassword, loading } = initState;

  const sendSMSAPI = async (values) => {
    try {
      setInitState({ ...initState, loading: true });
      const { message } = await axios.post(apis.resetPassBySMS, {
        mobile: values.mobile,
      });
      setInitState({
        ...initState,
        message: message,
        loading: false,
        verifyCode: true,
        mobile: values.mobile,
        error: "",
      });
    } catch (error) {
      console.error(error);
      setInitState({ ...initState, loading: false });
      console.error(error);
      if (error?.response?.data?.error) {
        return setInitState({
          ...initState,
          loading: false,
          error: error.response.data.error,
        });
      }
      setInitState({ ...initState, loading: false });
    }
  };

  const verifyCodeAPI = async (values) => {
    try {
      setInitState({ ...initState, loading: true });
      const { message } = await axios.put(apis.resetPassBySMS, {
        mobile: initState.mobile,
        code: values.vCode,
      });
      setInitState({
        ...initState,
        message: message,
        loading: false,
        verifyCode: false,
        changePassword: true,
        vCode: values.vCode,
        error: "",
      });
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.error) {
        console.log("Error", error?.response?.data?.error);
        return setInitState({
          ...initState,
          loading: false,
          error: error.response.data.error,
        });
      }
      setInitState({ ...initState, loading: false });
    }
  };

  const resetPasswordAPI = async (values) => {
    try {
      setInitState({ ...initState, loading: true });
      const { message } = await axios.put(apis.resetPassBySMS + "/change", {
        mobile: initState.mobile,
        code: initState.vCode,
        password: values.password,
      });
      return history.push("/login");
    } catch (error) {
      console.error(error);
      if (typeof error?.response?.data?.error === "string") {
        console.log("Error", error?.response?.data?.error);
        return setInitState({
          ...initState,
          loading: false,
          error: error.response.data.error,
        });
      }
      setInitState({ ...initState, loading: false });
    }
  };

  const changeMobile = () => {
    setInitState({
      loading: false,
      error: "",
      mobile: "",
      vCode: null,
      verifyCode: false,
      changePassword: false,
      message: "",
    });
  };

  const SendSMS = () => {
    return (
      <>
        <Form className="login-form col-md-5 p-5" onFinish={sendSMSAPI}>
          <h3 className="white-text">
            <RiLockPasswordLine /> Send Verification SMS
          </h3>

          {initState.error && (
            <h6 className="error-message">{initState.error}</h6>
          )}

          <Form.Item
            rules={[
              {
                required: true,
                min: 12,
                max: 12,
                message:
                  "Phone number must starts with country code -- Ex: 201202021670",
              },
            ]}
            name="mobile"
          >
            <Input
              type="number"
              prefix={<FaMobile />}
              className="my-2 login-input"
              name="mobile"
              placeholder="Phone number must starts with country code -- Ex: 201202021670"
            />
          </Form.Item>

          <Form.Item className="text-center">
            <Button
              className="sec-button my-3 col-8 text-center"
              htmlType="submit"
              disabled={loading}
            >
              Send
            </Button>
          </Form.Item>
          <Form.Item className="row justify-content-center ">
            <div className="col-md-12">
              <h6 className="text-end">
                <h6
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => history.push("/login")}
                >
                  Back
                </h6>
              </h6>
            </div>
          </Form.Item>
        </Form>
      </>
    );
  };

  const VerifyCode = () => {
    return (
      <>
        <Form className="login-form col-md-5 p-5">
          <h3 className="white-text">
            <RiLockPasswordLine /> Verification Code
          </h3>
          <h5 style={{ color: "var(--mainWhite)" }} className="text-center">
            +{initState.mobile}
          </h5>
          <h6 style={{ color: "var(--mainWhite)" }} className="text-center">
            Please type the code you have recieved
          </h6>
          {initState.error && (
            <h6 className="error-message">{initState.error}</h6>
          )}

          <Form.Item name="vCode" className="text-center">
            <div className="text-center">
              <InputVerificationCode
                length={6}
                loading={loading}
                onComplete={(vCode) => {
                  verifyCodeAPI({ vCode });
                }}
              />
            </div>
          </Form.Item>
          <Form.Item className="row justify-content-center ">
            <div className="col-md-12">
              <h6 className="text-end">
                <h6
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={changeMobile}
                >
                  Change mobile number?
                </h6>
              </h6>
            </div>
          </Form.Item>
          {/*  <Form.Item className="text-center">
            <Button
              className="sec-button my-2 col-8"
              htmlType="submit"
              disabled={loading}
            >
              Verify
            </Button>
          </Form.Item> */}
        </Form>
      </>
    );
  };

  const ChangePassword = () => {
    return (
      <Form className="login-form col-md-5 p-5" onFinish={resetPasswordAPI}>
        <h3 className="white-text">
          <RiLockPasswordLine /> Reset Password
        </h3>

        {initState.error && (
          <h6 className="error-message">{initState.error}</h6>
        )}

        <Form.Item
          rules={[
            {
              required: true,
              min: 6,
              message: "Password must be 6+ chars length",
            },
          ]}
          name="password"
        >
          <Input.Password
            type="password"
            prefix={<FaMobile />}
            className="my-2 login-input"
            name="password"
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              min: 6,
              message: "Password must be 6+ chars length",
            },
          ]}
          name="password_confirm"
        >
          <Input.Password
            type="password"
            prefix={<FaMobile />}
            className="my-2 login-input"
            name="password_confirm"
            placeholder="New Password confirm"
          />
        </Form.Item>

        <Form.Item className="text-center">
          <Button
            className="sec-button my-3 col-8 text-center"
            htmlType="submit"
            disabled={loading}
          >
            Reset password
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="login-page">
      {!loading ? (
        <>
          {!verifyCode && !changePassword && <SendSMS />}
          {verifyCode && !changePassword && <VerifyCode />}
          {!verifyCode && changePassword && <ChangePassword />}
        </>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPhone);
