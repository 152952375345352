import React from "react";
import { connect } from "react-redux";
import { getTodos } from "../../store/actions/todoActions";
import { Button, Switch } from "antd";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import { SiTodoist } from "react-icons/si";
import { FiFilter } from "react-icons/fi";
import { IoMdAddCircleOutline } from "react-icons/io";
import PaginateTodo from "./PaginateTodo";
import CreateTodo from "./CreateTodo";

export const TodoList = ({ list, getTodos, loading }) => {
  let { t: t2 } = useTranslation(["todo"]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    getTodos({ limit: 6}, 1);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="">
      <CreateTodo isModalVisible={isModalVisible} handleCancel={handleCancel} />
      <div className="d-flex my-4 justify-content-between">
        <div>
          <h2 className="head-text mx-auto">
            <SiTodoist /> {t2("todo")}
          </h2>
        </div>
        <div>
        <Button
            className="text-center main-button mx-1"
            style={{ cursor: "pointer" }}
            onClick={() => setFilterOpen(!filterOpen)}
            id="close"
          >
            <FiFilter size="20" color="white" />
          </Button>
          <Button className="main-button">
            <IoMdAddCircleOutline size="25" onClick={showModal} />
          </Button>
        </div>
      </div>
      <div>
        <PaginateTodo filterOpen={filterOpen}/>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state?.todo?.todo,
});

const mapDispatchToProps = { getTodos };

export default connect(mapStateToProps, mapDispatchToProps)(TodoList);
