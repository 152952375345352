import axios from "axios";

import { apis } from "../../constants/API";
import {
  GET_CONTACT_LIST,
  GET_CONTACT_LIST_ERROR,
  GET_CONTACT_LIST_LOADING,
} from "./types";

import { tokenConfig } from "./authAcrions";

import { toastr } from "react-redux-toastr";

export const getContactList = (query) => (dispatch, getState) => {
  dispatch({
    type: GET_CONTACT_LIST_LOADING,
  });
  return axios
    .get(apis.contactList, {
      ...tokenConfig(getState),
      params: {
        ...query,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: GET_CONTACT_LIST,
        payload: data,
      });
      return data;
    })
    .catch((err) => console.log(err));
};

export const createNewContact = (data) => (dispatch, getState) => {
  dispatch({
    type: GET_CONTACT_LIST_LOADING,
  });
  axios
    .post(apis.contactList, data, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      let oldContacts = getState().contacts.contacts;
      let newReplies = [...oldContacts, data];
      dispatch({
        type: GET_CONTACT_LIST,
        payload: newReplies,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CONTACT_LIST_ERROR,
      });
      if (err.response) {
        let errRes = err.response.data.error;
        if (typeof errRes == "string") {
          toastr.error(errRes);
        }
      }
    });
};

export const deleteContact = (contactId) => (dispatch, getState) => {
  axios
    .delete(apis.contactList, {
      ...tokenConfig(getState),
      params: {
        contactId,
      },
    })
    .then((res) => {
      const oldContacts = getState().contacts?.contacts;
      let newContacts = oldContacts.filter((c) => c.id !== contactId);
      toastr.success("Contact deleted");
      return dispatch({
        type: GET_CONTACT_LIST,
        payload: newContacts,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editContact = (contactData) => (dispatch, getState) => {
  dispatch({
    type: GET_CONTACT_LIST_LOADING,
  });
  axios
    .put(apis.contactList, contactData, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      let { data } = res.data;
      let currentContact = getState().contacts?.contacts;
      currentContact = currentContact.map((c) => {
        if (c.id === data.id) {
          c = data;
          return c;
        } else {
          return c;
        }
      });
      toastr.success("Contact updated");
      return dispatch({
        type: GET_CONTACT_LIST,
        payload: currentContact,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getContactListUsersOnly = (query) => (dispatch, getState) => {
  dispatch({
    type: GET_CONTACT_LIST_LOADING,
  });
  return axios
    .get(apis.contactList + "/users", {
      ...tokenConfig(getState),
      params: {
        limit: 49,
        ...query,
      },
    })
    .then((res) => {
      let {
        data: { data },
      } = res;

      data.result = data?.result?.filter(
        (contact) => contact.contact?.firebaseUUID?.length > 1
      );
      dispatch({
        type: GET_CONTACT_LIST,
        payload: data.result,
      });
      return data.result;
    })
    .catch((err) => console.log(err));
};
