import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Table } from 'antd';
import { deleteCategory, getCategories } from '../../../store/actions/categoryActions';
import { GrView } from "react-icons/gr";
import { MdDelete } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import ViewCategory from '../ViewCategory';

export const TableView = ({categories, getCategories, deleteCategory}) => {
    let {t:t2} = useTranslation(["delete"])

    const [isModalVisible1, setIsModalVisible1] = React.useState(false);
    const [isModalVisible2, setIsModalVisible2] = React.useState(false);
    const [isCategoryVisible, setIsCategoryVisible] = React.useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [catToBeDeleted, setCatToBeDeleted] = React.useState({ title: "", id: "" });
    const [catToView, setCatToView] = React.useState({ title: "", id: "" });
  
    const handleSelect = (record, selected, selectedRows, nativeEvent) => {
      console.log(record, selected, selectedRows)
      if (selected) {
        setSelectedRowKeys([...selectedRowKeys, record.id])
      } else {
        const idx = selectedRowKeys.indexOf(record.id)
        setSelectedRowKeys([...selectedRowKeys.slice(0, idx), ...selectedRowKeys.slice(idx + 1)])
      }
      console.log(selectedRowKeys)
    }
  
    const handleSelectAll = (selected, record, selectedRows, nativeEvent) => {
      if (selected) {
        setSelectedRowKeys(categories.map(record => record.id))
      } else {
        setSelectedRowKeys(selected ? categories.map(record => record.id) : [])
      }
      console.log(selectedRowKeys)
    }
  
    const showModal1 = () => {
      setIsModalVisible1(true);
    };
  
    const showModal2 = () => {
      setIsModalVisible2(true);
    }
  
    const handleOk1 = () => {
      deleteCategory(catToBeDeleted.id);
      setIsModalVisible1(false);
      setCatToBeDeleted({ title: "", id: "" })
    };
  
    const handleOk2 = () => {
      let i;
      for (i = 0; i < selectedRowKeys.length; i++) {
        deleteCategory(selectedRowKeys[i])
        console.log(selectedRowKeys[i])
      }
      setIsModalVisible2(false);
    };
  
    const handleCancel1 = () => {
      setIsModalVisible1(false);
    };
  
    const handleCancel2 = () => {
      setIsModalVisible2(false);
    };

    const showCategory = () => {
      setIsCategoryVisible(true)
    }

    const handleCancelCategory = () => {
      setIsCategoryVisible(false)
    }
  
    React.useEffect(() => {
      getCategories()
    }, [])
  
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Category Title',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (record) => (
          <div>
            <a className="mx-2" onClick={() => { setCatToBeDeleted({ title: record.title, id: record.id }); showModal1() }}><MdDelete style={{ color: "red" }} /></a>
            <a className="mx-2" onClick={() => { setCatToView({ title: record.title, id: record.id }); showCategory() }}><GrView style={{ color: "var(--primayColor)" }} /></a>
          </div>
        )
      },
    ];

    return (
        <div>
            <Modal title={t2("confirm")} visible={isModalVisible1} closable={false}
                onOk={handleOk1}
                onCancel={handleCancel1}
                okButtonProps={{ className: "main-button" }}
                okText={t2("ok")}
                cancelText={t2("cancel")}
                cancelButtonProps={{ className: "main-button-cancel" }}>
                <p>{t2("sure")} "{catToBeDeleted.title}"</p>
            </Modal>
            <Modal title={t2("confirm")} visible={isModalVisible2} closable={false}
                onOk={handleOk2}
                onCancel={handleCancel2}
                okButtonProps={{ className: "main-button" }}
                okText={t2("ok")}
                cancelText={t2("cancel")}
                cancelButtonProps={{ className: "main-button-cancel" }}>
                <p>{t2("sure")} {selectedRowKeys.length}</p>
            </Modal>
            <ViewCategory visible={isCategoryVisible} handleCancel={handleCancelCategory} category={catToView} />
            <Table rowSelection={
                {
                    selectedRowKeys,
                    type: "checkbox",
                    onSelect: handleSelect,
                    onSelectAll: handleSelectAll,
                }
            } dataSource={categories} columns={columns} rowKey={record => record.id} />
            <Button className="main-button" onClick={showModal2} disabled={selectedRowKeys.length === 0}><MdDelete /> Delete all selected ({selectedRowKeys.length})</Button>
        </div>
    )
}

const mapStateToProps = (state) => ({
    categories: state?.category?.categories
})

const mapDispatchToProps = {
    getCategories,
    deleteCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(TableView)
