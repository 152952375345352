import React from "react";
import { connect } from "react-redux";
import { Modal, Form, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { updateProfile } from "../../../store/actions/authAcrions";

export const QuickContactForm = ({
  isModalVisible,
  updateProfile,
  handleCancel,
  user,
}) => {

  const fileList = [
    {
      uid: "1",
      status: "done",
      url: user?.image,
    },
  ];

  const [image, setImage] = React.useState(fileList[0]);
  
  const onImageChange = (e) => {
    setImage(e.file);
  };

  const handleProfileImageChange = () => {
    let formData = new FormData();
    image && formData.append("image", image);
    updateProfile(formData);
    handleCancel();
  };

  return (
    <Modal
      title="Change Profile Image"
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={handleProfileImageChange}
      okButtonProps={{
        className: "main-button",
        disabled: !image || image.status === "removed",
      }}
      cancelButtonProps={{ className: "main-button-cancel" }}
    >
      <div className="col-md-12 justify-content-center">
        <div className="col">
          <Form>
            <ImgCrop rotate>
              <Upload
                className="d-flex justify-content-center"
                listType="picture-card"
                onChange={onImageChange}
                multiple={false}
                maxCount={1}
                defaultFileList={fileList}
              >
                {(!image ||
                  (image && image.status === "removed") ||
                  !fileList[0].url) &&
                  "Upload Profile Image"}
              </Upload>
            </ImgCrop>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
});

const mapDispatchToProps = {
  updateProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickContactForm);
