import React from "react";
import { connect } from "react-redux";
import { Modal, Form, Input, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Row, Col } from "reactstrap";
import { requestAppointment } from "../../../../../store/actions/appointmentsActions";

export const RequestAppointment = ({
  userId,
  requestAppointment,
  visible,
  handleCancel,
}) => {
  let { t } = useTranslation(["appointments"]);

  const [slotData, setSlotData] = React.useState({
    title: "",
    description: "",
    startDate: moment().locale("en-us"),
    endDate: "",
  });

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  let { startDate, endDate, title, description } = slotData;

  const onStartDateChange = (date) => {
    setSlotData({ ...slotData, startDate: date.locale("en-us") });
  };

  const onEndDateChange = (date) => {
    setSlotData({ ...slotData, endDate: date.locale("en-us") });
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    setSlotData({ ...slotData, [name]: value });
  };

  function disabledDates(e) {
    return moment(e).dayOfYear() !== moment(startDate).dayOfYear();
  }
  function disabledEndDateTime(e) {
    return {
      disabledHours: () => [
        ...range(0, moment(startDate).hour()),
        ...range(moment(startDate).hour() + 6, 24),
      ],
      disabledMinutes: () =>
        range(0, moment(e) < moment(startDate) && moment(startDate).minute()),
    };
  }
  function disabledStartDateTime(e) {
    return {
      disabledHours: () => range(0, moment(e) < moment() && moment().hour()),
      disabledMinutes: () =>
        range(0, moment(e) < moment() && moment().minute()),
    };
  }
  const handleOk = () => {
    let data = new FormData();
    title && data.append("title", title);
    description && data.append("description", description);
    startDate && data.append("startDate", startDate);
    endDate && data.append("endDate", endDate);
    data.append("inviteeId", userId);
    requestAppointment(data, handleCancel);
  };

  return (
    <Modal
      title={t("request")}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        className: "main-button",
        disabled: !title || !startDate || !endDate,
      }}
      cancelButtonProps={{ className: "main-button-cancel" }}
      okText={t("ok")}
      cancelText={t("cancel")}
    >
      <Form>
        <Row className="mb-2">
          <Col md={4}>{t("title")}</Col>
          <Col md={8}>
            <Input
              className="task-input"
              name="title"
              value={title}
              onChange={onChange}
              placeholder={t("title")}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={4}>{t("description")}</Col>
          <Col md={8}>
            <Input.TextArea
              className="task-input"
              name="description"
              value={description}
              onChange={onChange}
              type="text"
              placeholder={t("description")}
              showCount
              maxLength={500}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={4}>{t("start")}</Col>
          <Col md={8}>
            <DatePicker
              showTime
              showSecond={false}
              onOk={onStartDateChange}
              defaultValue={startDate}
              name="startDate"
              className="task-input col-md-12"
              placeholder="Start Time"
              disabledDate={(e) => e < moment().startOf("day")}
              disabledTime={disabledStartDateTime}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={4}>{t("end")}</Col>
          <Col md={8}>
            <DatePicker
              showTime
              showSecond={false}
              onOk={onEndDateChange}
              defaultValue={endDate}
              name="endDate"
              className="task-input col-md-12"
              placeholder="End Time"
              disabledDate={disabledDates}
              disabledTime={disabledEndDateTime}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { requestAppointment };

export default connect(mapStateToProps, mapDispatchToProps)(RequestAppointment);
