import React from "react";
import { connect } from "react-redux";
import SingleUser from "../SingleUser";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { getUsers } from "../../../store/actions/usersActions";

export const PaginateUsers = ({
  users,
  dataLimit,
  thisPage,
  allPages,
  getUsers,
  t,
}) => {
  const onClick = (event) => {
    switch (event.target.textContent) {
      case "»":
      case "»Last":
      case "Last":
        getUsers({ limit: 5 }, allPages);
        break;
      case "«":
      case "«First":
      case "First":
        getUsers({ limit: 5 }, 1);
        break;
      case "›":
      case "›Next":
      case "Next":
        getUsers({ limit: 5 }, thisPage + 1);
        break;
      case "‹":
      case "‹Previous":
      case "Previous":
        getUsers({ limit: 5 }, thisPage - 1);
        break;
      default:
        getUsers({ limit: 5 }, Number(event.target.textContent));
        break;
    }
  };

  return (
    <div>
      <div className="row">
        {users &&
          users.map((user, index) => {
            return <SingleUser user={user} key={index} t={t} />;
          })}
      </div>
      {allPages > 1 && (
        <div className="col d-flex justify-content-center my-3">
          <Pagination size="sm">
            <PaginationItem>
              <PaginationLink first onClick={onClick} className="pag-link" />
            </PaginationItem>
            {thisPage > 1 && (
              <PaginationItem>
                <PaginationLink
                  previous
                  onClick={onClick}
                  className="pag-link"
                />
              </PaginationItem>
            )}
            {allPages <= 5 &&
              [...Array(allPages)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index} className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage <= 2 &&
              [...Array(5)]?.map((page, index) => (
                <PaginationLink
                  onClick={onClick}
                  key={index} className={
                    "pag-link" + (index + 1 === thisPage ? "-curr" : "")
                  }
                >
                  {index + 1}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(2)]?.map((page, index) => (
                <PaginationLink onClick={onClick} key={index} className="pag-link">
                  {thisPage + index - 2}
                </PaginationLink>
              ))}
            {allPages > 5 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink
                      onClick={onClick}
                      key={index} className={"pag-link" + (index === 0 ? "-curr" : "")}
                    >
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}

            {allPages > 10 &&
              thisPage > 2 &&
              [...Array(3)]?.map((page, index) => {
                return (
                  thisPage + index <= allPages && (
                    <PaginationLink onClick={onClick} key={index} className="pag-link">
                      {thisPage + index}
                    </PaginationLink>
                  )
                );
              })}
            {thisPage < allPages && (
              <PaginationItem>
                <PaginationLink next onClick={onClick} className="pag-link" />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationLink last onClick={onClick} className="pag-link" />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state?.users?.users,
  thisPage: state?.users?.thisPage,
  allPages: state?.users?.allPages,
});

const mapDispatchToProps = { getUsers };

export default connect(mapStateToProps, mapDispatchToProps)(PaginateUsers);
