import React from "react";
import { connect } from "react-redux";
import { TabContent, NavItem, NavLink, Nav, TabPane, Row } from "reactstrap";
import classnames from "classnames";
import Users from "./Users";
import Overview from "./Overview";
import { useTranslation } from "react-i18next";

export const Tabs = ({ isCreator, task }) => {
  const [activeTab, setActiveTab] = React.useState("1");
  const [currVariant, setCurrVariant] = React.useState(
    task?.assignees[0]?.taskStatus[0]?.id
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let { t } = useTranslation(["overview"]);
  let { t: t2 } = useTranslation(["users"]);

  return (
    <div className="my-3">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            style={{
              color: "var(--primaryColor)",
              backgroundColor: "var(--mainWhite)",
            }}
            onClick={() => {
              toggle("1");
            }}
          >
            {t("overview")}
          </NavLink>
        </NavItem>
        {isCreator && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              style={{
                color: "var(--primaryColor)",
                backgroundColor: "var(--mainWhite)",
              }}
              onClick={() => {
                toggle("2");
              }}
            >
              {t2("assignees")}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Overview
              creator={isCreator}
              currVariant={currVariant}
              setCurrVariant={setCurrVariant}
            />
          </Row>
        </TabPane>
      </TabContent>
      {isCreator && (
        <TabContent activeTab={activeTab}>
          <TabPane tabId="2">
            <Row>
              <Users currVariant={currVariant} />
            </Row>
          </TabPane>
        </TabContent>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  task: state?.task?.singleTask?.task,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
