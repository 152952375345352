import axios from "axios";

import { apis } from "../../constants/API";
import {
  LOADING_FAVOURITES,
  LOAD_FAVOURITES,
  ADDED_FAVOURITE,
  FAVOURITE_ERROR,
} from "./types";

import { tokenConfig } from "./authAcrions";
import { toastr } from "react-redux-toastr";

export const getFavourites = (query, page) => (dispatch, getState) => {
  dispatch({
    type: LOADING_FAVOURITES,
  });
  return axios
    .get(apis.getFavourites, {
      ...tokenConfig(getState),
      params: {
        ...query,
        limit: query.limit ? query.limit : 50,
        page,
      },
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({
        type: LOAD_FAVOURITES,
        payload: {
          result: data.result,
          thisPage: data.thisPage,
          allPages: data.allPages,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch({
        type: FAVOURITE_ERROR,
      });
    });
};

export const addToFavourites = (data, handleCancel) => (dispatch, getState) => {
  dispatch({
    type: LOADING_FAVOURITES,
  });
  axios
    .post(apis.getFavourites, data, {
      ...tokenConfig(getState),
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      const oldFavourites = getState().favourites?.favourites;
      let newFavourites = [data, ...oldFavourites];
      toastr.success("New Favourite Added");
      handleCancel();
      return dispatch({
        type: ADDED_FAVOURITE,
        payload: { result: newFavourites },
      });
    })
    .catch((err) => {
      console.log(err);
      toastr.error(err.message);
      return dispatch({
        type: FAVOURITE_ERROR,
      });
    });
};

export const deleteFavourite = (favouriteId) => (dispatch, getState) => {
  axios
    .delete(apis.deleteFavourite, {
      ...tokenConfig(getState),
      params: {
        favouriteId,
      },
    })
    .then((res) => {
      const { message } = res.data;
      const oldFavourites = getState().favourites?.favourites;
      let newFavourites = oldFavourites.filter((t) => t.id != favouriteId);
      toastr.success("Favourite Deleted", message);
      return dispatch({
        type: LOAD_FAVOURITES,
        payload: { result: newFavourites },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editFavourite =
  (favouriteData, handleCancel) => (dispatch, getState) => {
    dispatch({
      type: LOADING_FAVOURITES,
    });
    axios
      .put(apis.getFavourites, favouriteData, {
        ...tokenConfig(getState),
      })
      .then((res) => {
        let { data } = res.data;
        let currentFav = getState().favourites?.favourites;
        currentFav = currentFav.map((f) => {
          if (f.id === data.id) {
            f = data;
            return f;
          } else {
            return f;
          }
        });
        toastr.success("Favourite item updated");
        if (res.status === 200) handleCancel();
        return dispatch({
          type: LOAD_FAVOURITES,
          payload: { result: currentFav },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
